import React, { useCallback, useEffect, useState } from 'react';
import { isEqual, keys, size } from 'lodash';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import classNames from "classnames";
import { MenageViewHeader } from '../header/MenageViewHeader';
import { MenageViewHeaderButtons } from '../header/MenageViewHeaderButtons';
import { AppState } from '../../../../../types/state/AppState';
import { ManageViewContent } from '../content/ManageViewContent';
import { ExcludeList } from '../../../../controls/exclude-list/ExcludeList';
import { apiUtils } from '../../../../../utils/api.utils';
import { ExcludeListItemType } from '../../../../controls/exclude-list/ExcludeListItemType';
import { BrokerDealerCompanySlim } from '../../../../../types/company/BrokerDealerCompanySlim';
import { isRequesting, isRequestNone, isRequestSuccess } from '../../../../../utils';
import { sellerAdminDealerActions } from '../../../../../actions/seller-admin-dealers.actions';
import { RouteLeavingGuard } from '../../../../routing';
import { OnHoverTooltip } from '../../../../common/OnHoverTooltip';
import { RadioButtonGroup } from '../../../../controls';
import { Preloader } from '../../../../common';
import { useBrokerDealers } from '../../../../../effects/useBrokerDealers';
import { ExpandableInfoPanel } from '../ExpandableInfoPanel';

export const DealersView: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const dealersListState = useBrokerDealers();
    const excludedDealerList = useSelector((state: AppState) => state.sellerAdminDealers.excludeDealers);
    const requestStateExcludedDealerList = useSelector((state: AppState) => state.sellerAdminDealers.requestStatusGetExcludeDealers);
    const requestStateSaveExcludeDealers = useSelector((state: AppState) => state.sellerAdminDealers.requestStateSaveExcludeDealers);
    const excludeNewCompanyState = useSelector((state: AppState) => state.sellerAdminDealers.excludeNewCompany);
    const editMode = useSelector((state: AppState) => state.sellerAdminDealers.editMode);

    useEffect(() => {
        if (isRequestNone(requestStateExcludedDealerList)) {
            dispatch(sellerAdminDealerActions.getExcludedDealersRequest())
        }
    }, [requestStateExcludedDealerList, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(sellerAdminDealerActions.setEditModeStatus(false))
        }
    }, [dispatch]);

    const createListModel = useCallback((dealers: BrokerDealerCompanySlim[]) =>
        apiUtils.normalize(dealers, entity => entity.id, entity => entity.name), []);

    const [includeDealers, setIncludeDealers] = useState<ExcludeListItemType>(() => createListModel(dealersListState.items));
    const [excludeDealers, setExcludeDealers] = useState<ExcludeListItemType>(createListModel(excludedDealerList));
    const [excludeNewCompany, setExcludeNewCompany] = useState<boolean | undefined>(excludeNewCompanyState);

    useEffect(() => {
        if (!editMode) {
            setIncludeDealers(createListModel(dealersListState.items));
            setExcludeDealers(createListModel(excludedDealerList));
            setExcludeNewCompany(excludeNewCompanyState);
        }
    }, [editMode, excludeNewCompanyState, excludedDealerList, createListModel, dealersListState.items]);

    const handleIsAnyChanges = useCallback(() =>
        !isEqual(createListModel(excludedDealerList), excludeDealers) || (excludeNewCompany !== excludeNewCompanyState),
        [excludeDealers, excludedDealerList, excludeNewCompany, excludeNewCompanyState, createListModel]
    );

    const handleInclude = (id: number) => {
        setExcludeDealers((excludeDealers) => {
            const excluded = { ...excludeDealers };
            const dealer = excluded[id];
            delete excluded[id];
            setIncludeDealers((includeDealers) => {
                const included = { ...includeDealers };
                included[id] = dealer;
                return included
            });
            return excluded;
        });
    };

    const handleExclude = (id: number) => {
        setIncludeDealers((includeDealers) => {
            const included = { ...includeDealers };
            const dealer = included[id];
            delete included[id];
            setExcludeDealers((excludeDealers) => {
                const excluded = { ...excludeDealers };
                excluded[id] = dealer;
                return excluded
            });
            return included
        });
    };

    const handleIncludeAll = () => {
        setExcludeDealers((excludeDealers) => {
            setIncludeDealers((includeDealers) => {
                return { ...includeDealers, ...excludeDealers }
            });
            return {};
        });
    };

    const handleExcludeAll = () => {
        setIncludeDealers((includeDealers) => {
            setExcludeDealers((excludeDealers) => {
                return { ...excludeDealers, ...includeDealers }
            });
            return {}
        });
    };

    const handleSave = () => {
        if (!!size(includeDealers) && excludeNewCompany !== undefined) {
            const ids = keys(excludeDealers).map(id => Number(id));
            dispatch(sellerAdminDealerActions.saveExcludedDealersRequest(ids, excludeNewCompany))
        }
    };

    return (
        <div className="tab-pane container-flex">

            <MenageViewHeader title="Counterparties">
                <MenageViewHeaderButtons>
                    {editMode ? (
                        <>
                            <button
                                className="btn btn-ghost"
                                onClick={() => dispatch(sellerAdminDealerActions.setEditModeStatus(false))}
                            >
                                Cancel
                            </button>
                            <OnHoverTooltip
                                overlay={'Available Dealers list cannot be blank. Please add at least one company to the Available Dealers.'}
                                disabled={!!size(includeDealers)}
                            >
                                <button
                                    className="btn btn-main"
                                    disabled={!handleIsAnyChanges() || !size(includeDealers) || isRequesting(requestStateSaveExcludeDealers)}
                                    onClick={handleSave}
                                >
                                    Save
                                </button>
                            </OnHoverTooltip>

                        </>
                    ) : (
                        <button
                            className="btn btn-ghost"
                            onClick={() => dispatch(sellerAdminDealerActions.setEditModeStatus(true))}
                        >
                            Edit
                        </button>
                    )}
                </MenageViewHeaderButtons>
            </MenageViewHeader>
            <ManageViewContent>
                <Preloader
                    inProgress={isRequesting(requestStateExcludedDealerList) || isRequesting(dealersListState.requestState)}
                >
                    <div className={classNames('container-flex manage-dealers', { 'manage-dealers-edit-mode': editMode })}>
                        <ExpandableInfoPanel>
                            <div className="status-message info">
                                <i className="icon icon-info"></i>
                                <span className="status-message-cnt">
                                    <p>
                                        When sending a BWIC, all broker-dealers are available by default. Trader users can always exclude any dealer from the BWIC by deselecting them in the BWIC wizard during creation.
                                    </p>
                                    <p>
                                        Admin user may remove broker-dealers from the list of Available Dealers by clicking Edit and de-selecting them from the Available Dealers list. In this case, such dealers would not be visible to Trader users during BWIC creation.
                                    </p>
                                </span>
                            </div>
                        </ExpandableInfoPanel>
                        <div className="radios-section">
                            <p>New dealer companies registered on the platform initially will be added to the selected
                                list:</p>
                            <RadioButtonGroup
                                options={[{ value: false, label: 'Available' }, { value: true, label: 'Excluded' }]}
                                disabled={!editMode || !isRequestSuccess(requestStateExcludedDealerList) || isRequesting(requestStateSaveExcludeDealers)}
                                onChange={setExcludeNewCompany}
                                value={excludeNewCompany}
                            />
                        </div>
                        <ExcludeList
                            editMode={editMode}
                            excludeTitle="Excluded Dealers"
                            includeTitle="Available Dealers"
                            includeColumnTitle={!!size(includeDealers) ? 'Dealer' : 'There are no available dealers to display'}
                            excludeColumnTitle={!!size(excludeDealers) ? 'Dealer' : 'There are no excluded dealers to display'}
                            includeList={includeDealers}
                            excludeList={excludeDealers}
                            disabled={isRequesting(requestStateSaveExcludeDealers)}
                            onInclude={handleInclude}
                            onExclude={handleExclude}
                            onIncludeAll={handleIncludeAll}
                            onExcludeAll={handleExcludeAll}
                        />
                    </div>
                </Preloader>
            </ManageViewContent>
            <RouteLeavingGuard
                navigate={(pathname: string) => history.push(pathname)}
                shouldBlockNavigation={handleIsAnyChanges}
            />
        </div>
    )
};
