import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { manageEmailPreferencesActions } from "../../../../../../actions/manage-email-preferences.actions";
import { useAppSelector } from '../../../../../../effects/useAppSelector';
import { isRequestNone, isRequesting } from "../../../../../../utils/request-state.utils";
import { PageConfigType } from '../../../../../../types/page-config/PageConfigType';
import { BwicFilterType } from '../../../../../../types/filters/FilterState';
import { usePageConfig } from '../../../../../common/pageConfig/usePageConfig';
import { SavedFiltersAlerts } from '../../SavedFiltersAlerts';
import { DealerInventoryAlertsSaveButton } from './DealerInventoryAlertsSaveButton';
import { UserConfigFilter } from '../../../../../../types/user-config/UserConfigFilter';
import { Preloader } from '../../../../../common/Preloader';
import { SubscriptionFeature } from "../../../../../../types/billing/SubscriptionFeature";
import { EmailPreferencesRadioGroup } from "../EmailPreferencesRadioGroup";
import { AlertOption } from "../../../../../../types/email-preferences/EmailPreferences";
import { FilterColumnsKeys } from "../../../../../amrPipeline/aggregated/email-alerts/issuance-monitor/filterColumns";
import { UnsubscribeInventoryAlertsButton } from "./UnsubscribeInventoryAlertsButton";
import RouteLeavingGuard from "../../../../../routing/RouteLeavingGuard";
import { useInventoryAlertsChangedState } from "./useInventoryAlertsChangedState";
import { ShowFor } from "../../../../../access";
import { textLabels } from "../../../../../../constants/text-labels";

export const DealerInventoryAlertsTab = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const filter = useAppSelector(s => s.filters.bdInventory.filter);
    const visibleFilters = useAppSelector(s => s.filters.bdInventory.visibleFilters);
    const savePreferencesRequestState = useAppSelector(s => s.manageEmailPreferences.requestStateSaveEditPreferences);
    const dealerInventoryAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.dealerInventoryAlertState);

    const { config, requestState, saveAlerts, updateRequestState } = usePageConfig(
        PageConfigType.DealerInventory,
        true,
        BwicFilterType.Inventory
    );

    const { isFilterAlertsChanged, dealerInventoryAlertStateChanged } = useInventoryAlertsChangedState();

    const hasChanges = isFilterAlertsChanged || dealerInventoryAlertStateChanged;
    const disabled = isRequestNone(requestState) || isRequesting(requestState, updateRequestState, savePreferencesRequestState);

    const handleSave = (updatedFilters?: UserConfigFilter[]) => {
        dispatch(manageEmailPreferencesActions.saveEmailPreferencesRequest());
        if (updatedFilters?.length) {
            saveAlerts(updatedFilters);
        }
    }

    return (
        <>
            <div className="flex-row justify-content-end save-changes">
                <ShowFor feature={SubscriptionFeature.InventoryAlerts}>
                    <UnsubscribeInventoryAlertsButton filterAlertsStatuses={config?.filters} disabled={disabled} />
                </ShowFor>
                <DealerInventoryAlertsSaveButton
                    className="btn-sm"
                    currentFilters={config?.filters}
                    disabled={disabled}
                    onSave={handleSave}
                />
            </div>
            <EmailPreferencesRadioGroup
                value={dealerInventoryAlertState}
                disabled={isRequesting(updateRequestState)}
                alertOptions={[AlertOption.Never, AlertOption.Instant]}
                alertOptionInfo={{
                    [AlertOption.Instant]: 'Alerts are sent once the security has been added, updated, or marked as traded in the Dealer Inventory.'
                }}
                groupNameIndentifier="dealer-inventory-alert-state"
                title="Inventory"
                description="Receive email notifications when security has been added, updated, or marked as traded in the Dealer Inventory."
                requiredFeature={SubscriptionFeature.InventoryAlerts}
                onChange={alertOption => dispatch(manageEmailPreferencesActions.setDealerInventoryAlertState(alertOption))}
            />
            <Preloader inProgress={isRequestNone(requestState) || isRequesting(requestState)}>
                <SavedFiltersAlerts
                    excludeColumns={[FilterColumnsKeys.Daily]}
                    filter={filter}
                    filterAlertsStatuses={config?.filters}
                    disabled={isRequesting(savePreferencesRequestState, updateRequestState)}
                    visibleFilters={visibleFilters}
                    filterType={BwicFilterType.Inventory}
                    pageConfigType={PageConfigType.DealerInventory}
                    requiredFeature={SubscriptionFeature.InventoryAlerts}
                    description={textLabels.savedFiltersInventory}
                    alertOptionToolitps={{
                        [AlertOption.Instant]: textLabels.savedFiltersInventoryInstantTooltip
                    }}
                    
                />
            </Preloader>
            <RouteLeavingGuard
                navigate={(pathname: string) => {
                    dispatch(manageEmailPreferencesActions.rollbackInventory())
                    history.push(pathname);
                }}
                shouldBlockNavigation={() => hasChanges}
            />
        </>
    )
}
