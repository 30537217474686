import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { profileActions } from '../../../../../actions/profile.actions';
import { sellerAdminUsersActions } from '../../../../../actions/seller-admin-users.actions';
import { constants, roles, routes, SORT, SORTING_TYPE } from '../../../../../constants';
import IconSVG from '../../../../../styles/svg-icons';
import { UserStatus } from '../../../../../types/account/UserStatus';
import { UserRequestStatus } from '../../../../../types/management/UserRequestStatus';
import { AppState } from '../../../../../types/state/AppState';
import { user } from '../../../../../user';
import { isRequesting, isRequestNone } from '../../../../../utils';
import { ShowFor } from '../../../../access';
import Confirm from '../../../../alerts/Confirm';
import { Table } from '../../../../bidding/common/table';
import { ColumnBuilder } from '../../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../../../bidding/common/table/types/ColumnDefinition';
import { OnHoverTooltip, Preloader } from '../../../../common';
import { RejectReasonPopup } from '../../../../common/RejectReasonPopup';
import { Popover } from '../../../../controls';
import { Checkbox } from '../../../../controls/Checkbox';
import { ManageViewContent } from '../content/ManageViewContent';
import { ExpandableInfoPanel } from '../ExpandableInfoPanel';
import { MenageViewHeader } from '../header/MenageViewHeader';
import { MenageViewHeaderButtons } from '../header/MenageViewHeaderButtons';
import { RequestUserStatusDetails } from './RequestUserStatusDetails';
import { UserContextMenu } from './UserContextMenu';
import { convert, UserListTableItem } from './UserListTableItem';
import { useAppDispatch } from '../../../../../effects/useAppDispatch';

interface AnoncmentNotification {
    bwicCreateNotifyAdmins?: boolean;
    bwicCreateNotifyViewers?: boolean;
}

export const UserList: React.FC = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const [hideBlockedUsers, setHideBlockedUsers] = useState(true);

    const [blockingUser, setBlockingUser] = useState<UserListTableItem>();
    const [unblockingUser, setUnblockingUser] = useState<UserListTableItem>();

    const companyAccount = useSelector((state: AppState) => state.profile.company);
    const users = useSelector((state: AppState) => state.sellerAdminUsers.users);
    const changeBwicAnnouncementNotificationsRequestState = useSelector((state: AppState) =>
        state.sellerAdminUsers.changeBwicAnnouncementNotificationsRequestState
    );
    const requestUsers = useSelector((state: AppState) => state.sellerAdminUsers.requestUsers);
    const isRequestingExportUser = useSelector((state: AppState) => state.sellerAdminUsers.isRequestingExportUser);
    const requestStateGetUserList = useSelector((state: AppState) => state.sellerAdminUsers.requestStateGetUserList);

    useEffect(() => {
        if (isRequestNone(requestStateGetUserList)) {
            dispatch(sellerAdminUsersActions.getUserListRequest());
        }
    }, [dispatch, requestStateGetUserList]);

    const changeBwicAnnouncementNotifications = (bwicAnnouncementNotification: AnoncmentNotification) => {
        dispatch(profileActions.changeBwicAnnouncementNotifications({
            bwicCreateNotifyAdmins: bwicAnnouncementNotification.bwicCreateNotifyAdmins ?? companyAccount?.bwicCreateNotifyAdmins,
            bwicCreateNotifyViewers: bwicAnnouncementNotification.bwicCreateNotifyViewers ?? companyAccount?.bwicCreateNotifyViewers
        }))
    }

    const convertToUserListItems = useCallback(() => {
        const userList = [
            ...users.map(u => convert(u)),
            ...requestUsers.map(r => convert(r))
        ].sort((a, b) => a.fullName.localeCompare(b.fullName));
        if (hideBlockedUsers) {
            return userList.filter(u => u.userStatus !== UserStatus.Blocked && u.userRequestStatus !== UserRequestStatus.Blocked)
        }
        return userList
    }, [users, requestUsers, hideBlockedUsers]);

    if (isRequestNone(requestStateGetUserList)) return null;

    const handleBlockUser = (message: string) => {
        if (blockingUser) {
            blockingUser.request
                ? dispatch(sellerAdminUsersActions.disableUserRequest(blockingUser.id, blockingUser.request.concurrencyStamp, message))
                : dispatch(sellerAdminUsersActions.disableUser(blockingUser.id, message));
        }
        setBlockingUser(undefined)
    };

    const getColumns = () => {
        const userRoles: IColumnDefinition<UserListTableItem> = {
            columnKey: 'roles',
            renderColumnHeaderContent: () => 'User Role',
            renderColumnContent: entity => {
                const text = roles.sortRolesByPriority([...entity.roles])
                    .map((role: string) => roles.getShortTitle(role))
                    .join(', ');
                return (
                    <OnHoverTooltip wrapperClass="text-ellipsis" overlay={text}>
                        {text}
                    </OnHoverTooltip>
                )
            },
            headerClassName: 'data-list-user-role',
            bodyClassName: 'data-list-user-role',
            sortingField: 'roles',
            sortingType: SORTING_TYPE.rolesShortTitles
        };
        const name: IColumnDefinition<UserListTableItem> = {
            columnKey: 'name',
            renderColumnHeaderContent: () => 'User',
            renderColumnContent: entity => (
                <Popover
                    title={entity.fullName}
                    className="popover-md"
                    actionButton={<button className="pseudo-link text-ellipsis">{entity.fullName}</button>}
                >
                    <ul className="contacts-cards">
                        <li>
                            <span><a href={`mailto:${entity.email}`}>{entity.email}</a></span>
                            <span>{entity.phoneNumber || '–'}</span>
                        </li>
                    </ul>
                </Popover>
            ),
            headerClassName: 'data-list-user',
            bodyClassName: 'data-list-user',
            sortingField: 'fullName',
            sortingType: SORTING_TYPE.string
        };
        const fullName: IColumnDefinition<UserListTableItem> = {
            columnKey: 'fullName',
            renderColumnHeaderContent: () => 'User',
            renderColumnContent: entity => {
                return (
                    <OnHoverTooltip wrapperClass="text-ellipsis" overlay={entity.fullName}>
                        {entity.fullName}
                    </OnHoverTooltip>
                )
            },
            headerClassName: 'data-list-user',
            bodyClassName: 'data-list-user',
            sortingField: 'fullName',
            sortingType: SORTING_TYPE.string
        };
        const email: IColumnDefinition<UserListTableItem> = {
            columnKey: 'email',
            renderColumnHeaderContent: () => 'Email',
            renderColumnContent: entity => {
                return (
                    <OnHoverTooltip wrapperClass="text-ellipsis" overlay={entity.email}>
                        <a href={`mailto:${entity.email}`}>{entity.email}</a>
                    </OnHoverTooltip>
                )
            },
            headerClassName: 'data-list-email',
            bodyClassName: 'data-list-email'
        };
        const phone: IColumnDefinition<UserListTableItem> = {
            columnKey: 'phone',
            renderColumnHeaderContent: () => 'Phone',
            renderColumnContent: entity => {
                return (
                    <OnHoverTooltip wrapperClass="text-ellipsis" overlay={entity.phoneNumber}>
                        {entity.phoneNumber || constants.emptyPlaceholder}
                    </OnHoverTooltip>
                )
            },
            headerClassName: 'data-list-phone',
            bodyClassName: 'data-list-phone',
        };
        const status: IColumnDefinition<UserListTableItem> = {
            columnKey: 'status',
            renderColumnHeaderContent: () => 'Status',
            renderColumnContent: entity => (
                <RequestUserStatusDetails
                    userStatus={entity.userStatus}
                    userRequestStatus={entity.userRequestStatus}
                    blockReason={entity.blockReason || ''}
                />
            ),
            headerClassName: 'data-list-status',
            bodyClassName: 'data-list-status',
            sortingField: 'statusText',
            sortingType: SORTING_TYPE.string
        };
        const registrationDate: IColumnDefinition<UserListTableItem> = {
            columnKey: 'registrationDate',
            renderColumnHeaderContent: () => 'Reg. Date',
            renderColumnContent: entity => moment(entity.registrationDate).format(constants.dateFormat4),
            headerClassName: 'data-list-reg-date',
            bodyClassName: 'data-list-reg-date',
            sortingField: 'registrationDate',
            sortingType: SORTING_TYPE.date
        };
        const registeredBy: IColumnDefinition<UserListTableItem> = {
            columnKey: 'registratedBy',
            renderColumnHeaderContent: () => 'Reg. by',
            renderColumnContent: entity => (
                entity.registrationBy === 'KopenTech Admin'
                    ? 'KopenTech Admin'
                    : entity.registrationBy ? <a href={`mailto:${entity.registrationBy}`}>{entity.registrationBy}</a> : null
            ),
            headerClassName: 'data-list-reg-by',
            bodyClassName: 'data-list-reg-by',
            sortingField: 'registrationBy',
            sortingType: SORTING_TYPE.string
        };
        const options: IColumnDefinition<UserListTableItem> = {
            columnKey: 'actions',
            renderColumnHeaderContent: () => 'Actions',
            renderColumnContent: entity => (
                <UserContextMenu
                    user={entity}
                    onBlockingUser={setBlockingUser}
                    onUnblockingUser={setUnblockingUser}
                />
            ),
            headerClassName: 'data-list-options text-right',
            bodyClassName: 'data-list-options text-right'
        };
        const jobTitle: IColumnDefinition<UserListTableItem> = {
            columnKey: 'jobTitle',
            renderColumnHeaderContent: () => 'Job Title',
            renderColumnContent: entity => (
                !!entity.jobTitle
                    ? <OnHoverTooltip overlay={entity.jobTitle}>{entity.jobTitle}</OnHoverTooltip>
                    : constants.emptyPlaceholder
            ),
            headerClassName: 'data-list-job-title',
            bodyClassName: 'data-list-job-title',
            sortingField: 'jobTitle',
            sortingType: SORTING_TYPE.string
        };


        const columns = user.hasRoles(roles.SellerAdministrator)
            ? [userRoles, name, jobTitle, status, registrationDate, registeredBy, options]
            : [userRoles, fullName, jobTitle, status, email, phone];

        return columns.map(c => new ColumnBuilder(c));
    };

    return (
        <Preloader inProgress={isRequesting(requestStateGetUserList)}>
            <MenageViewHeader title="Platform Users">
                <MenageViewHeaderButtons>
                    <ShowFor role={roles.SellerAdministrator}>
                        <Preloader small={true} fullScreen={false} inProgress={isRequestingExportUser} text="Exporting…">
                            <button
                                className="btn btn-link"
                                onClick={() => dispatch(sellerAdminUsersActions.exportUsersRequest())}
                                disabled={isRequestingExportUser}
                            >
                                <IconSVG name="export" width={16} height={16} /> Export Login History
                            </button>
                        </Preloader>
                        <button
                            className="btn btn-ghost"
                            onClick={() => history.push(routes.profileManageCreateNewUser)}
                        >
                            <IconSVG name="plus" width={16} height={16} />
                            <span>New User</span>
                        </button>
                    </ShowFor>
                </MenageViewHeaderButtons>
            </MenageViewHeader>
            <ManageViewContent>
                <ShowFor role={roles.SellerAdministrator}>
                    <ExpandableInfoPanel>
                        <div className="status-message info">
                            <i className="icon icon-info" />
                            <span className="status-message-cnt">
                                <p>
                                    Company Admin users can perform the following functions:<br />
                                    &middot; Create, update, block/unblock users<br />
                                    &middot; Download login history of company users by clicking “Export Login History”<br />
                                </p>
                                <p>
                                    Traders/Viewers can see the Platform Users page in view-only mode.<br />
                                    When a new Admin user is created, all current Admins will receive an email notification.
                                </p>
                                <p>
                                    Users created by Admin must be approved by the KTX ATS Platform. Until approval is processed, the new user is shown to the Admin as “Pending Approval”
                                </p>
                            </span>
                        </div>
                    </ExpandableInfoPanel>
                    <div className="notify-options">
                        <div className="notify-options-checkboxes flex-row">
                            <Checkbox
                                label="Hide Blocked Users"
                                checked={hideBlockedUsers}
                                onChange={() => setHideBlockedUsers(!hideBlockedUsers)}
                            />
                            <Checkbox
                                label="Notify Admins"
                                disabled={isRequesting(changeBwicAnnouncementNotificationsRequestState)}
                                checked={companyAccount?.bwicCreateNotifyAdmins}
                                onChange={(e) => changeBwicAnnouncementNotifications({ bwicCreateNotifyAdmins: e.target.checked })}
                            />
                            <Checkbox
                                label="Notify Viewers"
                                disabled={isRequesting(changeBwicAnnouncementNotificationsRequestState)}
                                checked={companyAccount?.bwicCreateNotifyViewers}
                                onChange={(e) => changeBwicAnnouncementNotifications({ bwicCreateNotifyViewers: e.target.checked })}
                            />
                        </div>
                        <div className="notify-options-desc text-sm text-warm-grey">Receive email notifications on BWIC announcements</div>
                    </div>
                </ShowFor>
                <Table
                    dataItems={convertToUserListItems()}
                    columns={getColumns()}
                    defaultSortBy={'fullName'}
                    defaultSortByDirection={SORT.ASC}
                />
            </ManageViewContent>
            {
                blockingUser &&
                <RejectReasonPopup
                    title="Blocked Reason"
                    onClose={() => setBlockingUser(undefined)}
                    onReject={handleBlockUser}
                    comfirmButtonText="Block"
                />
            }
            {
                unblockingUser &&
                <Confirm
                    text={`Do you really want to activate ${unblockingUser.fullName}?`}
                    onConfirm={() => {
                        unblockingUser.request
                            ? dispatch(sellerAdminUsersActions.enableUserRequest(unblockingUser.id, unblockingUser.request.concurrencyStamp))
                            : dispatch(sellerAdminUsersActions.enableUser(unblockingUser.id));
                        setUnblockingUser(undefined)
                    }}
                    onCancel={() => setUnblockingUser(undefined)}
                />
            }
        </Preloader>
    );
};
