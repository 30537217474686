import cn from 'classnames';
import { isRequesting } from "../../../../../../utils/request-state.utils";
import { useAppSelector } from "../../../../../../effects/useAppSelector";
import { UserConfigFilter } from "../../../../../../types/user-config/UserConfigFilter";
import { useInventoryAlertsChangedState } from './useInventoryAlertsChangedState';

interface Props {
    className?: string;
    currentFilters?: UserConfigFilter[];
    disabled?: boolean;
    onSave: (updatedFilters?: UserConfigFilter[]) => void;
}

export const DealerInventoryAlertsSaveButton = ({ className, currentFilters, disabled, onSave }: Props) => {
    const savedFiltersEditState = useAppSelector(s => s.manageEmailPreferences.editSavedFiltersPreferences);
    const savePreferencesRequestState = useAppSelector(s => s.manageEmailPreferences.requestStateSaveEditPreferences);
    const emailPreferencesRequestState = useAppSelector(s => s.manageEmailPreferences.requestStateGetPreferences);
    const { isFilterAlertsChanged, dealerInventoryAlertStateChanged } = useInventoryAlertsChangedState();


    const isDisabled = disabled
        || !(isFilterAlertsChanged || dealerInventoryAlertStateChanged)
        || isRequesting(emailPreferencesRequestState, savePreferencesRequestState);

    const handleSave = () => {
        let updatedFilters: UserConfigFilter[] | undefined;
        if (isFilterAlertsChanged && currentFilters?.length) {
            updatedFilters = currentFilters.map(f => ({
                ...f,
                alertOption: savedFiltersEditState[f.referenceName] ?? f.alertOption
            }));

        }

        onSave(updatedFilters);
    }

    return (
        <button
            className={cn("btn btn-main", className)}
            disabled={isDisabled}
            onClick={handleSave}
        >
            Save
        </button>
    );
}