import { SettlementAgentAgreement } from "../../../../types/bid-as-dealer/SettlementAgentAgreement";
import { SettlementAgreementStatus } from "../../../../types/bid-as-dealer/SettlementAgreementStatus";
import { isRequestSuccess } from "../../../../utils";
import { EmptyPlaceholder } from "../../../common";
import { useAgreement } from "../../../seller-buyside/useAgreement";

interface Props {
    children: (agreement: SettlementAgentAgreement) => React.ReactNode;
}
export function SettlementAgentTabContent({ children }: Props) {
    const { agreement, requestState } = useAgreement();

    if (!isRequestSuccess(requestState)) return null;

    if (agreement == null ||
        agreement?.agreementStatus !== SettlementAgreementStatus.confirmed) {
        return <EmptyPlaceholder text="You don't yet have an agreement with the settlement agent for KTX Trading" />
    }


    return <>{children(agreement)}</>;
}