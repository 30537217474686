import * as React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../types/state/AppState';
import { IdentityCodeSubmitContent } from '../../../account/verify-identity/IdentityCodeSubmitContent';
import { VerifyIdentityMethodType } from '../../../../types/account/VerifyIdentityMethodType';
import { tfaSettingsActions } from '../../../../actions/tfa-settings.actions';
import { Popup, PopupBody, PopupFooter } from '../../../controls';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

interface Props {
    initialDate: Date;
    maskedPhone: string;
    onSubmit: (code: string) => void;
    onClose: () => void;
}

export function SubmitVerificationCodePopup({ initialDate, maskedPhone, onSubmit, onClose }: Props) {
    const dispatch = useAppDispatch();
    const [code, setCode] = React.useState('');
    const [canSubmit, setCanSubmit] = React.useState(false);
    const [codeExpired, setCodeExpired] = React.useState(false);
    const {
        isIdentityPhoneSaving,
        isPhoneVerificationCodeSending,
        isPhoneVerificationCodeInvalid,
        smsActivationCodeRequestedDate } = useSelector((state: AppState) => state.profile);
    const isLoading = isIdentityPhoneSaving || isPhoneVerificationCodeSending;

    React.useEffect(() => {
        setCode('');
        setCodeExpired(false);
        setCanSubmit(false);
    }, [smsActivationCodeRequestedDate]);

    const handleCodeReSend = () => dispatch(tfaSettingsActions.requestPhoneVerificationCode());

    const handleCodeExpired = (expired: boolean) => setCodeExpired(expired);

    const handleCodeChange = (code: string, canSubmit: boolean) => {
        setCode(code);
        setCanSubmit(canSubmit);
        dispatch(tfaSettingsActions.invalidPhoneVerificationCode(false));
    }

    return (
        <Popup title="Confirm Phone Number" modalClass="modal-confirm-phone" onClose={onClose}>
            <PopupBody>
                <IdentityCodeSubmitContent
                    initialDate={initialDate}
                    selectedMethod={VerifyIdentityMethodType.sms}
                    code={code}
                    phoneMask={maskedPhone}
                    emailMask=""
                    isCodeExpired={codeExpired}
                    isCodeInvalid={isPhoneVerificationCodeInvalid}
                    disabled={isLoading}
                    resendCodeVisible={false}
                    onResend={handleCodeReSend}
                    onCodeChange={handleCodeChange}
                    onExpiredChange={handleCodeExpired}
                />
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" disabled={isLoading} onClick={onClose}>Cancel</button>
                {codeExpired && <button className="btn btn-main" disabled={isLoading} onClick={handleCodeReSend}>re-send code</button>}
                {!codeExpired && <button className="btn btn-main" disabled={!canSubmit || isLoading} onClick={() => onSubmit(code)}>Confirm</button>}
            </PopupFooter>
        </Popup>
    );
}
