import cn from 'classnames';
import { Subscription } from "../../../../../types/billing/Subscription";
import { isRequesting } from "../../../../../utils/request-state.utils";
import { useSubscriptionRequest } from "../hooks/useSubscriptionRequest";
import { calculateSubscriptionTimeline } from '../../../../../utils/subscription.utils';


interface Props {
    subscription: Subscription;
    isCurrent: boolean;
}

export function RequestSubscriptionButton({ subscription, isCurrent }: Props) {
    const { requestState, requestCallback } = useSubscriptionRequest();
    const isBasicPlan = subscription.title.toLowerCase() === "basic";
    const { isTrial } = calculateSubscriptionTimeline();

    if (isCurrent && isBasicPlan && !isTrial) {
        return (
            <button className="btn btn-ghost btn-sm" disabled>current plan</button>
        )
    }

    return (
        <button
            className={cn("btn btn-sm", isBasicPlan ? "btn-ghost" : "btn-main")}
            disabled={isRequesting(requestState)}
            onClick={() => requestCallback(subscription)}
        >
            Select plan
        </button>
    );
}
