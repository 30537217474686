import * as React from 'react';
import { AgencyRatings } from '../../types/models/rating/AgencyRatings';
import { Preloader } from '../common';

interface AgencyRatingListProps {
    ratings?: AgencyRatings
}

export function AgencyRatingList({ ratings }: AgencyRatingListProps) {
    const values: string[] = [];

    if (ratings) {
        values.push(ratings.snP || '-');
        values.push(ratings.fitch || '-');
    }

    return (
        <>
            <dt>S&P/Fitch</dt>
            <dd className="text-capitalize">
                <Preloader inProgress={!ratings} fullScreen={false} textOnly={true} text={'Loading...'}>
                    {ratings && <>{values.join('/')}</>}
                </Preloader>
            </dd>
        </>
    );
}
