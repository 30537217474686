import { bankImApRequiredFeatures } from "../../../../../../constants/banks/bankImApRequiredFeatures";
import { SubscriptionFeature } from "../../../../../../types/billing/SubscriptionFeature";
import { AlertOption } from "../../../../../../types/email-preferences/EmailPreferences";
import {
    CompanyAlertsValue,
    SecondaryStatsAlertsValue,
} from "../../../../../../types/user-config/UserConfig";
import { UserConfigType } from "../../../../../../types/user-config/UserConfigType";
import { ActionBlocker } from "../../../../../access/ActionBlocker";
import EmailInfoTooltip from "../../../../../common/EmailInfoTooltip";
import { ColumnBuilder } from "../../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { IColumnDefinition } from "../../../../../bidding/common/table/types/ColumnDefinition";
import { OnHoverTooltip } from "../../../../../common";
import { RadioButton } from "../../../../../controls";
import { MultiTableHeaderRadioButton } from "../MultiTableHeaderRadioButton";

interface BanksColumns {
    legalName: string;
    bankDealerInventoryAlert: SecondaryStatsAlertsValue;
    bankImAlerts: CompanyAlertsValue;
    bankApAlerts: CompanyAlertsValue;
}

const banksDiAlertsColumnsDefinition: IColumnDefinition<BanksColumns>[] = [
    {
        columnKey: "managerName",
        renderColumnHeaderContent: () => "Bank Name",
        renderColumnContent: (alert) => {
            return (
                <div className="filter-name-text text-ellipsis">
                    <OnHoverTooltip overlay={alert.legalName}>
                        {alert.legalName}
                    </OnHoverTooltip>
                </div>
            );
        },
        className: "data-list-cell-xl",
        sortingField: "legalName",
        sortingType: "string",
        stickLeft: true,
    },
    {
        columnKey: "di-description",
        renderColumnHeaderContent: () => (
            <span className="text-medium">
                Inventory
                <EmailInfoTooltip overlayText="Alerts are sent once bank’s security was added/updated/marked as traded on the Dealer Inventory." />
            </span>
        ),
        renderColumnContent: () => "",
        className: "data-list-cell-sm-01",
    },
    {
        columnKey: "di-never",
        renderColumnHeaderContent: ({
            bankDealerInventoryAlert,
            onHeaderClick,
            disabled,
        }) => (
            <MultiTableHeaderRadioButton
                type={UserConfigType.bankDealerInventoryAlert}
                option={AlertOption.Never}
                allOptions={bankDealerInventoryAlert}
                onChange={onHeaderClick}
                disabled={disabled}
                feature={SubscriptionFeature.InventoryAlerts}
            />
        ),
        renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
            const { companyReferenceName, alertOption } =
                alertValue.bankDealerInventoryAlert;
            const name = "di-" + companyReferenceName;
            return (
                <ActionBlocker feature={SubscriptionFeature.InventoryAlerts}>
                    {(blocked) => (
                        <RadioButton
                            disabled={disabled || blocked}
                            key={name}
                            name={name}
                            checked={
                                alertOption === AlertOption.Never || blocked
                            }
                            onChange={() =>
                                onColumnClick(
                                    UserConfigType.bankDealerInventoryAlert,
                                    companyReferenceName,
                                    AlertOption.Never
                                )
                            }
                        />
                    )}
                </ActionBlocker>
            );
        },
        className: "data-list-cell-control",
    },
    {
        columnKey: "di-instant",
        renderColumnHeaderContent: ({
            bankDealerInventoryAlert,
            onHeaderClick,
            disabled,
        }) => (
            <>
                <MultiTableHeaderRadioButton
                    type={UserConfigType.bankDealerInventoryAlert}
                    option={AlertOption.Instant}
                    allOptions={bankDealerInventoryAlert}
                    onChange={onHeaderClick}
                    disabled={disabled}
                    feature={SubscriptionFeature.InventoryAlerts}
                />
                <EmailInfoTooltip overlayText="Alerts are sent once bank’s security was added/updated/marked as traded on the Dealer Inventory." />
            </>
        ),
        renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
            const { companyReferenceName, alertOption } =
                alertValue.bankDealerInventoryAlert;
            const name = "di-" + companyReferenceName;
            return (
                <ActionBlocker feature={SubscriptionFeature.InventoryAlerts}>
                    {(blocked) => (
                        <RadioButton
                            disabled={disabled || blocked}
                            key={name}
                            name={name}
                            checked={
                                blocked
                                    ? false
                                    : alertOption === AlertOption.Instant
                            }
                            onChange={() =>
                                onColumnClick(
                                    UserConfigType.bankDealerInventoryAlert,
                                    companyReferenceName,
                                    AlertOption.Instant
                                )
                            }
                        />
                    )}
                </ActionBlocker>
            );
        },
        className:
            "data-list-cell-sm data-list-cell-control data-list-cell-right-border",
    },
];

const banksImAlertsColumnsDefinition: IColumnDefinition<BanksColumns>[] = [
    {
        columnKey: "im-description",
        renderColumnHeaderContent: () => (
            <span className="text-medium">
                Issuance Monitor{" "}
                <EmailInfoTooltip overlayText="Receive an email when bank’s transactions are published or updated on Issuance Monitor." />
            </span>
        ),
        renderColumnContent: (alert) => "",
        className: "data-list-cell-lg",
    },
    {
        columnKey: "im-never",
        renderColumnHeaderContent: ({
            bankImAlerts,
            onHeaderClick,
            disabled,
        }) => (
            <MultiTableHeaderRadioButton
                type={UserConfigType.bankIssuanceMonitorAlert}
                option={AlertOption.Never}
                allOptions={bankImAlerts}
                onChange={onHeaderClick}
                disabled={disabled}
                features={bankImApRequiredFeatures}
            />
        ),
        renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
            const { companyReferenceName, alertOption } =
                alertValue.bankImAlerts;
            const name = "im-" + companyReferenceName;
            return (
                <ActionBlocker features={bankImApRequiredFeatures} requireAllFeatures>
                    {(blocked) => (
                        <RadioButton
                            disabled={disabled || blocked}
                            key={name}
                            name={name}
                            checked={
                                alertOption === AlertOption.Never || blocked
                            }
                            onChange={() =>
                                onColumnClick(
                                    UserConfigType.bankIssuanceMonitorAlert,
                                    companyReferenceName,
                                    AlertOption.Never
                                )
                            }
                        />
                    )}
                </ActionBlocker>
            );
        },
        className: "data-list-cell-control data-list-cell-xs",
    },
    {
        columnKey: "im-daily",
        renderColumnHeaderContent: ({
            bankImAlerts,
            onHeaderClick,
            disabled,
        }) => (
            <>
                <MultiTableHeaderRadioButton
                    type={UserConfigType.bankIssuanceMonitorAlert}
                    option={AlertOption.Daily}
                    allOptions={bankImAlerts}
                    onChange={onHeaderClick}
                    disabled={disabled}
                    features={bankImApRequiredFeatures}
                />
                <EmailInfoTooltip overlayText="Alerts are sent at 5 PM EST every business day excluding holidays if bank’s transactions are published or updated" />
            </>
        ),
        renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
            const { companyReferenceName, alertOption } =
                alertValue.bankImAlerts;
            const name = "im-" + companyReferenceName;
            return (
                <ActionBlocker features={bankImApRequiredFeatures} requireAllFeatures>
                    {(blocked) => (
                        <RadioButton
                            disabled={disabled || blocked}
                            key={name}
                            name={name}
                            checked={
                                blocked
                                    ? false
                                    : alertOption === AlertOption.Daily
                            }
                            onChange={() =>
                                onColumnClick(
                                    UserConfigType.bankIssuanceMonitorAlert,
                                    companyReferenceName,
                                    AlertOption.Daily
                                )
                            }
                        />
                    )}
                </ActionBlocker>
            );
        },
        className: "data-list-cell-control",
    },
    {
        columnKey: "im-instant",
        renderColumnHeaderContent: ({
            bankImAlerts,
            onHeaderClick,
            disabled,
        }) => (
            <>
                <MultiTableHeaderRadioButton
                    type={UserConfigType.bankIssuanceMonitorAlert}
                    option={AlertOption.Instant}
                    allOptions={bankImAlerts}
                    onChange={onHeaderClick}
                    disabled={disabled}
                    features={bankImApRequiredFeatures}
                />
                <EmailInfoTooltip overlayText="Alerts are sent once bank’s transactions are published or updated" />
            </>
        ),
        renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
            const { companyReferenceName, alertOption } =
                alertValue.bankImAlerts;
            const name = "im-" + companyReferenceName;
            return (
                <ActionBlocker features={bankImApRequiredFeatures} requireAllFeatures>
                    {(blocked) => (
                        <RadioButton
                            disabled={disabled || blocked}
                            key={name}
                            name={name}
                            checked={
                                blocked
                                    ? false
                                    : alertOption === AlertOption.Instant
                            }
                            onChange={() =>
                                onColumnClick(
                                    UserConfigType.bankIssuanceMonitorAlert,
                                    companyReferenceName,
                                    AlertOption.Instant
                                )
                            }
                        />
                    )}
                </ActionBlocker>
            );
        },
        className:
            "data-list-cell-sm-01 data-list-cell-control data-list-cell-right-border",
    },
];

const banksApAlertsColumnsDefinition: IColumnDefinition<BanksColumns>[] = [
    {
        columnKey: "ap-description",
        renderColumnHeaderContent: () => (
            <span className="text-medium">
                Arrangers Pipeline{" "}
                <EmailInfoTooltip overlayText="Receive an email when selected bank’s transactions are published on Arranger Pipeline" />
            </span>
        ),
        renderColumnContent: (alert) => "",
        className: "data-list-cell-lg",
    },
    {
        columnKey: "ap-never",
        renderColumnHeaderContent: ({
            bankApAlerts,
            onHeaderClick,
            disabled,
        }) => (
            <MultiTableHeaderRadioButton
                type={UserConfigType.bankArrangerPipelineAlert}
                option={AlertOption.Never}
                allOptions={bankApAlerts}
                onChange={onHeaderClick}
                disabled={disabled}
                features={bankImApRequiredFeatures}
            />
        ),
        renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
            const { companyReferenceName, alertOption } =
                alertValue.bankApAlerts;
            const name = "ap-" + companyReferenceName;
            return (
                <ActionBlocker features={bankImApRequiredFeatures} requireAllFeatures>
                    {(blocked) => (
                        <RadioButton
                            disabled={disabled || blocked}
                            key={name}
                            name={name}
                            checked={
                                alertOption === AlertOption.Never || blocked
                            }
                            onChange={() =>
                                onColumnClick(
                                    UserConfigType.bankArrangerPipelineAlert,
                                    companyReferenceName,
                                    AlertOption.Never
                                )
                            }
                        />
                    )}
                </ActionBlocker>
            );
        },
        className: "data-list-cell-control data-list-cell-xs",
    },
    {
        columnKey: "ap-daily",
        renderColumnHeaderContent: ({
            bankApAlerts,
            onHeaderClick,
            disabled,
        }) => (
            <>
                <MultiTableHeaderRadioButton
                    type={UserConfigType.bankArrangerPipelineAlert}
                    option={AlertOption.Daily}
                    allOptions={bankApAlerts}
                    onChange={onHeaderClick}
                    disabled={disabled}
                    features={bankImApRequiredFeatures}
                />
                <EmailInfoTooltip overlayText="Alerts are sent at 5 PM EST every business day excluding holidays if bank’s transactions are published or updated" />
            </>
        ),
        renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
            const { companyReferenceName, alertOption } =
                alertValue.bankApAlerts;
            const name = "ap-" + companyReferenceName;
            return (
                <ActionBlocker features={bankImApRequiredFeatures} requireAllFeatures>
                    {(blocked) => (
                        <RadioButton
                            disabled={disabled || blocked}
                            key={name}
                            name={name}
                            checked={
                                blocked
                                    ? false
                                    : alertOption === AlertOption.Daily
                            }
                            onChange={() =>
                                onColumnClick(
                                    UserConfigType.bankArrangerPipelineAlert,
                                    companyReferenceName,
                                    AlertOption.Daily
                                )
                            }
                        />
                    )}
                </ActionBlocker>
            );
        },
        className: "data-list-cell-control",
    },
    {
        columnKey: "ap-instant",
        renderColumnHeaderContent: ({
            bankApAlerts,
            onHeaderClick,
            disabled,
        }) => (
            <>
                <MultiTableHeaderRadioButton
                    type={UserConfigType.bankArrangerPipelineAlert}
                    option={AlertOption.Instant}
                    allOptions={bankApAlerts}
                    onChange={onHeaderClick}
                    disabled={disabled}
                    features={bankImApRequiredFeatures}
                />
                <EmailInfoTooltip overlayText="Alerts are sent once bank’s upcoming transactions are published on Arranger Pipeline" />
            </>
        ),
        renderColumnContent: (alertValue, { disabled, onColumnClick }) => {
            const { companyReferenceName, alertOption } =
                alertValue.bankApAlerts;
            const name = "ap-" + companyReferenceName;
            return (
                <ActionBlocker features={bankImApRequiredFeatures} requireAllFeatures>
                    {(blocked) => (
                        <RadioButton
                            disabled={disabled || blocked}
                            key={name}
                            name={name}
                            checked={
                                blocked
                                    ? false
                                    : alertOption === AlertOption.Instant
                            }
                            onChange={() =>
                                onColumnClick(
                                    UserConfigType.bankArrangerPipelineAlert,
                                    companyReferenceName,
                                    AlertOption.Instant
                                )
                            }
                        />
                    )}
                </ActionBlocker>
            );
        },
        className: "data-list-cell-sm-01 data-list-cell-control",
    },
];

export const banksAlertsColumns = [
    ...banksDiAlertsColumnsDefinition,
    ...banksImAlertsColumnsDefinition,
    ...banksApAlertsColumnsDefinition,
].map((c) => new ColumnBuilder(c));
