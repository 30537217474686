import { isEqual } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../../../../effects/useAppSelector";
import { manageEmailPreferencesActions } from "../../../../../../actions/manage-email-preferences.actions";
import { AlertOption } from "../../../../../../types/email-preferences/EmailPreferences";
import { UserConfigType } from "../../../../../../types/user-config/UserConfigType";
import { isRequesting } from "../../../../../../utils";
import { Preloader } from "../../../../../common";
import { RouteLeavingGuard } from "../../../../../routing";
import { EmailPreferencesRadioGroup } from "../EmailPreferencesRadioGroup";
import { SaveButton } from "../SaveButton";
import { UnsubscribeAllButton } from "../UnsubscribeAllButton";
import { ShowFor } from "../../../../../access";
import { SubscriptionFeature } from "../../../../../../types/billing/SubscriptionFeature";
import { BanksAlertsSection } from "./BanksAlertsSection";
import { DailyStatsAlertsConfig } from "../../../../../../types/user-config/UserConfig";
import { alertDescriptions } from "../../../../../../constants/banks/alertDescriptions";
import { alertOptionTooltipText } from "../../../../../../constants/amr-pipeline/alertOptions";
import { hasBankAnalyticsAlertsAccess, hasBankAnalyticsAlertsChangeAccess } from "../../../../../../utils/amr-pipeline.utils";
import { user } from "../../../../../../user";
import { bankImApRequiredFeatures } from "../../../../../../constants/banks/bankImApRequiredFeatures";

export function BanksTab() {
    const dispatch = useDispatch();
    const history = useHistory();

    const userCompany = useAppSelector(
        (s) => s.issuanceMonitor.amrPipelineCommon.userCompany
    );

    const banks = useAppSelector((s) => s.manageEmailPreferences.banks);

    const editBanksPreferences = useAppSelector(
        (s) => s.manageEmailPreferences.editBanksPreferences
    );
    const initialBanksPreferences = useAppSelector(
        (s) => s.manageEmailPreferences.initialBanksPreferences
    );
    const isRequestingSavePreferences = useAppSelector((s) =>
        isRequesting(
            s.manageEmailPreferences.requestStateSaveIssuanceMonitorPreferences
        )
    );

    const withAnalyticsAccess = hasBankAnalyticsAlertsAccess(banks, userCompany);
    const withAnalyticsChangeAccess = hasBankAnalyticsAlertsChangeAccess(banks, userCompany);
    const withDiAccess = user.hasFeatures(SubscriptionFeature.InventoryAlerts);
    const withImApAccess = user.hasAllFeatures(...bankImApRequiredFeatures);

    const bankAnalyticsAlert =
        editBanksPreferences?.bankAnalyticsAlert.value.find(
            (c) => c.companyReferenceName === userCompany?.referenceName
        );
    const bankDealerInventoryAlert =
        editBanksPreferences?.bankDealerInventoryAlert.value || [];
    const bankIssuanceMonitorAlert =
        editBanksPreferences?.bankIssuanceMonitorAlert.value || [];
    const bankArrangerPipelineAlert =
        editBanksPreferences?.bankArrangerPipelineAlert.value || [];

    const hasChanges = !isEqual(editBanksPreferences, initialBanksPreferences);

    const isUnsubscribeAllVisible =
        [
            ...(withAnalyticsChangeAccess && bankAnalyticsAlert ? [bankAnalyticsAlert] : []),
            ...(withDiAccess ? bankDealerInventoryAlert : []),
            ...(withImApAccess ? bankIssuanceMonitorAlert : []),
            ...(withImApAccess ? bankArrangerPipelineAlert : []),
        ].some((alert) => alert.alertOption !== AlertOption.Never);

    const handleUnsubscribeAll = () => {
        dispatch(
            manageEmailPreferencesActions.unsubscribeAllBanks(
                userCompany?.referenceName
            )
        );
    };

    const save = () => {
        dispatch(
            manageEmailPreferencesActions.saveBanksRequest()
        );
    };

    if (!userCompany) {
        return <Preloader inProgress />;
    }

    return (
        <>
            <div className="flex-row justify-content-end save-changes">
                <ShowFor
                    features={[
                        SubscriptionFeature.DealerProfileEmailAlerts,
                        SubscriptionFeature.IssuanceMonitorFullAccess,
                        SubscriptionFeature.InventoryAlerts,
                    ]}
                >
                    <UnsubscribeAllButton
                        onClick={handleUnsubscribeAll}
                        disabled={
                            isRequestingSavePreferences ||
                            !isUnsubscribeAllVisible
                        }
                    />
                </ShowFor>
                <SaveButton
                    disabled={!hasChanges || isRequestingSavePreferences}
                    onClick={save}
                />
            </div>

            {withAnalyticsAccess && (
                <EmailPreferencesRadioGroup
                    value={bankAnalyticsAlert?.alertOption ?? AlertOption.Never}
                    disabled={isRequestingSavePreferences}
                    alertOptions={[AlertOption.Never, AlertOption.Daily]}
                    alertOptionInfo={{
                        [AlertOption.Daily]: alertOptionTooltipText.Daily,
                    }}
                    groupNameIndentifier="banks-analytics-alerts"
                    title="Analytics Alerts"
                    description={alertDescriptions.analytics}
                    requiredFeature={SubscriptionFeature.DealerProfileEmailAlerts}
                    onChange={(alertOption) =>
                        dispatch(
                            manageEmailPreferencesActions.setBanksAnalyticsAlert(
                                {
                                    type: UserConfigType.bankAnalyticsAlert,
                                    value: [
                                        {
                                            ...bankAnalyticsAlert!,
                                            companyReferenceName:
                                                userCompany.referenceName,
                                            alertOption,
                                        },
                                    ],
                                } as DailyStatsAlertsConfig
                            )
                        )
                    }
                />
            )}

            <div className="banks-alerts">
                <h4>Banks Alerts</h4>
                <BanksAlertsSection disabled={isRequestingSavePreferences} />
            </div>
            <RouteLeavingGuard
                navigate={(pathname: string) => {
                    dispatch(manageEmailPreferencesActions.rollbackBanks());
                    history.push(pathname);
                }}
                shouldBlockNavigation={() => hasChanges}
            />
        </>
    );
}
