import * as React from 'react';
import { useSelector } from "react-redux";
import { AppState } from '../../../../types/state/AppState';
import { TwoFactorAuthSettingsSection } from './TwoFactorAuthSettingsSection';
import { PhoneEdit } from './PhoneEdit';
import { formatUtils } from '../../../../utils';
import { tfaSettingsActions } from '../../../../actions/tfa-settings.actions';
import { SubmitVerificationCodePopup } from './SubmitVerificationCodePopup';
import { Confirm } from '../../../alerts';
import IconSVG from '../../../../styles/svg-icons';
import { StatusMessageSectionType } from "../../../../types/state/NotificationState";
import { StatusMessageSection } from "../../../status-message/StatusMessageSection";
import { useAppDispatch } from '../../../../effects/useAppDispatch';

export function TwoFactorAuthSettings() {
    const dispatch = useAppDispatch();
    const {
        profile,
        isSmsConfirmationDeactivating,
        isIdentityPhoneSaving,
        isPhoneVerificationCodeSending,
        isIdentityPhoneEdit,
        smsActivationCodeRequestedDate
    } = useSelector((state: AppState) => state.profile);
    const [smsActive, setSmsActive] = React.useState(profile ? profile.identityPhoneConfirmed : false);
    const [deactiveteConfirmationVisible, setDeactivePhoneConfirmationVisible] = React.useState(false);

    React.useEffect(() => {
        if (profile && profile.identityPhoneConfirmed !== smsActive) {
            setSmsActive(profile.identityPhoneConfirmed)
        } // eslint-disable-next-line
    }, [profile && profile.identityPhoneConfirmed]);

    if (!profile || !profile.tfaEnabled) {
        return null;
    }

    const isDisabled = isSmsConfirmationDeactivating || isIdentityPhoneSaving || isPhoneVerificationCodeSending;
    const maskedEmail = formatUtils.maskEmail(profile.email);
    const maskedPhone = formatUtils.maskPhone(profile.identityPhone);

    const handleSmsActiveChange = (active: boolean) => {
        setSmsActive(active);

        if (active && profile.identityPhone && !profile.identityPhoneConfirmed) {
            dispatch(tfaSettingsActions.requestPhoneVerificationCode());
        } else {
            dispatch(tfaSettingsActions.identityPhoneEdit(active));
            if (!active && profile.identityPhoneConfirmed) {
                dispatch(tfaSettingsActions.deactivateSmsConfirmation());
            }
        }
    }

    const handlePhoneDeleteConfirmed = () => {
        setSmsActive(false);
        setDeactivePhoneConfirmationVisible(false);
        dispatch(tfaSettingsActions.deactivateSmsConfirmation());
    }

    const handlePhoneSave = (phone: string) => dispatch(tfaSettingsActions.saveIdentityPhone(phone));

    const handlePhoneEditCancel = () => {
        if (!isDisabled) {
            setSmsActive(profile.identityPhoneConfirmed);
            dispatch(tfaSettingsActions.storeSmsActivationRequestedDate(undefined));
            dispatch(tfaSettingsActions.identityPhoneEdit(false));
        }
    }

    return (
        <div className="tab-pane tfa container-flex">
            <div className="sub-title">
                <h1>Two-factor authentication</h1>
            </div>
            <div className="content-section container-flex">
                <StatusMessageSection type={StatusMessageSectionType.Info}>
                    On an attempted login from an unrecognized device or browser, the system will activate two-factor authentication and ask for your password and verification code. Email verification is enabled by default and can't be disabled. Optionally, you can enable SMS verification.
                </StatusMessageSection>
                <div className="tfa-section flex-row">
                    <TwoFactorAuthSettingsSection disabled={true} active={true}>
                        <div className="tfa-section-cnt">
                            <h2>Email</h2>
                            <p>
                                Receive an email containing a verification code. If you want to change your email, please contact us via email:<br/>
                                <a href={`mailto:${process.env.REACT_APP_SALES_EMAIL}`}>{process.env.REACT_APP_SALES_EMAIL}</a>
                            </p>
                            <span className="contact-info">{maskedEmail}</span>
                        </div>
                    </TwoFactorAuthSettingsSection>
                </div>

                <div className="tfa-section flex-row">
                    <TwoFactorAuthSettingsSection
                        active={smsActive}
                        disabled={isDisabled}
                        onActiveChange={handleSmsActiveChange}
                    >
                        <div className="tfa-section-cnt">
                            <h2>SMS</h2>
                            {
                                smsActive &&
                                <p>Receive a text message containing verification code</p>
                            }
                            {
                                !isIdentityPhoneEdit &&
                                profile.identityPhoneConfirmed &&
                                <>
                                    <div className="phone-area flex-row">
                                        <span className="contact-info">{maskedPhone}</span>
                                        <button
                                            type="button"
                                            className="btn-link btn-edit"
                                            onClick={() => dispatch(tfaSettingsActions.identityPhoneEdit(true))}
                                        >
                                            <IconSVG name="edit-pencil" width={16} height={16} />
                                        </button>

                                        <button
                                            className="btn-link btn-danger"
                                            onClick={() => setDeactivePhoneConfirmationVisible(true)}
                                        >
                                            <i className="icon icon-delete" />
                                            Delete Phone Number
                                        </button>
                                    </div>
                                    {
                                        deactiveteConfirmationVisible &&
                                        <Confirm
                                            onCancel={() => setDeactivePhoneConfirmationVisible(false)}
                                            onConfirm={handlePhoneDeleteConfirmed}
                                            title="Confirmation"
                                            text={<>Deleting your phone number will automatically disable SMS verification. Are you sure you want to delete your phone number and disable SMS verification?</>}
                                        />
                                    }
                                </>
                            }
                            {
                                !isIdentityPhoneEdit &&
                                !!profile.identityPhone &&
                                !profile.identityPhoneConfirmed &&
                                <div className="phone-area">
                                    <span className="contact-info">{maskedPhone}</span>
                                    <div className="status-message alert">
                                        <i className="icon icon-warning" />
                                        <span className="status-message-cnt">The phone wasn't confirmed. Do You want to confirm it?</span>
                                        <div className="flex-item-right">
                                            <button
                                                className="btn btn-main"
                                                onClick={() => dispatch(tfaSettingsActions.requestPhoneVerificationCode())}
                                            >
                                                YES, SEND A CODE
                                            </button>
                                            <button
                                                className="btn btn-ghost"
                                                onClick={() => dispatch(tfaSettingsActions.deactivateSmsConfirmation())}
                                            >
                                                NO, DELETE IT
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                isIdentityPhoneEdit &&
                                <PhoneEdit
                                    phone={profile.identityPhone}
                                    phoneConfirmed={profile.identityPhoneConfirmed}
                                    disabled={isDisabled}
                                    onSave={handlePhoneSave}
                                    onCancel={handlePhoneEditCancel}
                                />
                            }
                            {
                                smsActivationCodeRequestedDate != null &&
                                <SubmitVerificationCodePopup
                                    initialDate={smsActivationCodeRequestedDate}
                                    maskedPhone={maskedPhone}
                                    onSubmit={(code: string) => dispatch(tfaSettingsActions.submitPhoneVerificationCode(code))}
                                    onClose={handlePhoneEditCancel}
                                />
                            }
                        </div>
                    </TwoFactorAuthSettingsSection>
                </div>
            </div>
        </div>
    );
}
