import * as React from 'react';
import { Toggle } from '../../../controls';

interface Props {
    active: boolean,
    disabled?: boolean
    children: React.ReactNode,
    onActiveChange?: (active: boolean) => void
}

export function TwoFactorAuthSettingsSection({ active, disabled = false, children, onActiveChange }: Props) {
    return (
        <>
            {children}
            <Toggle
                label=""
                value={active}
                disabled={disabled}
                onClick={onActiveChange ? () => onActiveChange(!active) : undefined}
            />
        </>
    );
}
