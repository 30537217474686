import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../../effects/useAppSelector";
import { UnsubscribeAllButton } from "../UnsubscribeAllButton";
import { manageEmailPreferencesActions } from "../../../../../../actions/manage-email-preferences.actions";
import { AlertOption } from "../../../../../../types/email-preferences/EmailPreferences";
import { UserConfigFilter } from "../../../../../../types/user-config/UserConfigFilter";

interface Props {
    disabled?: boolean;
    filterAlertsStatuses?: UserConfigFilter[];
}

export function UnsubscribeInventoryAlertsButton({ disabled, filterAlertsStatuses }: Props) {
    const dispatch = useDispatch();

    const dealerInventoryAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.dealerInventoryAlertState);
    const editSavedFiltersPreferences = useAppSelector(s => s.manageEmailPreferences.editSavedFiltersPreferences);
    const hasDealerInventoryAlerts = dealerInventoryAlertState !== AlertOption.Never;
    const hasActiveFilterAlerts = filterAlertsStatuses?.some(f =>
        editSavedFiltersPreferences[f.referenceName]
            ? editSavedFiltersPreferences[f.referenceName] !== AlertOption.Never
            : f.alertOption !== AlertOption.Never
    );

    const handleUnsubscribeAll = () => {
        let newFilterAlerts: Record<string, AlertOption> = {};

        filterAlertsStatuses?.forEach(f => {
            newFilterAlerts[f.referenceName] = AlertOption.Never;
        })

        if (hasActiveFilterAlerts) {
            dispatch(manageEmailPreferencesActions.savedFilterAlertOptionChange(newFilterAlerts));
        }
        if (hasDealerInventoryAlerts) {
            dispatch(manageEmailPreferencesActions.setDealerInventoryAlertState(AlertOption.Never));
        }
    }

    return (
        <UnsubscribeAllButton
            onClick={handleUnsubscribeAll}
            disabled={disabled || !(hasDealerInventoryAlerts || hasActiveFilterAlerts)}
        />
    );
}