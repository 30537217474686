import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { manageEmailPreferencesActions } from '../../../../../actions/manage-email-preferences.actions';
import { useAppSelector } from '../../../../../effects/useAppSelector';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';
import { AlertOption } from '../../../../../types/email-preferences/EmailPreferences';
import { isRequesting } from '../../../../../utils';
import { ShowFor } from '../../../../access';
import { RouteLeavingGuard } from '../../../../routing';
import { IssuanceMonitorAlertsSection } from './IssuanceMonitorAlertsSection';
import { SaveButton } from './SaveButton';
import { UnsubscribeAllButton } from './UnsubscribeAllButton';

export function IssuanceMonitorTab() {
    const dispatch = useDispatch();
    const history = useHistory();

    const imAlertState = useAppSelector(s => s.manageEmailPreferences.editIssuanceMonitorPreferences?.imAlertConfig.value.alertOption);
    const imTransactionAlertState = useAppSelector(s => s.manageEmailPreferences.editIssuanceMonitorPreferences?.newTransactionAlertConfig.value.alertOption);
    const imStatisticAlertState = useAppSelector(s => s.manageEmailPreferences.editIssuanceMonitorPreferences?.weeklyStatsAlertConfig.value.emailOption);
    const imFiltersAlertState = useAppSelector(s => s.manageEmailPreferences.editIssuanceMonitorPreferences?.filtersConfig.ImFilter.value);
    const isRequestingSavePreferences = useAppSelector(s => isRequesting(s.manageEmailPreferences.requestStateSaveIssuanceMonitorPreferences));
    const initialIssuanceMonitorPreferences = useAppSelector(s => s.manageEmailPreferences.initialIssuanceMonitorPreferences)

    const hasChanges = !(
        isEqual(initialIssuanceMonitorPreferences?.imAlertConfig.value.alertOption, imAlertState) &&
        isEqual(initialIssuanceMonitorPreferences?.newTransactionAlertConfig.value.alertOption, imTransactionAlertState) &&
        isEqual(initialIssuanceMonitorPreferences?.weeklyStatsAlertConfig.value.emailOption, imStatisticAlertState) &&
        isEqual(initialIssuanceMonitorPreferences?.filtersConfig.ImFilter.value, imFiltersAlertState)
    );

    const isUnsubscribeAllVisible = [
        imAlertState,
        imTransactionAlertState,
        imStatisticAlertState,
        ...(imFiltersAlertState ? imFiltersAlertState.map(filter => filter.alertOption) : [])
    ].filter(option => option !== AlertOption.Never).length > 1;

    const handleUnsubscribeAll = () => {
        dispatch(manageEmailPreferencesActions.unsubscribeAllIssuanceMonitor())
    }

    const save = () => {
        dispatch(manageEmailPreferencesActions.saveRequest())
    }

    return (
        <>
            <div className="flex-row justify-content-end save-changes">
                <ShowFor feature={SubscriptionFeature.IssuanceMonitorFullAccess}>
                    <UnsubscribeAllButton
                        onClick={handleUnsubscribeAll}
                        disabled={isRequestingSavePreferences || !isUnsubscribeAllVisible}
                    />
                </ShowFor>
                <SaveButton disabled={!hasChanges || isRequestingSavePreferences} onClick={save} />
            </div>
            <IssuanceMonitorAlertsSection
                imAlertState={imAlertState}
                imTransactionAlertState={imTransactionAlertState}
                imStatisticAlertState={imStatisticAlertState}
                imFiltersAlertState={imFiltersAlertState}
                disabled={isRequestingSavePreferences}
            />
            <RouteLeavingGuard
                navigate={(pathname: string) => {
                    dispatch(manageEmailPreferencesActions.rollbackIssuanceMonitor());
                    history.push(pathname);
                }}
                shouldBlockNavigation={() => hasChanges}
            />
        </>
    );
}
