import { useState } from "react";
import { useDispatch } from "react-redux";
import { manageEmailPreferencesActions } from "../../../../../../actions/manage-email-preferences.actions";
import { useAppSelector } from "../../../../../../effects/useAppSelector";
import { AlertOption } from "../../../../../../types/email-preferences/EmailPreferences";
import { UserConfigType } from "../../../../../../types/user-config/UserConfigType";
import { Table } from "../../../../../bidding/common/table";
import { SearchInput, EmptyPlaceholder } from "../../../../../common";
import {
    CompanyAlertsValue,
    SecondaryStatsAlertsValue,
} from "../../../../../../types/user-config/UserConfig";
import { banksAlertsColumns } from "./banks-alerts.columns";

interface FilterUpdatesTableProps {
    disabled?: boolean;
}

export const BanksAlertsSection = ({ disabled }: FilterUpdatesTableProps) => {
    const dispatch = useDispatch();
    const banks = useAppSelector((s) => s.manageEmailPreferences.banks);

    const bankImAlerts = useAppSelector(
        (s) =>
            s.manageEmailPreferences.editBanksPreferences
                ?.bankIssuanceMonitorAlert.value
    );
    const bankApAlerts = useAppSelector(
        (s) =>
            s.manageEmailPreferences.editBanksPreferences
                ?.bankArrangerPipelineAlert.value
    );
    const bankDealerInventoryAlert = useAppSelector(
        (s) =>
            s.manageEmailPreferences.editBanksPreferences
                ?.bankDealerInventoryAlert.value
    );

    const alertsList = banks?.map(({ referenceName, legalName }) => ({
        legalName,
        bankImAlerts: bankImAlerts?.find(
            (alert) => alert.companyReferenceName === referenceName
        ),
        bankApAlerts: bankApAlerts?.find(
            (alert) => alert.companyReferenceName === referenceName
        ),
        bankDealerInventoryAlert: bankDealerInventoryAlert?.find(
            (alert) => alert.companyReferenceName === referenceName
        ),
    }));

    const [searchTerm, setSearchTerm] = useState<string>("");

    const getBanks = () => {
        return alertsList?.filter(({ legalName }) =>
            legalName
                ?.toLocaleLowerCase()
                .includes(searchTerm.toLocaleLowerCase())
        );
    };

    const setAlertsOptions = (
        type: UserConfigType,
        mapCallback: (a: CompanyAlertsValue) => CompanyAlertsValue
    ) => {
        switch (type) {
            case UserConfigType.bankDealerInventoryAlert:
                dispatch(
                    manageEmailPreferencesActions.setBanksDealerInventoryAlert({
                        type,
                        value: bankDealerInventoryAlert!.map(
                            mapCallback
                        ) as SecondaryStatsAlertsValue[],
                    })
                );
                break;
            case UserConfigType.bankIssuanceMonitorAlert:
                dispatch(
                    manageEmailPreferencesActions.setBanksIssuanceMonitorAlert({
                        type,
                        value: bankImAlerts!.map(mapCallback),
                    })
                );
                break;
            case UserConfigType.bankArrangerPipelineAlert:
                dispatch(
                    manageEmailPreferencesActions.setBanksArrangerPipelineAlert(
                        {
                            type,
                            value: bankApAlerts!.map(mapCallback),
                        }
                    )
                );
                break;
            default:
                break;
        }
    };

    const onColumnClick = (
        type: UserConfigType,
        referenceName: string,
        alertOption: AlertOption
    ) => {
        const mapList = (a: CompanyAlertsValue) =>
            a.companyReferenceName === referenceName
                ? { ...a, alertOption }
                : a;
        setAlertsOptions(type, mapList);
    };

    const onHeaderClick = (type: UserConfigType, option: AlertOption) => {
        const mapList = (a: CompanyAlertsValue) => ({ ...a, alertOption: option });
        setAlertsOptions(type, mapList);
    };

    return (
        <>
            <SearchInput
                onClear={() => setSearchTerm("")}
                value={searchTerm}
                onChange={(searchTerm: string) => setSearchTerm(searchTerm)}
                placeholder="Search by banks"
            />
            {getBanks()?.length ? (
                <Table
                    columns={banksAlertsColumns}
                    createHeaderCustomArgs={() => ({
                        bankImAlerts,
                        bankApAlerts,
                        bankDealerInventoryAlert,
                        onHeaderClick,
                        disabled,
                    })}
                    createSecurityCustomArgs={() => ({
                        onColumnClick,
                        disabled,
                    })}
                    className="data-list-striped"
                    dataItems={getBanks()}
                    sticky
                />
            ) : (
                <EmptyPlaceholder className="empty-placeholder-without-margin" />
            )}
        </>
    );
};
