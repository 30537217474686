import { useDispatch } from "react-redux";
import { StatisticsAlertOption } from "../../../../../types/amr-pipeline/enums/AlertOption";
import { UserConfigFilter } from "../../../../../types/user-config/UserConfigFilter";
import { manageEmailPreferencesActions } from "../../../../../actions/manage-email-preferences.actions";
import { ImEmailAlerts } from "../../../../amrPipeline/aggregated/email-alerts/issuance-monitor/ImEmailAlerts";
import { AlertOption } from "../../../../../types/email-preferences/EmailPreferences";
interface Props {
    imAlertState?: AlertOption;
    imTransactionAlertState?: AlertOption;
    imStatisticAlertState?: StatisticsAlertOption;
    imFiltersAlertState?: UserConfigFilter[];
    disabled: boolean;
}
export const IssuanceMonitorAlertsSection = ({
    imAlertState,
    imTransactionAlertState,
    imStatisticAlertState,
    imFiltersAlertState,
    disabled,
}: Props) => {
    const dispatch = useDispatch();
    return (
        <div className="modal-email-alerts">
            <ImEmailAlerts
                disabled={disabled}
                singleUpdate={imAlertState}
                newTransactionUpdate={imTransactionAlertState}
                weeklyStatistic={imStatisticAlertState}
                filterAlerts={imFiltersAlertState}
                onChangeSingleUpdate={(option) =>
                    dispatch(
                        manageEmailPreferencesActions.setIssuanceMonitorSingleAlert(
                            option
                        )
                    )
                }
                onChangeNewTransactionUpdate={(option) =>
                    dispatch(
                        manageEmailPreferencesActions.setIssuanceMonitorNewTransactionAlert(
                            option
                        )
                    )
                }
                onChangeWeeklyStatistic={(option) =>
                    dispatch(
                        manageEmailPreferencesActions.setIssuanceMonitorStatisticsAlert(
                            option
                        )
                    )
                }
                onChangeFilterAlerts={(filters: UserConfigFilter[]) =>
                    dispatch(
                        manageEmailPreferencesActions.setIssuanceMonitorFiltersAlert(
                            filters
                        )
                    )
                }
            />
        </div>
    );
};
