import * as React from 'react';
import { RatingMap } from '../../types/models/rating/RatingMap';

interface RatingMappingTableProps {
    ratingMappings: RatingMap[]
}

export function RatingMappingTable({ ratingMappings }: RatingMappingTableProps) {
    const format = (ratings: string[]) =>
        ratings
            .filter(r => !r.toLowerCase().includes('(exp)')) // don't show Fitch (EXP) ratings
            .map(r => r === '/' ? '-/-' : r)
            .join('/');

    const renderMap = (map: RatingMap, index: number) =>
        <tr key={map.aggregated}>
            <td>{map.aggregated}</td>
            <td>{format(map.moodys)}</td>
            <td>{format(map.snP)}</td>
            <td>{format(map.fitch)}</td>
        </tr>

    return (
        <table className="rating-table">
            <thead>
                <tr>
                    <th>RATING</th>
                    <th>MOODY'S</th>
                    <th>S&amp;P</th>
                    <th>FITCH</th>
                </tr>
            </thead>
            <tbody>
                {ratingMappings.map((m, i) => renderMap(m, i))}
            </tbody>
        </table>
    );
}
