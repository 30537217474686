import React from 'react';
import { Switch, Route } from 'react-router';
import { roles, routes } from '../../../../../constants';
import { user } from '../../../../../user';
import { EditUser } from './EditUser';
import { UserList } from './UserList';

export const UsersView: React.FC = () => {
    const isSellerAdmin = user.hasRoles(roles.SellerAdministrator);

    return (
        <div className="tab-pane profile-users container-flex">
            <Switch>
                {isSellerAdmin && <Route path={routes.profileManageCreateNewUser} exact={true} component={EditUser} />}
                {isSellerAdmin && <Route path={routes.profileManageEditUserRequest} exact={true} component={EditUser} />}
                {isSellerAdmin && <Route path={routes.profileManageEditUser} exact={true} component={EditUser} />}
                <Route path={routes.profileManagePlatformUsers} component={UserList} />
            </Switch>
        </div>
    )
};
