import { Redirect, Route, Switch, useParams } from "react-router";
import { TabItem, TabList } from "../../../bidding/common/tab-list";
import { routes } from "../../../../constants/routes";
import { ShowFor } from "../../../access";
import { roles } from "../../../../constants";
import { SettlementAgentLimits } from "./SettlementAgentLimits";
import { SettlementAgentContacts } from "./SettlementAgentContacts";
import { user } from "../../../../user";
import { CompanyLimits } from "./CompanyLimits";

export const tradingLimitsTabs = {
    companyLimits: "company-limits",
    settlementAgentLimits: "settlement-agent-limits",
    settlementContacts: "settlement-contacts"
}

export function TradingLimitsView() {
    const { tab } = useParams<{ tab: string }>();

    if(user.current()?.isClearingBank) return <Redirect to={routes.notFound} />

    const isSeller = user.hasRoles(roles.SellerTrader, roles.SellerViewer, roles.SellerAdministrator);
    const isBd = user.hasRoles(roles.BrokerDealerTrader, roles.BrokerDealerViewer);
    const settllementTabsAccessRoles = [...roles.seller(), ...roles.bd()];

    return (
        <div className="tab-pane trading-limits container-flex">
            <div className="sub-title">
                <h1>Trading Limits</h1>
            </div>
            <TabList>
                <ShowFor roles={[...roles.seller(), roles.SellerAdministrator]}>
                    <TabItem
                        title="Company Limits"
                        path={routes.profileTradingLimitsTab(tradingLimitsTabs.companyLimits)}
                        active={(isSeller && !tab) || tab === tradingLimitsTabs.companyLimits}
                    />
                </ShowFor>
                <ShowFor roles={settllementTabsAccessRoles}>
                    <TabItem
                        title="Settlement Agent Limits"
                        path={routes.profileTradingLimitsTab(tradingLimitsTabs.settlementAgentLimits)}
                        active={(isBd && !tab) || tab === tradingLimitsTabs.settlementAgentLimits}
                    />
                    <TabItem
                        title="Settlement Contacts"
                        path={routes.profileTradingLimitsTab(tradingLimitsTabs.settlementContacts)}
                        active={tab === tradingLimitsTabs.settlementContacts}
                    />
                </ShowFor>
            </TabList>
            <div className="content-section container-flex">
                <Switch>
                    {
                        isSeller && // seller default tab
                        <Route
                            exact={true}
                            path={[routes.profileTradingLimitsTab(), routes.profileTradingLimitsTab(tradingLimitsTabs.companyLimits)]}
                            component={CompanyLimits}
                        />
                    }
                    {
                        isBd && // bd default tab
                        <Route exact={true} path={routes.profileTradingLimitsTab()} component={SettlementAgentLimits} />
                    }
                    {user.hasRoles(...settllementTabsAccessRoles) ?
                        (<>
                            <Route
                                exact={true}
                                path={routes.profileTradingLimitsTab(tradingLimitsTabs.settlementAgentLimits)}
                                component={SettlementAgentLimits}
                            />
                            <Route
                                exact={true}
                                path={routes.profileTradingLimitsTab(tradingLimitsTabs.settlementContacts)}
                                component={SettlementAgentContacts}
                            />
                        </>) : null}
                    <Redirect to={routes.notFound} />
                </Switch>
            </div>
        </div>
    );
}