import React, { PropsWithChildren } from 'react';

interface Props {
    title: React.ReactNode;
}

export const MenageViewHeader: React.FC<PropsWithChildren<Props>> = ({ title, children }) => {
  return (
      <div className="sub-title">
          <h1>{title}</h1>
          {children}
      </div>
  )
};
