import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalAddEditDepartment } from './ModalAddEditDepartment';
import { user } from '../../../user';
import IconSVG from '../../../styles/svg-icons';
import { constants } from '../../../constants';

const RenderContact = ({ contact, isAuthorisedUserPrimaryContact, handleEdit }) => {
    const renderAddEditBtn = (contact) => {
        if (isAuthorisedUserPrimaryContact) {
            if (contact.firstName || contact.lastName || contact.email || contact.phone) {
                return (
                    <div className="profile-cntrl text-right">
                        <button className="btn-link btn-edit flex-none flex-item-right" onClick={() => handleEdit(contact, true)}>
                            <IconSVG name="edit-pencil" width={16} height={16} />
                        </button>
                    </div>
                )
            }
            return (
                <div className="profile-cntrl text-right">
                    <button className="btn btn-ghost btn-sm flex-none flex-item-right"
                            onClick={() => handleEdit(contact)}>+
                        Add contact
                    </button>
                </div>
            )
        }
        return null;
    };
    return (
        <>
            <dt>{contact.departmentName || constants.emptyPlaceholder}</dt>
            <dd>
                <div className="profile-row">
                    <div className="profile-name word-break">
                        {!contact.firstName && !contact.lastName ? constants.emptyPlaceholder : `${contact.firstName || ''} ${contact.lastName || ''}`}
                    </div>
                    <div className="profile-email word-break">
                        {contact.email ? <a href={`mailto:${contact.email}`}>{contact.email}</a> : constants.emptyPlaceholder}
                    </div>
                    <div className="profile-phone">{contact.phone || constants.emptyPlaceholder}</div>
                    {renderAddEditBtn(contact)}
                </div>
            </dd>
        </>
    );
};
export const Company = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [contactForEdit, setContactForEdit] = useState({});
    const [isEditModal, setIsEditModal] = useState(false);

    const currentUser = user.current();
    const company = useSelector(state => state.profile.company || {});

    const contacts = company.departmentContacts ? company.departmentContacts : [];
    const isAuthorisedUserPrimaryContact = company.primaryContactId === currentUser.id;

    const handleEdit = (contact, isEdit) => {
        setIsEditModal(isEdit);
        setContactForEdit({ ...contact });
        setModalVisible(true)
    };

    const handleCloseModal = () => {
        setContactForEdit({});
        setModalVisible(false)
    };

    return (
        <div className="tab-pane company container-flex">
            <div className="sub-title">
                <h1>Information</h1>
            </div>
            <div className="content-section container-flex">
                <div className="section-title">
                    <h2>Company</h2>
                </div>
                <dl className="profile-list">
                    <dt>Company</dt>
                    <dd>{company.companyName}</dd>
                    <dt>Website</dt>
                    <dd className="email">
                        {company.website ? <a href={company.website}>{company.website}</a> : constants.emptyPlaceholder}
                    </dd>
                </dl>
                <dl className="profile-list">
                    <dt>Address</dt>
                    <dd>
                        <address>
                            {company.address}<br />
                            {company.city}, {company.state}<br />
                            {company.zipCode ? `${company.zipCode},` : ''} {company.country}
                        </address>
                    </dd>
                </dl>
                <div className="section-title">
                    <h2>Contacts</h2>
                </div>
                <dl className="profile-list">

                    {contacts.map(contact =>
                        <RenderContact
                            key={contact.departmentValue}
                            contact={contact}
                            isAuthorisedUserPrimaryContact={isAuthorisedUserPrimaryContact}
                            handleEdit={handleEdit}
                        />
                    )}

                </dl>
            </div>
            <ModalAddEditDepartment
                visible={modalVisible}
                onClose={handleCloseModal}
                contact={contactForEdit}
                isEditModal={isEditModal}
            />
        </div>
    );
};
