import React from 'react';
import classNames from 'classnames';
import { UserStatus } from '../../../../../types/account/UserStatus';
import { UserStatusDetails } from '../../../../common/UserStatusDetails';
import { Popover } from '../../../../controls';
import { userRequestStatuses } from '../../../../../constants';
import { UserRequestStatus } from '../../../../../types/management/UserRequestStatus';

interface Props {
    userRequestStatus?: UserRequestStatus;
    userStatus?: UserStatus;
    blockReason?: string;
}

export const RequestUserStatusDetails: React.FC<Props> = ({ userStatus, userRequestStatus, blockReason }) => {
    if (userStatus) {
        return  <UserStatusDetails status={userStatus} blockReason={blockReason || ''} />
    }
    const hasBlockedReason = userRequestStatus === UserRequestStatus.Blocked && blockReason;

    if (userRequestStatus) {
        const statusClasses = classNames({
            [`first-letter status ${userRequestStatuses.getByStatusCode(userRequestStatus)?.className}`]: true,
            'has-blocked-reason': hasBlockedReason
        });

        if (!hasBlockedReason) {
            return (
                <div className={statusClasses}>
                    <span>{userRequestStatuses.getByStatusCode(userRequestStatus)?.title}</span>
                </div>
            )
        }

        return (
            <Popover
                title="Block Reason"
                className="popover-md"
                actionButton={(
                    <div className={statusClasses}>
                        <button className='pseudo-link'>{userRequestStatuses.getByStatusCode(userRequestStatus)?.title}</button>
                    </div>
                )}
            >
                <div className="status-message alert">
                    <i className="icon icon-warning" />
                    <span className="status-message-cnt">{blockReason}</span>
                </div>
            </Popover>
        )
    }

    return null;
};
