import React from 'react';

interface Props {
    children?: React.ReactNode
}

export const MenageViewHeaderButtons: React.FC<Props> = ({ children }: Props) => {
    return (
        <div className="control">
            {children}
        </div>
    )
};
