import { useAppSelector } from '../../../../../effects/useAppSelector';
import { AlertOption } from "../../../../../types/email-preferences/EmailPreferences";
import { Currency } from "../../../../../types/enums/Currency";
import { Rating, Ratings } from "../../../../../types/enums/Rating";
import { isRequesting } from "../../../../../utils";
import { Table } from "../../../../bidding/common/table";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { IColumnDefinition } from "../../../../bidding/common/table/types/ColumnDefinition";
import { EmailPreferencesTableRating } from "./EmailPreferencesTableRating";
import { EmailPreferencesTableRatingTitle } from "./EmailPreferencesTableRatingTitle";
import { EmailPreferencesTableSize } from "./EmailPreferencesTableSize";

export const EmailPreferencesByRating = () => {
    const bwicByRatingAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.bwicByRatingAlertState);
    const isRequestStateSaving = useAppSelector(s => isRequesting(s.manageEmailPreferences.requestStateSaveEditPreferences));
    const disabled = isRequestStateSaving || bwicByRatingAlertState === AlertOption.Never;

    const columnDefinitions: IColumnDefinition<Rating>[] = [
        {
            columnKey: `rating-${Currency.USD}`,
            renderColumnContent: rating =>
                <EmailPreferencesTableRating disabled={disabled} key={rating} currency={Currency.USD} rating={rating} />,
            renderColumnHeaderContent: () => <EmailPreferencesTableRatingTitle currency={Currency.USD} />,
            headerClassName: '',
            bodyClassName: ''
        },
        {
            columnKey: `rating-${Currency.EUR}`,
            renderColumnContent: rating =>
                <EmailPreferencesTableRating disabled={disabled} key={rating} currency={Currency.EUR} rating={rating} />,
            renderColumnHeaderContent: () => <EmailPreferencesTableRatingTitle currency={Currency.EUR} />,
            headerClassName: '',
            bodyClassName: ''
        },
        {
            columnKey: 'min-size',
            renderColumnContent: rating => <EmailPreferencesTableSize disabled={disabled} key={rating} rating={rating} type="minSize" />,
            renderColumnHeaderContent: () => 'Min Size',
            headerClassName: 'data-list-cell-lg padding-l-0',
            bodyClassName: 'data-list-cell-lg text-right overflow-visible'
        },
        {
            columnKey: 'dash',
            renderColumnContent: () => <>&mdash;</>,
            renderColumnHeaderContent: () => '',
            headerClassName: 'data-list-cell-symbol',
            bodyClassName: 'data-list-cell-symbol'
        },
        {
            columnKey: 'max-size',
            renderColumnContent: rating => <EmailPreferencesTableSize disabled={disabled} key={rating} rating={rating} type="maxSize" />,
            renderColumnHeaderContent: () => 'Max Size',
            headerClassName: 'data-list-cell-lg',
            bodyClassName: 'data-list-cell-lg text-right overflow-visible'
        },
    ];
    return <Table className="data-list-striped" columns={columnDefinitions.map(c => new ColumnBuilder(c))} dataItems={Ratings} />;
}
