import { useState } from "react";
import { AlertOption } from "../../../../../../types/email-preferences/EmailPreferences";
import { Portfolio } from "../../../../../../types/portfolio/Portfolio";
import { Popup, PopupBody, PopupFooter } from "../../../../../controls";
import { portfolioActions } from "../../../../../../actions/portfolio.actions";
import { useAppDispatch } from "../../../../../../effects/useAppDispatch";
import { Link } from "../../../../../controls/Link";
import { routes } from "../../../../../../constants";
import { EmailPreferencesTab } from "../../EmailPreferencesContent";
import { PortfolioAlertsBlock } from "./PortfolioAlertsBlock";

interface Props {
    portfolio: Portfolio;
    onClose: () => void;
}

export function SinglePortfolioAlertsPopup({ portfolio, onClose }: Props) {
    const dispatch = useAppDispatch();

    const [bwicAlert, setBwicAlert] = useState(portfolio.bwicAlert);
    const [inventoryAlert, setInventoryAlert] = useState(portfolio.dealersInventoryAlert);
    const [issuanceMonitorAlert, setIssuanceMonitorAlert] = useState(
        portfolio.issuanceMonitorAlert,
    );
    const [outOfRiAlert, setOutOfRiAlert] = useState(portfolio.outOfRiPeriodAlert);
    const [outOfNcAlert, setOutOfNcAlert] = useState(portfolio.outOfNcPeriodAlert);
    const [rollerDeadlineAlert, setRollerDeadlineAlert] = useState(portfolio.rollerDeadlineAlert);

    const hasChanges =
        bwicAlert !== portfolio.bwicAlert ||
        inventoryAlert !== portfolio.dealersInventoryAlert ||
        issuanceMonitorAlert !== portfolio.issuanceMonitorAlert ||
        outOfRiAlert !== portfolio.outOfRiPeriodAlert ||
        outOfNcAlert !== portfolio.outOfNcPeriodAlert ||
        rollerDeadlineAlert !== portfolio.rollerDeadlineAlert;

    const handleSave = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        if (!hasChanges) {
            return;
        }

        dispatch(
            portfolioActions.updatePortfoliosAlertStatus([
                {
                    portfolioId: portfolio.id,
                    bwicAlert: bwicAlert === portfolio.bwicAlert ? undefined : bwicAlert,
                    dealersInventoryAlert:
                        inventoryAlert === portfolio.dealersInventoryAlert ? undefined : inventoryAlert,
                    issuanceMonitorAlert: issuanceMonitorAlert === portfolio.issuanceMonitorAlert ? undefined : issuanceMonitorAlert,
                    outOfRiPeriodAlert: outOfRiAlert === portfolio.outOfRiPeriodAlert ? undefined : outOfRiAlert,
                    outOfNcPeriodAlert: outOfNcAlert === portfolio.outOfNcPeriodAlert ? undefined : outOfNcAlert,
                    rollerDeadlineAlert: rollerDeadlineAlert === portfolio.rollerDeadlineAlert ? undefined : rollerDeadlineAlert,
                },
            ]),
        );

        onClose();
    };

    const handleClose = (e?: React.MouseEvent<HTMLButtonElement> | MouseEvent) => {
        e?.stopPropagation();
        onClose();
    };

    return (
        <Popup
            modalClass="modal-email-preferences modal-portfolio-alerts"
            renderInBody
            title={
                <>
                    Portfolio Alerts <span className="name-tag">{portfolio.name}</span>
                </>
            }
            onClose={handleClose}
        >
            <PopupBody className="notifications-center">
                <PortfolioAlertsBlock
                    bwicAlert={bwicAlert ? AlertOption.Instant : AlertOption.Never}
                    onChangeBwicAlert={o => setBwicAlert(o === AlertOption.Instant)}
                    inventoryAlert={inventoryAlert ? AlertOption.Instant : AlertOption.Never}
                    onChangeInventoryAlert={o => setInventoryAlert(o === AlertOption.Instant)}
                    imAlert={issuanceMonitorAlert ? AlertOption.Instant : AlertOption.Never}
                    onChangeImAlert={(o) => setIssuanceMonitorAlert(o === AlertOption.Instant)}
                    outOfRiAlert={outOfRiAlert ? AlertOption.Periodic : AlertOption.Never}
                    onChangeOutOfRiAlert={o => setOutOfRiAlert(o === AlertOption.Periodic)}
                    outOfNcAlert={outOfNcAlert ? AlertOption.Periodic : AlertOption.Never}
                    onChangeOutOfNcAlert={o => setOutOfNcAlert(o === AlertOption.Periodic)}
                    rollerDeadlineAlert={rollerDeadlineAlert ? AlertOption.Instant : AlertOption.Never}
                    onChangeRollerDeadlineAlert={(o) => setRollerDeadlineAlert(o === AlertOption.Instant)}
                />
            </PopupBody>

            <PopupFooter>
                <Link to={routes.profileNotificationsTab(EmailPreferencesTab.Portfolio)} className="btn-link margin-r-a">
                    Manage Alerts
                </Link>
                <button className="btn btn-ghost" onClick={handleClose}>
                    Cancel
                </button>
                <button className="btn btn-main" onClick={handleSave} disabled={!hasChanges}>
                    Save
                </button>
            </PopupFooter>
        </Popup>
    );
}
