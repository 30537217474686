import * as React from "react";
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';
import { AlertOption } from '../../../../../types/email-preferences/EmailPreferences'
import { ActionBlocker } from '../../../../access/ActionBlocker';
import { BlockedFeatureTooltipText } from '../../../../access/BlockedFeatureText';
import { RadioButton } from '../../../../controls'
import { InfoTooltip } from "../../../../common/InfoTooltip";
import IconSVG from '../../../../../styles/svg-icons';

interface EmailPreferencesRadioGroupProps {
    value: AlertOption;
    disabled?: boolean;
    alertOptions: AlertOption[];
    alertOptionInfo?: { [key in AlertOption]?: string }
    title: string | JSX.Element;
    description?: string;
    groupNameIndentifier: string;
    onChange: (alertOption: AlertOption) => void
    partially?: boolean;
    requiredFeature?: SubscriptionFeature;
    requiredFeatures?: SubscriptionFeature[];
    children?: React.ReactNode;
    collapsible?: boolean;
}

export const EmailPreferencesRadioGroup = (
    {
        value,
        disabled = false,
        alertOptions,
        alertOptionInfo,
        title,
        description,
        groupNameIndentifier,
        onChange,
        partially,
        requiredFeature,
        requiredFeatures,
        children,
        collapsible = false
    }: EmailPreferencesRadioGroupProps) => {
    const [collapsed, setCollapsed] = React.useState(false);
    const renderInfoBlock = (alertOption: AlertOption) => {
        const info = alertOptionInfo?.[alertOption];

        if (!info) {
            return null
        }

        return <InfoTooltip text={info} />
    }

    const renderAlert = (alertOption: AlertOption, blocked?: boolean) => {
        const selectedValue = blocked ? AlertOption.Never : value;
        return (
            <RadioButton
                name={`single-update-${groupNameIndentifier}-${alertOption}`}
                label={alertOption}
                checked={selectedValue === alertOption}
                disabled={disabled || blocked}
                onClick={() => onChange(alertOption)}
                onChange={() => {}}
                partially={partially && alertOption !== AlertOption.Never}
            />
        );
    };

    const renderTitle = () => (
        <h4 onClick={collapsible ? () => setCollapsed(!collapsed) : undefined} className="controls-wrap-title flex-row">
            {
                collapsible &&
                <span className="btn-link">
                    <IconSVG name="icon-expand" className={`${!collapsed ? 'collapse' : ''}`} width={16} height={16} />
                </span>
            }
            {title}
        </h4>
    )

    return (
        <div className="controls-wrap">
            <div className="controls-wrap-head">
                {renderTitle()}
                <div className="controls-wrap-radio">
                    {alertOptions.map(alertOption =>
                        <div key={alertOption} className="controls-radio-item">
                            {requiredFeature || requiredFeatures?.length ? (
                                <ActionBlocker
                                    tooltipContent={<BlockedFeatureTooltipText />}
                                    feature={requiredFeature}
                                    features={requiredFeatures}
                                    requireAllFeatures
                                >
                                    {blocked => renderAlert(alertOption, blocked)}
                                </ActionBlocker>
                            ) : (
                                renderAlert(alertOption)
                            )}
                            {renderInfoBlock(alertOption)}
                        </div>
                    )}
                </div>
            </div>
            {!!description && <p className="controls-wrap-info">{description}</p>}
            {collapsed ? null: children}
        </div>
    );
}
