import { useCompanyUserList } from "../../../../effects/useCompanyUserList";
import { SettlementAgentAgreement } from "../../../../types/bid-as-dealer/SettlementAgentAgreement";
import { SettlementAgentContactsList } from "../../../common/settlment-agent-popup/SettlementAgentContactsList";
import { SettlementAgentTabContent } from "./SettlementAgentTabContent";

export function SettlementAgentContacts() {
    return (
        <SettlementAgentTabContent>
            {agreement => <SettlementContactsContent agreement={agreement} />}
        </SettlementAgentTabContent>
    )
}

interface Props {
    agreement: SettlementAgentAgreement;
}
export function SettlementContactsContent({ agreement }: Props) {
    const { requestStateGetUsers, users } = useCompanyUserList(agreement.settlementAgent.id);

    return <SettlementAgentContactsList
            users={users}
            requestStateGetUsers={requestStateGetUsers}
        />
}