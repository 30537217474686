import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useAppSelector } from '../../../../../../effects/useAppSelector';
import { manageEmailPreferencesActions } from '../../../../../../actions/manage-email-preferences.actions';
import { roles } from '../../../../../../constants';
import { AlertOption } from '../../../../../../types/email-preferences/EmailPreferences';
import { UserConfigType } from '../../../../../../types/user-config/UserConfigType';
import { user } from '../../../../../../user';
import { isRequesting } from '../../../../../../utils';
import { Preloader } from '../../../../../common/Preloader';
import { RouteLeavingGuard } from '../../../../../routing';
import { EmailPreferencesRadioGroup } from '../EmailPreferencesRadioGroup';
import { SaveButton } from '../SaveButton';
import { UnsubscribeAllButton } from '../UnsubscribeAllButton';
import { CloManagerImAlertsSection } from './CloManagerImAlerts';
import { ShowFor } from '../../../../../access';
import { SubscriptionFeature } from '../../../../../../types/billing/SubscriptionFeature';
import { alertDescriptions } from '../../../../../../constants/cloManagers/alertDescriptions';
import { cloImApRequiredFeatures } from '../../../../../../constants/cloManagers/cloImApRequiredFeatures';

export function CloManagerTab() {
    const dispatch = useDispatch();
    const history = useHistory();

    const userCompany = useAppSelector(s => s.issuanceMonitor.amrPipelineCommon.userCompany);

    const editCloManagersPreferences = useAppSelector(s => s.manageEmailPreferences.editCloManagersPreferences);
    const isRequestingSavePreferences = useAppSelector(s =>
        isRequesting(s.manageEmailPreferences.requestStateSaveIssuanceMonitorPreferences),
    );

    const withImApAccess = user.hasAllFeatures(...cloImApRequiredFeatures);
    const withBwicAccess = user.hasFeatures(SubscriptionFeature.BwicMonitorAlerts);
    const withDiAccess = user.hasFeatures(SubscriptionFeature.InventoryAlerts);

    const cloManagersImAlerts = editCloManagersPreferences?.cloManagerIssuanceMonitorAlert.value || [];
    const cloManagersApAlerts = editCloManagersPreferences?.cloManagerArrangerPipelineAlert.value || [];
    const cloManagersBwicAlerts = editCloManagersPreferences?.cloManagerBwicAlert.value || [];
    const cloManagersDiAlerts = editCloManagersPreferences?.cloManagerDealerInventoryAlert.value || [];
    const cloManagersAnalyticAlert = editCloManagersPreferences?.cloManagerAnalyticsAlert.value.find(
        c => c.companyReferenceName === userCompany?.referenceName,
    );

    const initialCloManagersAlertsPreferences = useAppSelector(
        s => s.manageEmailPreferences.initialCloManagersPreferences,
    );

    const isSellerCollateralManager = user.hasRoles(roles.CollateralManager);

    const hasChanges = !isEqual(editCloManagersPreferences, initialCloManagersAlertsPreferences);

    const isUnsubscribeAllVisible =
        [
            ...(isSellerCollateralManager && cloManagersAnalyticAlert ? [cloManagersAnalyticAlert] : []),
            ...(withImApAccess ? [...cloManagersImAlerts, ...cloManagersApAlerts] : []),
            ...(withBwicAccess ? cloManagersBwicAlerts : []),
            ...(withDiAccess ? cloManagersDiAlerts : []),
        ].some(alert => alert.alertOption !== AlertOption.Never);

    const handleUnsubscribeAll = () => {
        dispatch(manageEmailPreferencesActions.unsubscribeAllCloManagers(userCompany?.referenceName));
    };

    const save = () => {
        dispatch(manageEmailPreferencesActions.saveRequest());
    };

    if (!userCompany) {
        return <Preloader inProgress />;
    }

    return (
        <>
            <div className="flex-row justify-content-end save-changes">
                <ShowFor
                    features={[
                        SubscriptionFeature.ManagerProfileManagerRelatedAlerts,
                        SubscriptionFeature.IssuanceMonitorFullAccess,
                        SubscriptionFeature.BwicMonitorAlerts,
                        SubscriptionFeature.InventoryAlerts,
                    ]}
                >
                    <UnsubscribeAllButton
                        onClick={handleUnsubscribeAll}
                        disabled={isRequestingSavePreferences || !isUnsubscribeAllVisible}
                    />
                </ShowFor>
                <SaveButton disabled={!hasChanges || isRequestingSavePreferences} onClick={save} />
            </div>
            {isSellerCollateralManager && (
                <EmailPreferencesRadioGroup
                    value={cloManagersAnalyticAlert?.alertOption ?? AlertOption.Never}
                    disabled={isRequestingSavePreferences}
                    alertOptions={[AlertOption.Never, AlertOption.Daily]}
                    alertOptionInfo={{
                        [AlertOption.Daily]: 'Alerts are sent at 5 AM EST every business day excluding holidays',
                    }}
                    groupNameIndentifier="clo-manager-analytics-alerts"
                    title="Analytics Alerts"
                    description={alertDescriptions.analytics}
                    requiredFeature={SubscriptionFeature.ManagerProfileManagerRelatedAlerts}
                    onChange={alertOption =>
                        dispatch(
                            manageEmailPreferencesActions.setCloManagersAnalyticsAlert({
                                type: UserConfigType.cloManagerAnalyticsAlert,
                                value: [
                                    {
                                        ...cloManagersAnalyticAlert!,
                                        companyReferenceName: userCompany.referenceName,
                                        alertOption,
                                    },
                                ],
                            }),
                        )
                    }
                />
            )}
            <div className="clo-managers-alerts">
                <h4>Managers Alerts</h4>
                <CloManagerImAlertsSection disabled={isRequestingSavePreferences} />
            </div>
            <RouteLeavingGuard
                navigate={(pathname: string) => {
                    dispatch(manageEmailPreferencesActions.rollbackCloManagers());
                    history.push(pathname);
                }}
                shouldBlockNavigation={() => hasChanges}
            />
        </>
    );
}
