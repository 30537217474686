import { useState } from "react";
import { Subscription } from "../../../../../types/billing/Subscription";
import { RequestState } from "../../../../../constants/request-state";
import { useDispatch } from "react-redux";
import { errorActions, notificationActions } from "../../../../../actions";
import { billingService } from "../../../../../services";

export function useSubscriptionRequest(){
    const dispatch = useDispatch();
    const [requestState, setRequestState] = useState(RequestState.none);

    const requestCallback = async (subscription: Subscription) => {
        setRequestState(RequestState.request);

        try{
            await billingService.changeSubscription(subscription.title);
            setRequestState(RequestState.success);
            dispatch(notificationActions.infoModal(
                `Your request for the ${subscription.title} plan subscription has been submitted to the Sales team.`,
                "Subscription request submitted"
            ));
        } catch(e) {
            setRequestState(RequestState.failure);
            dispatch(errorActions.unexpectedError(e));
        }
    }

    return { requestState, requestCallback };
}