import { useEffect, useState } from "react"
import { RequestState } from "../../../../../constants/request-state"
import { Subscription } from "../../../../../types/billing/Subscription";
import { isRequestNone } from "../../../../../utils";
import { billingService } from "../../../../../services";

export function useSubscriptions() {
    const [requestState, setRequestState] = useState(RequestState.none);
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

    useEffect(() => {
        if(isRequestNone(requestState)){
            (async function doRequest() {
                setRequestState(RequestState.request)

                try {
                    const subscriptions = await billingService.getSubscriptions();
                    setSubscriptions(subscriptions);
                    setRequestState(RequestState.success)
                } catch {
                    setRequestState(RequestState.failure)
                }
            })();
        }
    }, [requestState])

    return { requestState, subscriptions };
}