import { keys } from "lodash";
import { useAppSelector } from "../../../../../../effects/useAppSelector";
import { BwicFilterType } from "../../../../../../types/filters/FilterState";
import { PageConfigType } from "../../../../../../types/page-config/PageConfigType";
import { PortfolioUserConfigFilter } from "../../../../../../types/user-config/UserConfigFilter";
import { usePageConfig } from "../../../../../common/pageConfig/usePageConfig";

export function usePortfolioAlertsChangedState() {
    const { config  } = usePageConfig(PageConfigType.Portfolio, true, BwicFilterType.Portfolio);
    const filtersAlertState = useAppSelector(s => s.manageEmailPreferences.portfolioSavedFilterAlerts);
    const portfolioBwicAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioBwicAlertState);
    const portfolioInvneotryAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioDealersInventoryAlertState);
    const issuanceMonitorAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioIssuanceMonitorAlertState);
    const outOfRiAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioOutOfRiPeriodAlertState);
    const outOfNcAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioOutOfNcPeriodAlertState);
    const rollerDeadlineAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioRollerDeadlineAlertState);
    const initialPortfolioBwicAlertState = useAppSelector(s => s.manageEmailPreferences.initialPreferences.portfolioBwicAlertState);
    const initialPortfolioInvnetoryAlertState = useAppSelector(s => s.manageEmailPreferences.initialPreferences.portfolioDealersInventoryAlertState);
    const initialIssuanceMonitorAlertState = useAppSelector(s => s.manageEmailPreferences.initialPreferences.portfolioIssuanceMonitorAlertState);
    const initialOutOfRiAlertState = useAppSelector(s => s.manageEmailPreferences.initialPreferences.portfolioOutOfRiPeriodAlertState);
    const initialOutOfNcAlertState = useAppSelector(s => s.manageEmailPreferences.initialPreferences.portfolioOutOfNcPeriodAlertState);
    const initialRollerDeadlineAlertState = useAppSelector(s => s.manageEmailPreferences.initialPreferences.portfolioRollerDeadlineAlertState);
    const filters = config?.filters as PortfolioUserConfigFilter[] ?? [];

    const getFilterAlertsChangedFlag = () => {
        // filters state has not initialized yet
        if(filters.length &&  !keys(filtersAlertState).length) return false;

        return filters.some(f =>
            f.bwicAlertOption !== filtersAlertState[f.referenceName]?.bwicAlert ||
            f.dealerInventoryAlertOption !== filtersAlertState[f.referenceName]?.inventoryAlert
        );
    }

    const hasChanges =
        initialPortfolioBwicAlertState !== portfolioBwicAlertState ||
        initialPortfolioInvnetoryAlertState !== portfolioInvneotryAlertState ||
        initialIssuanceMonitorAlertState !== issuanceMonitorAlertState ||
        initialRollerDeadlineAlertState !== rollerDeadlineAlertState ||
        initialOutOfRiAlertState !== outOfRiAlertState ||
        initialOutOfNcAlertState !== outOfNcAlertState ||
        getFilterAlertsChangedFlag();

    return hasChanges;
}
