import React from 'react';
import {Relative} from "../../../../common";

interface Props {
    children?: React.ReactNode
}

export const ManageViewContent: React.FC<Props> = ({ children }: Props) => {
    return (
        <Relative className="content-section container-flex">
            {children}
        </Relative>
    )
}
