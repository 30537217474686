import IconSVG from "../../../styles/svg-icons";

interface ProfileMenuSectionProps {
    children: React.ReactNode;
    title: React.ReactNode;
    icon?: string;
}

export const ProfileMenuSection = ({ children, title, icon }: ProfileMenuSectionProps) => (
    <>
        <h3 className="flex-row">
            <IconSVG name={icon} width={16} height={16} />
            {title}
        </h3>
        {children}
    </>
)