import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ChangePassword } from '../../account';
import { profileActions as actions } from '../../../actions';
import { EmptyPlaceholder } from '../../common';

class PasswordSettings extends Component {
    state = {
        isValid: true,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        isLengthValid: false,
        isLetterValid: false,
        isSpecCharsValid: false,
        isNumericValid: false
    }

    componentWillUnmount = () => this.props.dispatch(actions.passwordSettingsReset());

    render = () => {
        const { dispatch, changePasswordVisible } = this.props;

        if (!changePasswordVisible) {
            return (
                <div className="tab-pane password container-flex">
                    <div className="sub-title">
                        <h1>Password</h1>
                    </div>
                    <div className="content-section container-flex">
                        <EmptyPlaceholder text="To change your current password push the button below, please">
                            <button className="btn btn-main" onClick={() => dispatch(actions.changePasswordFormVisible(true))}>
                                Change password
                            </button>
                        </EmptyPlaceholder>
                    </div>
                </div>
            );
        }

        return (
            <ChangePassword onCancel={() => dispatch(actions.changePasswordFormVisible(false))} />
        );
    }
}

const mapStateToProps = ({ profile }) => ({
    changePasswordVisible: profile.changePasswordVisible
});

const connectedPasswordSettings = connect(mapStateToProps)(PasswordSettings);
export { connectedPasswordSettings as PasswordSettings };
