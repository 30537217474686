import * as React from 'react';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import classNames from 'classnames';
import Input from 'react-phone-number-input/input';
import { getCountries, getCountryCallingCode, Country, Value } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';
import { FormError } from '../../../controls';
import { errorMessages } from '../../../../constants';
import { DropDownListItem, DropDownList } from '../../../controls/DropDownList';

interface Props {
    phone: string,
    phoneConfirmed: boolean,
    disabled: boolean,
    onSave: (phone: string) => void
    onCancel: () => void
}

export function PhoneEdit({ phone, phoneConfirmed, disabled, onSave, onCancel }: Props) {
    const validate = (phone: string) => phone ? (isValidPhoneNumber(phone) ? '' : 'Invalid phone number') : errorMessages.empty;
    const [editingPhone, setEditingPhone] = React.useState(validate(phone) ? '' : phone);
    const [errorMessage, setErrorMessage] = React.useState('');
    const parsedPhoneNumber = phone && parsePhoneNumber(phone);
    const [country, setCountry] = React.useState((parsedPhoneNumber && parsedPhoneNumber.country) || 'US');
    const canSave = !disabled && !!editingPhone && !!editingPhone.length && (!phoneConfirmed || phone !== editingPhone);

    const handleSave = () => {
        const error = validate(editingPhone);
        setErrorMessage(error);
        if (!error && onSave) {
            onSave(editingPhone);
        }
    };

    const handleBlur = () => {
        if (errorMessage) {
            const error = validate(editingPhone);
            setErrorMessage(error);
        }
    };

    const handleCancelEdit = () => {
        if (onCancel) {
            onCancel();
        }
    };

    const handleGetCountryCallingCode = (code: Country) => {
        return getCountryCallingCode(code)
    };

    const renderCountrySelectItem = (item: DropDownListItem) => {
        return (
            <>
                <span className="item-value-left">+{handleGetCountryCallingCode(item.value as Country)}</span>
                <span className="item-value-right">{item.text}</span>
            </>
        );
    };

    const renderSelectedItem = (item: DropDownListItem) => <span>+{handleGetCountryCallingCode(item.value as Country)}</span>;

    const handlePhoneCountryChange = (item: DropDownListItem) => {
        setEditingPhone('');
        setErrorMessage('');
        setCountry(item.value as Country);
    };

    const renderCountrySelect = () => {
        const preselected: DropDownListItem[] = ['US'].map(c => ({
            value: c,
            selected: c === country,
            groupKey: 1,
            text: (en as any)[c]
        }));

        const countries: DropDownListItem[] = getCountries()
            .filter((c: string) => !preselected.some(p => p.value === c))
            .map((c: string) => ({
                value: c,
                selected: c === country,
                text: (en as any)[c]
            }));

        return (
            <DropDownList
                className="country-code"
                disabled={disabled}
                items={preselected.concat(countries)}
                renderSelectedItemCallback={renderSelectedItem}
                renderItemContentCallback={renderCountrySelectItem}
                onChange={handlePhoneCountryChange}
            />
        );
    };

    return (
        <div className="phone-area flex-row">
            {renderCountrySelect()}
            <div className="form-control-wrapper">
                <Input
                    className={classNames('form-control', { 'is-invalid': !!errorMessage })}
                    country={country}
                    international
                    value={editingPhone as Value}
                    placeholder="Phone Number"
                    onChange={(value?: string) => setEditingPhone(value || '')}
                    onBlur={handleBlur}
                    disabled={disabled}
                />
                <FormError message={errorMessage} />
             </div>
            <button type="button" className="btn btn-main" disabled={!canSave} onClick={handleSave}>Save</button>
            <button type="button" className="btn btn-ghost" disabled={disabled} onClick={handleCancelEdit}>Cancel</button>
        </div>
    );
}
