import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { manageEmailPreferencesActions } from '../../../../../../actions/manage-email-preferences.actions';
import { useAppSelector } from '../../../../../../effects/useAppSelector';
import { AlertOption } from '../../../../../../types/email-preferences/EmailPreferences';
import { UserConfigType } from '../../../../../../types/user-config/UserConfigType';
import { Table } from '../../../../../bidding/common/table';
import { SearchInput, EmptyPlaceholder } from '../../../../../common';
import { cloManagerAlertsColumns } from './clo-managers-im-alerts.columns';
import { CompanyAlertsValue, SecondaryStatsAlertsValue } from '../../../../../../types/user-config/UserConfig';

interface FilterUpdatesTableProps {
    disabled?: boolean;
}

export const CloManagerImAlertsSection = ({ disabled }: FilterUpdatesTableProps) => {
    const dispatch = useDispatch();
    const managers = useAppSelector(s => s.manageEmailPreferences.mangers);
    const cloManagerImAlerts = useAppSelector(
        s => s.manageEmailPreferences.editCloManagersPreferences?.cloManagerIssuanceMonitorAlert.value,
    );
    const cloManagerApAlerts = useAppSelector(
        s => s.manageEmailPreferences.editCloManagersPreferences?.cloManagerArrangerPipelineAlert.value,
    );
    const cloManagerBwicAlerts = useAppSelector(
        s => s.manageEmailPreferences.editCloManagersPreferences?.cloManagerBwicAlert.value,
    );
    const cloManagerDealerInventoryAlert = useAppSelector(
        s => s.manageEmailPreferences.editCloManagersPreferences?.cloManagerDealerInventoryAlert.value,
    );
    const alertsList = managers?.map(({referenceName, legalName}) => ({
        legalName,
        cloManagerImAlerts: cloManagerImAlerts?.find(alert => alert.companyReferenceName === referenceName),
        cloManagerApAlerts: cloManagerApAlerts?.find(alert => alert.companyReferenceName === referenceName),
        cloManagerBwicAlerts: cloManagerBwicAlerts?.find(alert => alert.companyReferenceName === referenceName),
        cloManagerDiAlerts: cloManagerDealerInventoryAlert?.find(alert => alert.companyReferenceName === referenceName),
    }));

    const [searchTerm, setSearchTerm] = useState<string>('');

    const getManagers = () => {
        return alertsList?.filter(({ legalName }) =>
            legalName?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
        );
    };
    
    const setAlertsOptions = (
        type: UserConfigType,
        mapCallback: (a: CompanyAlertsValue) => CompanyAlertsValue
    ) => {
        switch(type) {
            case UserConfigType.cloManagerIssuanceMonitorAlert:
                dispatch(
                    manageEmailPreferencesActions.setCloManagersIssuanceMonitorAlert({
                        type,
                        value: cloManagerImAlerts!.map(mapCallback),
                    }),
                );
                break;
            case UserConfigType.cloManagerArrangerPipelineAlert:
                dispatch(
                    manageEmailPreferencesActions.setCloManagersArrangerPipelineAlert({
                        type,
                        value: cloManagerApAlerts!.map(mapCallback),
                    }),
                ); 
                break;
            case UserConfigType.cloManagerBwicAlert:
                dispatch(
                    manageEmailPreferencesActions.setCloManagersBwicAlert({
                        type,
                        value: cloManagerBwicAlerts!.map(mapCallback) as SecondaryStatsAlertsValue[],
                    }),
                ); 
                break;
            case UserConfigType.cloManagerDealerInventoryAlert:
                dispatch(
                    manageEmailPreferencesActions.setCloManagersDealerInventoryAlert({
                        type,
                        value: cloManagerDealerInventoryAlert!.map(mapCallback) as SecondaryStatsAlertsValue[],
                    }),
                ); 
                break;
            default:
                break;
        }
    };

    const onColumnClick = (type: UserConfigType, referenceName: string, alertOption: AlertOption) => {
        const mapList = (a: CompanyAlertsValue) => a.companyReferenceName === referenceName ? { ...a, alertOption } : a;
        setAlertsOptions(type, mapList);
    };

    const onHeaderClick = (type: UserConfigType, option: AlertOption) => {
        const mapList = (a: CompanyAlertsValue) => ({ ...a, alertOption: option });
        setAlertsOptions(type, mapList);
    };

    return (
        <>
            <SearchInput
                onClear={() => setSearchTerm('')}
                value={searchTerm}
                onChange={(searchTerm: string) => setSearchTerm(searchTerm)}
                placeholder="Search by managers"
            />
            {getManagers()?.length ? (
                <Table
                    columns={cloManagerAlertsColumns}
                    createHeaderCustomArgs={() => ({
                        cloManagerImAlerts,
                        cloManagerApAlerts,
                        cloManagerBwicAlerts,
                        cloManagerDealerInventoryAlert,
                        onHeaderClick,
                        disabled,
                    })}
                    createSecurityCustomArgs={() => ({
                        onColumnClick,
                        disabled,
                    })}
                    className="data-list-striped"
                    dataItems={getManagers()}
                    sticky={true}
                />
            ) : (
                <EmptyPlaceholder className="empty-placeholder-without-margin" />
            )}
        </>
    );
};
