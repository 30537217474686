import { yup } from '../../../validation/yup';
import { constants } from '../../../constants';

export const addNewTraderValidation = () => {
    return yup.object()
        .shape({
            firstName: yup.string()
                .required()
                .matches(constants.name.source)
                .max(64),
            lastName: yup.string()
                .required()
                .matches(constants.name.source)
                .max(64),
            title: yup.string().max(256),
            jobTitle: yup.string().max(256),
            phone: yup.string().trim()
                .required()
                .matches(constants.nonWhitespace.source)
                .max(constants.phoneMaxLength),
            email: yup.string().required().email(),
        })
};
