import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from "classnames";
import { Form } from '../../forms';
import { profileActions } from '../../../actions';
import { validateEmail } from '../../../utils';
import { errorMessages } from '../../../constants';
import { AppState } from '../../../types/state/AppState';
import { FormError } from '../../controls';
import { user } from '../../../user';
import { useAppDispatch } from '../../../effects/useAppDispatch';

export const ChangeBloombergEmail: React.FC = () => {
    const dispatch = useAppDispatch();
    const emailDomain = '@bloomberg.net';
    const email = user.current()?.bloombergEmail || '';
    const requestStatusSaveEmail = useSelector((state: AppState) => state.profile.requestStatusSaveBloombergEmail);
    const [error, setError] = useState('');
    const [emailName, setEmailName] = useState('');
    const isDisabledSaveButton = requestStatusSaveEmail || emailName === email.split('@')[0];

    useEffect(() => {
        setEmailName(email.split('@')[0]);
    }, [email]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const [mail, domain] = value.trim().split('@');
        const newEmailName = `@${domain}` === emailDomain ? mail : value;
        if (error) {
            setError('')
        }
        setEmailName(newEmailName);
    };

    const handleSave = () => {
        const bloombergEmail = `${emailName}${emailDomain}`;
        if (emailName && !validateEmail(bloombergEmail)) {
             return setError(errorMessages.invalidEmail);
        }
        dispatch(profileActions.saveBloombergEmail(emailName ? bloombergEmail : ''))
    };

    return (
        <div className="edit-form-wrapper">
            <Form>
                <label className="form-label" htmlFor="bloombergEmail">Change Bloomberg Email:</label>
                <div className="form-control-wrapper">
                    <div className="bloomberg-email flex-row">
                        <input
                            className={classNames("form-control", {"is-invalid": error})}
                            autoFocus
                            id="bloombergEmail"
                            name="email"
                            value={emailName}
                            maxLength={50}
                            required
                            onChange={handleChange}
                        />
                        <label htmlFor="bloombergEmail">{emailDomain}</label>
                        <FormError message={error}/>
                    </div>
                </div>
                <div>
                    <button className="btn btn-main" onClick={handleSave} type="button"
                            disabled={isDisabledSaveButton}>Save
                    </button>
                    <button
                        className="btn btn-ghost"
                        type="button"
                        onClick={() => dispatch(profileActions.toggleEditBloombergEmail())}
                    >
                        Cancel
                    </button>
                </div>
            </Form>
        </div>
    )
};
