import { SubscriptionFeature } from '../../../../../../types/billing/SubscriptionFeature';
import { AlertOption } from '../../../../../../types/email-preferences/EmailPreferences';
import { ActionBlocker } from '../../../../../access/ActionBlocker';
import EmailInfoTooltip from '../../../../../common/EmailInfoTooltip';
import { ColumnBuilder } from '../../../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../../../../bidding/common/table/types/ColumnDefinition';
import { OnHoverTooltip } from '../../../../../common';
import { RadioButton } from '../../../../../controls';
import { textLabels } from '../../../../../../constants/text-labels';
import { PortfolioFiltersAlertsHeaderRadioButton } from './PortfolioFiltersAlertsHeaderRadioButton';
import { IPortfolioAlertRow } from './PortfolioFilterAlertsTable';
import { PortfolioAlertsTypes } from '../../../../../../types/portfolio/PortfolioAlerts';

const filterName: IColumnDefinition<IPortfolioAlertRow> = {
    columnKey: 'filterName',
    renderColumnHeaderContent: () => 'Filter Name',
    renderColumnContent: row => {
        const filterNameText = row.title
        return (
            <div className="filter-name-text text-ellipsis">
                <OnHoverTooltip overlay={filterNameText}>{filterNameText}</OnHoverTooltip>
            </div>
        );
    },
    className: 'data-list-cell-xl padding-l-0 data-list-cell-right-border data-list-cell-filter-name',
};

const portfolioBwicAlerts: IColumnDefinition<IPortfolioAlertRow>[] = [
    {
        columnKey: 'bwicAlert-description',
        renderColumnHeaderContent: () => (
            <span className="text-medium">
                BWIC
                <EmailInfoTooltip overlayText={textLabels.savedFiltersPortfolioBwicAlertDescription} />
            </span>
        ),
        renderColumnContent: () => '',
        className: 'text-center padding-l-0',
    },
    {
        columnKey: 'bwicAlert-never',
        renderColumnHeaderContent: ({ filters, onHeaderClick, disabled }) => (
            <PortfolioFiltersAlertsHeaderRadioButton
                name="bwicAlert-never"
                type={PortfolioAlertsTypes.bwicAlert}
                option={AlertOption.Never}
                filterAlertState={filters}
                onChange={onHeaderClick}
                disabled={disabled}
                requiredFeature={SubscriptionFeature.PortfolioAlerts}
            />
        ),
        renderColumnContent: (row, { disabled, onColumnClick }) => {
            const name = 'bwicAlert-' + row.filterReferenceName;
            return (
                <ActionBlocker feature={SubscriptionFeature.PortfolioAlerts}>
                    {blocked => (
                        <RadioButton
                            disabled={disabled || blocked}
                            key={name}
                            name={name}
                            checked={row.bwicAlert === AlertOption.Never || blocked}
                            onChange={() =>
                                onColumnClick(
                                    PortfolioAlertsTypes.bwicAlert,
                                    AlertOption.Never,
                                    row
                                )
                            }
                        />
                    )}
                </ActionBlocker>
            );
        },
        className: 'data-list-cell-xs padding-l-0 data-list-cell-control',
    },
    {
        columnKey: 'bwicAlert-instant',
        renderColumnHeaderContent: ({ filters, onHeaderClick, disabled }) => (
            <>
                <PortfolioFiltersAlertsHeaderRadioButton
                    name="bwicAlert-instant"
                    type={PortfolioAlertsTypes.bwicAlert}
                    option={AlertOption.Instant}
                    filterAlertState={filters}
                    onChange={onHeaderClick}
                    disabled={disabled}
                    requiredFeature={SubscriptionFeature.PortfolioAlerts}
                />
                <EmailInfoTooltip overlayText={textLabels.savedFiltersPortfolioBwicInstantAlert} />
            </>
        ),
        renderColumnContent: (row, { disabled, onColumnClick }) => {
            const name = 'bwicAlert-instant-' + row.filterReferenceName;
            return (
                <ActionBlocker feature={SubscriptionFeature.PortfolioAlerts}>
                    {blocked => (
                        <RadioButton
                            disabled={disabled || blocked}
                            key={name}
                            name={name}
                            checked={blocked ? false : row.bwicAlert === AlertOption.Instant}
                            onChange={() =>
                                onColumnClick(
                                    PortfolioAlertsTypes.bwicAlert,
                                    AlertOption.Instant,
                                    row
                                )
                            }
                        />
                    )}
                </ActionBlocker>
            );
        },
        className: 'data-list-cell-sm-01 padding-l-0 data-list-cell-control data-list-cell-right-border',
    },
];

const porfolioInventoryAlerts: IColumnDefinition<IPortfolioAlertRow>[] = [
    {
        columnKey: 'inventoryAlert-description',
        renderColumnHeaderContent: () => (
            <span className="text-medium">
                Inventory
                <EmailInfoTooltip overlayText={textLabels.savedFiltersPortfolioInventoryAlertDescription} />
            </span>
        ),
        renderColumnContent: () => '',
        className: 'data-list-cell-sm-01',
    },
    {
        columnKey: 'inventoryAlert-never',
        renderColumnHeaderContent: ({ filters, onHeaderClick, disabled }) => (
            <PortfolioFiltersAlertsHeaderRadioButton
                name="inventoryAlert-never"
                type={PortfolioAlertsTypes.dealersInventoryAlert}
                option={AlertOption.Never}
                filterAlertState={filters}
                onChange={onHeaderClick}
                disabled={disabled}
                requiredFeature={SubscriptionFeature.PortfolioAlerts}
            />
        ),
        renderColumnContent: (row, { disabled, onColumnClick }) => {
            const name = 'inventoryAlert-never-' + row.filterReferenceName;
            return (
                <ActionBlocker feature={SubscriptionFeature.PortfolioAlerts}>
                    {blocked => (
                        <RadioButton
                            disabled={disabled || blocked}
                            key={name}
                            name={name}
                            checked={row.inventoryAlert === AlertOption.Never || blocked}
                            onChange={() =>
                                onColumnClick(
                                    PortfolioAlertsTypes.dealersInventoryAlert,
                                    AlertOption.Never,
                                    row
                                )
                            }
                        />
                    )}
                </ActionBlocker>
            );
        },
        className: 'data-list-cell-xs padding-l-0 data-list-cell-control',
    },
    {
        columnKey: 'inventoryAlert-instant',
        renderColumnHeaderContent: ({ filters, onHeaderClick, disabled }) => (
            <>
                <PortfolioFiltersAlertsHeaderRadioButton
                    name="inventoryAlert-instant"
                    type={PortfolioAlertsTypes.dealersInventoryAlert}
                    option={AlertOption.Instant}
                    filterAlertState={filters}
                    onChange={onHeaderClick}
                    disabled={disabled}
                    requiredFeature={SubscriptionFeature.PortfolioAlerts}
                />
                <EmailInfoTooltip overlayText={textLabels.savedFiltersInventoryInstantTooltip} />
            </>
        ),
        renderColumnContent: (row, { disabled, onColumnClick }) => {
            const name = 'inventoryAlert-instant+' + row.filterReferenceName;
            return (
                <ActionBlocker feature={SubscriptionFeature.PortfolioAlerts}>
                    {blocked => (
                        <RadioButton
                            disabled={disabled || blocked}
                            key={name}
                            name={name}
                            checked={blocked ? false : row.inventoryAlert === AlertOption.Instant}
                            onChange={() =>
                                onColumnClick(
                                    PortfolioAlertsTypes.dealersInventoryAlert,
                                    AlertOption.Instant,
                                    row
                                )
                            }
                        />
                    )}
                </ActionBlocker>
            );
        },
        className: 'padding-l-0 data-list-cell-control',
    },
];

export const portfolioFilterAlertsColumns = [
    filterName,
    ...portfolioBwicAlerts,
    ...porfolioInventoryAlerts,
].map(c => new ColumnBuilder(c));
