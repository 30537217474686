import React, { useState } from 'react';
import IconSVG from '../../../../../../styles/svg-icons';
import { BwicMonitorAlertsPopup } from './BwicMonitorAlertsPopup';
import { useAppSelector } from '../../../../../../effects/useAppSelector';
import { AlertOption } from '../../../../../../types/email-preferences/EmailPreferences';
import { useEmailPreferences } from '../../../../../../effects/data-accessors/useEmailPreferences';
import { usePageConfig } from '../../../../../common/pageConfig/usePageConfig';
import { PageConfigType } from '../../../../../../types/page-config/PageConfigType';
import { BwicFilterType } from '../../../../../../types/filters/FilterState';

export const BwicMonitorAlertsPreferences = () => {
    const [showModal, setShowModal] = useState(false);
    const isBwicByRatingAlertEnabled = useAppSelector(s => s.entities.emailPreferences.preferences?.bwicByRatingAlertState !== AlertOption.Never);
    const { config } = usePageConfig(PageConfigType.BwicMonitorAll, true, BwicFilterType.BwicMonitor);
    const isAnyFilterAlertEnabled = config?.filters.some(f => f.alertOption !== AlertOption.Never);
    const isAnyAlertEnabled = isBwicByRatingAlertEnabled || isAnyFilterAlertEnabled;

    useEmailPreferences();

    return (<>
        <button
            className="btn btn-link"
            onClick={() => setShowModal(true)}
        >
            <IconSVG name={isAnyAlertEnabled ? "alert" : "alert-off"} width={16} height={16} />
            Alerts
        </button>
        {showModal && <BwicMonitorAlertsPopup onClose={() => setShowModal(false)} />}
    </>)
}
