import { useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { useHistory } from 'react-router';
import { routes } from '../../../constants/routes';
import { user } from '../../../user/user';
import { nameUtils } from '../../../utils/name.utils';
import { profileActions } from '../../../actions/profile.actions';
import { UserProfilePhotoViewer } from '../../user-profile-photo/UserProfilePhotoViewer';
import IconSVG from '../../../styles/svg-icons';
import { OnHoverTooltip } from '../../common/OnHoverTooltip';
import { ProfileMenuItems } from './ProfileMenuItems';
import { ClickOutside } from '../../common/ClickOutside';

export const ProfileMenu = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [showUserDropdown, setShowUserDropdown] = useState(false);

    const handleLogout = () => {
        history.replace(routes.logout);
    };

    const handleUploadUserImage = () => {
        dispatch(profileActions.setVisibleUploadImagePopup(true));
        setShowUserDropdown(false);
    };

    const currentUser = user.current();
    if (!currentUser) {
        return null;
    }
    
    const { firstName, lastName, email } = currentUser;
    const profileClassNames = cn('navbar-profile navbar-item-dropdown', {
        'is-show': showUserDropdown
    });

    const renderUserImage = () =>
        <UserProfilePhotoViewer userId={currentUser.id} alt={nameUtils.getInitials(firstName, lastName)} />

    return (
        <ClickOutside onClick={() => setShowUserDropdown(false)}>
            <div className={profileClassNames}>
                <OnHoverTooltip placement="bottomRight" overlay={`${firstName} ${lastName}`}>
                    <button className="navbar-link navbar-link-profile" onClick={() => setShowUserDropdown(!showUserDropdown)}>
                        {renderUserImage()}
                    </button>
                </OnHoverTooltip>

                <div className="navbar-dropdown-menu navbar-dropdown-profile">
                    <div className="navbar-dropdown-user">
                        <div className="navbar-dropdown-user-avatar">
                            <div className="user-avatar-section">
                                {showUserDropdown && renderUserImage()}
                                <button className="btn btn-main" onClick={handleUploadUserImage}>
                                    <IconSVG name="photo" width={16} height={16} />
                                </button>
                            </div>
                        </div>
                        <div className="navbar-dropdown-user-name word-break">{firstName} {lastName}</div>
                        <div className="navbar-dropdown-user-email">
                            <OnHoverTooltip overlay={email}>
                                <span className="text-ellipsis">{email}</span>
                            </OnHoverTooltip>
                        </div>
                    </div>

                    <ProfileMenuItems onItemClick={() => setShowUserDropdown(false)} />

                    <button className="navbar-dropdown-menu-item navbar-dropdown-sign-out" onClick={handleLogout}>
                        <span className="flex-row">
                            <span className="margin-r-8">Sign Out</span>
                            <IconSVG name="sign-out" width={16} height={16} />
                        </span>
                    </button>
                </div>
            </div>
        </ClickOutside>
    );
}
