import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from "formik";
import { cloneDeep, isEqual } from 'lodash';
import { constants, errorMessages, roles, routes } from '../../../../../constants';
import { yup } from '../../../../../validation/yup';
import { FormFieldLabel } from '../../../../forms/FormFieldLabel';
import { MenageViewHeader } from '../header/MenageViewHeader';
import { MenageViewHeaderButtons } from '../header/MenageViewHeaderButtons';
import { Redirect, useHistory, useParams } from 'react-router';
import { ManageViewContent } from '../content/ManageViewContent';
import { RadioButton } from '../../../../controls';
import { SellerAdminEditFormModel } from '../../../../../types/seller-admin/SellerAdminEditFormModel';
import { sellerAdminUsersActions } from '../../../../../actions/seller-admin-users.actions';
import { AppState } from '../../../../../types/state/AppState';
import { isRequesting, isRequestNone, isRequestSuccess, numericUtils } from '../../../../../utils';
import { Preloader } from '../../../../common';
import { InputField } from '../../../../forms';
import { RequestUserStatusDetails } from './RequestUserStatusDetails';
import { UserStatus } from '../../../../../types/account/UserStatus';
import { UserRequestStatus } from '../../../../../types/management/UserRequestStatus';
import RouteLeavingGuard from '../../../../routing/RouteLeavingGuard';
import { Checkbox } from '../../../../controls/Checkbox';

const initialFormState: SellerAdminEditFormModel = {
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
    jobTitle: '',
    phoneNumber: '',
    primaryContact: false,
    location: '',
    linkedIn: ''
};

const validationSchema = yup.object().shape({
    firstName: yup.string().trim().matches(constants.name).required().max(64),
    lastName: yup.string().trim().matches(constants.name).required().max(64),
    email: yup.string().trim().required().email().max(256, errorMessages.maxLength(256)),
    roles: yup.array().of(yup.string()).min(1, errorMessages.empty).required(),
    jobTitle: yup.string().trim().required().max(256, errorMessages.maxLength(256)),
    phoneNumber: yup.string().trim().matches(constants.nonWhitespace).max(constants.phoneMaxLength),
    location: yup.string().max(64),
    linkedIn: yup.string().trim().matches(constants.linkedIn).max(2083),
});

export const EditUser: React.FC = () => {
    const requestParams = useParams<{ userId?: string, userRequestId?: string }>();
    const userId = numericUtils.numberOrDefault(requestParams.userId);
    const userRequestId = numericUtils.numberOrDefault(requestParams.userRequestId);
    const formUpdatedFromState = useRef(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector((state: AppState) =>
        state.sellerAdminUsers.users.find((user) => user.id === userId)
    );
    const requestStateGetUserList = useSelector((state: AppState) => state.sellerAdminUsers.requestStateGetUserList);
    const requestStatusUpdateRequestUser = useSelector((state: AppState) => state.sellerAdminUsers.requestStatusUpdateRequestUser);
    const requestStatusUpdateUser = useSelector((state: AppState) => state.sellerAdminUsers.requestStatusUpdateUser);
    const userRequest = useSelector((state: AppState) =>
        state.sellerAdminUsers.requestUsers.find((user) => user.id === userRequestId)
    );

    const selectedUser = user || userRequest;

    const initialFormData = useMemo(() => selectedUser
        ? {
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            email: selectedUser.email,
            roles: selectedUser.roles,
            jobTitle: selectedUser.jobTitle || '',
            phoneNumber: selectedUser.phoneNumber || '',
            primaryContact: selectedUser.primaryContact,
            location: selectedUser.location || '',
            linkedIn: selectedUser.linkedIn || ''
        }
        : initialFormState,
        [selectedUser]
    );

    const [formState, setFormState] = useState(cloneDeep(initialFormState));

    useEffect(() => {
        if (isRequestSuccess(requestStatusUpdateRequestUser) || isRequestSuccess(requestStatusUpdateUser)) {
            history.replace(routes.profileManagePlatformUsers);
        }
    }, [requestStatusUpdateRequestUser, requestStatusUpdateUser, history])

    useEffect(() => {
        const formData = user || userRequest;
        if (!formUpdatedFromState.current && formData) {
            setFormState(cloneDeep(initialFormData));
            formUpdatedFromState.current = true;
        }
    }, [user, userRequest, initialFormData]);

    useEffect(() => {
        if (isRequestNone(requestStateGetUserList)) {
            dispatch(sellerAdminUsersActions.getUserListRequest());
        }
    }, [requestStateGetUserList, dispatch]);

    if (isRequestNone(requestStateGetUserList)) return null;

    if ((userId || userRequestId) && !selectedUser && isRequestSuccess(requestStateGetUserList)) {
        return <Redirect to={routes.profileManagePlatformUsers} />
    }

    const handleSave = (values: SellerAdminEditFormModel) => {
        if (userId) {
            dispatch(sellerAdminUsersActions.saveUser(values, userId));
        } else {
            dispatch(sellerAdminUsersActions.saveUserRequest(values, userRequestId, userRequest?.concurrencyStamp))
        }
    };

    const handleRoleChange = (role: string, roles: string[], setFieldValue: (field: string, value: string[]) => void) => {
        if (roles.indexOf(role) > -1) {
            roles.splice(roles.indexOf(role), 1)
        } else {
            roles.push(role);
        }
        setFieldValue('roles', [...roles]);
    };

    const renderError = (error: string | undefined, isTouched: boolean | undefined) => (
        isTouched && error && <div className="form-error">{error}</div>
    );

    const renderTitle = (): React.ReactNode => {
        if (selectedUser) {
            return (
                <>
                    Edit User
                    <RequestUserStatusDetails
                        userStatus={userRequestId ? undefined : selectedUser.status as UserStatus}
                        userRequestStatus={userRequestId ? selectedUser.status as UserRequestStatus : undefined}
                    />
                </>
            )
        }
        return <>Create New User</>
    };

    const renderUserRole = (selectedRoles: string[], setFieldValue: (field: string, value: string[]) => void) => {
        const isSellerTraderChecked = selectedRoles.some(r => r === roles.SellerTrader);
        const isSellerViewerChecked = selectedRoles.some(r => r === roles.SellerViewer);
        const isSellerAdministratorChecked = selectedRoles.some(r => r === roles.SellerAdministrator);
        const isCollateralManagerChecked = selectedRoles.some(r => r === roles.CollateralManager);

        const setUserRole = (role: string) => {
            if (selectedRoles.some(r => r === role)) {
                return;
            }
            setFieldValue('roles', [role])
        };
        return (
            <div className="radio-group">
                <>
                    <RadioButton
                        label={roles.getShortTitle(roles.SellerTrader)}
                        value={roles.SellerTrader}
                        checked={isSellerTraderChecked}
                        onChange={() => setUserRole(roles.SellerTrader)}
                    />
                    {isSellerTraderChecked &&
                        <>
                            <Checkbox
                                checked={isSellerAdministratorChecked && isSellerTraderChecked}
                                onChange={() => handleRoleChange(roles.SellerAdministrator, selectedRoles, setFieldValue)}
                                label={roles.getShortTitle(roles.SellerAdministrator)}
                            />
                            <Checkbox
                                checked={isCollateralManagerChecked && isSellerTraderChecked}
                                onChange={() => handleRoleChange(roles.CollateralManager, selectedRoles, setFieldValue)}
                                label={roles.getTitle(roles.CollateralManager)}
                            />
                        </>}
                </>
                <>
                    <RadioButton
                        label={roles.getShortTitle(roles.SellerViewer)}
                        value={roles.SellerViewer}
                        checked={isSellerViewerChecked}
                        onChange={() => setUserRole(roles.SellerViewer)}
                    />
                    {isSellerViewerChecked &&
                        <>
                            <Checkbox
                                checked={isSellerAdministratorChecked && isSellerViewerChecked}
                                onChange={() => handleRoleChange(roles.SellerAdministrator, selectedRoles, setFieldValue)}
                                label={roles.getShortTitle(roles.SellerAdministrator)}
                            />
                            <Checkbox
                                checked={isCollateralManagerChecked && isSellerViewerChecked}
                                onChange={() => handleRoleChange(roles.CollateralManager, selectedRoles, setFieldValue)}
                                label={roles.getTitle(roles.CollateralManager)}
                            />
                        </>}
                </>
                <RadioButton
                    label={roles.getShortTitle(roles.SellerAdministrator)}
                    value={roles.SellerAdministrator}
                    checked={isSellerAdministratorChecked && !isSellerTraderChecked && !isSellerViewerChecked}
                    onChange={() => setFieldValue('roles', [roles.SellerAdministrator])}
                />
            </div>
        )
    };

    return (
        <Formik
            initialValues={formState}
            validationSchema={validationSchema}
            onSubmit={handleSave}
            enableReinitialize={true}
        >
            {({ values, errors, touched, setFieldValue }) => (
                <Preloader inProgress={isRequesting(requestStateGetUserList)}>
                    <Form noValidate>
                        <MenageViewHeader title={renderTitle()}>
                            <MenageViewHeaderButtons>
                                <button className="btn btn-ghost" type="button" onClick={() => history.goBack()}>
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-main"
                                    type="submit"
                                    disabled={isEqual(values, initialFormData) || isRequesting(requestStatusUpdateRequestUser) || isRequesting(requestStatusUpdateUser)}
                                >
                                    Save
                                </button>
                            </MenageViewHeaderButtons>
                        </MenageViewHeader>
                        <ManageViewContent>
                            <div className="user-form">
                                <div className="form-row">
                                    <div className="form-item">
                                        <InputField
                                            label="First Name"
                                            placeholder="First Name"
                                            markRequired={true}
                                            name="firstName"
                                            pattern={constants.name.source}
                                            loading={false}
                                            maxLength={64}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-item">
                                        <InputField
                                            label="Last Name"
                                            placeholder="Last Name"
                                            markRequired={true}
                                            name="lastName"
                                            pattern={constants.name.source}
                                            loading={false}
                                            maxLength={64}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-item">
                                        <InputField
                                            label="Title"
                                            placeholder="Title"
                                            markRequired={true}
                                            name="jobTitle"
                                            loading={false}
                                            maxLength={256}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-item">
                                        <InputField
                                            label="Email"
                                            placeholder="Email Address"
                                            markRequired={true}
                                            name="email"
                                            pattern={constants.email.source}
                                            loading={false}
                                            maxLength={64}
                                        />
                                    </div>
                                </div>
                                <div className="form-row form-row-role-selector">
                                    <div className="form-item">
                                        <FormFieldLabel required={true} text="User Role" />
                                        <div className="form-control-wrapper">
                                            {renderUserRole(values.roles, setFieldValue)}
                                            {renderError(Array.isArray(errors.roles) ? errors.roles?.join(',') : errors.roles, touched.roles)}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-item">
                                        <InputField
                                            label="Phone"
                                            placeholder="Phone Number"
                                            markRequired={false}
                                            name="phoneNumber"
                                            loading={false}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-item">
                                        <InputField
                                            label="LinkedIn"
                                            placeholder="LinkedIn"
                                            markRequired={false}
                                            name="linkedIn"
                                            pattern={constants.linkedIn.source}
                                            loading={false}
                                            maxLength={2083}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-item">
                                        <InputField
                                            label="Location"
                                            placeholder="Location"
                                            markRequired={false}
                                            name="location"
                                            loading={false}
                                            maxLength={64}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-item">
                                        <div className="form-label">&nbsp;</div>
                                        <Checkbox
                                            label="Primary Company Contact"
                                            checked={values.primaryContact}
                                            onChange={() => setFieldValue('primaryContact', !values.primaryContact)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </ManageViewContent>
                        <RouteLeavingGuard
                            navigate={(pathname: string) => history.push(pathname)}
                            shouldBlockNavigation={() => !isEqual(values, initialFormData)
                                && (!isRequestSuccess(requestStatusUpdateRequestUser) && !isRequestSuccess(requestStatusUpdateUser))
                            }
                        />
                    </Form>
                </Preloader>
            )}
        </Formik>
    )
};
