import { useEffect, useMemo, useState } from "react";
import { cloneDeep, entries, isEqual } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { AppState } from "../../../../types/state/AppState";
import { sellerAdminLimitActions } from "../../../../actions/seller-admin-limit.actions";
import { isRequestNone, isRequestSuccess, isRequesting } from "../../../../utils/request-state.utils";
import { TradingLimitType } from "../../../../types/seller-admin/TradingLimitType";
import { SellerAdminTradingLimitValuesState } from "../../../../types/seller-admin/SellerAdminTradingLimitValuesState";
import { Currency } from "../../../../types/enums/Currency";
import { ShowFor } from "../../../access/ShowFor";
import { roles } from "../../../../constants/roles";
import { Preloader } from "../../../common/Preloader";
import { ExpandableInfoPanel } from "../manage/ExpandableInfoPanel";
import { CompanyLimitInput } from "./CompanyLimitInput";
import RouteLeavingGuard from "../../../routing/RouteLeavingGuard";
import Confirm from "../../../alerts/Confirm";

export function CompanyLimits() {
    const dispatch = useDispatch();
    const history = useHistory();

    const editMode = useSelector((state: AppState) => state.sellerAdminLimit.editMode);
    const tradingLimitsState = useSelector((state: AppState) => state.sellerAdminLimit.tradingLimits);
    const getLimitsRequestStatus = useSelector((state: AppState) => state.sellerAdminLimit.getLimitsRequestStatus);
    const updateLimitsRequestStatus = useSelector((state: AppState) => state.sellerAdminLimit.updateLimitsRequestStatus);

    const [tradingLimits, setTradingLimits] = useState(cloneDeep(tradingLimitsState));
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        return () => {
            dispatch(sellerAdminLimitActions.setEditModeStatus(false));
        }
    }, [dispatch]);

    useEffect(() => {
        if (!isRequesting(getLimitsRequestStatus) && !isRequestSuccess(getLimitsRequestStatus)) {
            dispatch(sellerAdminLimitActions.getTradeLimitsRequest());
        }
    }, [dispatch, getLimitsRequestStatus]);

    useEffect(() => {
        if (!editMode) {
            setTradingLimits(cloneDeep(tradingLimitsState))
        }
    }, [editMode, tradingLimitsState]);

    const isChanged = useMemo(
        () => !isEqual(tradingLimitsState, tradingLimits),
        [tradingLimitsState, tradingLimits]
    );

    if (isRequestNone(getLimitsRequestStatus)) return null;

    const handleSetTradingLimits = (limitKey: TradingLimitType, currencyKey: string, value: number | null) => {
        const updatedLimits: SellerAdminTradingLimitValuesState = { ...tradingLimits };
        updatedLimits[limitKey as unknown as TradingLimitType][currencyKey as Currency] = value;
        setTradingLimits(updatedLimits)
    };

    const handleSave = () => {
        const hasZeroValues = entries(tradingLimits).some(([, { USD, EUR }]) => USD === 0 || EUR === 0);
        if (hasZeroValues) {
            setConfirm(true);
        } else {
            dispatch(sellerAdminLimitActions.updateTradeLimitsRequest(tradingLimits))
        }
    };

    return (
        <div>
            <div className="flex-row description">
                <div className="text-warm-grey">Sell/Buy limits set by the Seller-Admin of your company.</div>
                <div className="control margin-l-a">
                    {editMode ? (
                        <>
                            <button
                                className="btn btn-sm btn-ghost"
                                onClick={() => dispatch(sellerAdminLimitActions.setEditModeStatus(false))}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-sm btn-main"
                                disabled={isRequesting(updateLimitsRequestStatus) || !isChanged}
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </>
                    ) : (
                        <ShowFor role={roles.SellerAdministrator}>
                            <button
                                className="btn btn-sm btn-ghost"
                                onClick={() => dispatch(sellerAdminLimitActions.setEditModeStatus(true))}
                            >
                                Edit
                            </button>
                        </ShowFor>
                    )}

                </div>
            </div>

            <Preloader inProgress={isRequesting(getLimitsRequestStatus)}>
                <ShowFor role={roles.SellerAdministrator}>
                    <ExpandableInfoPanel>
                        <div className="status-message info">
                            <i className="icon icon-info" />
                            <span className="status-message-cnt">
                                <p>
                                    Company Admins can set the following limits:<br />
                                    &middot; Total daily limit on sales and purchases<br />
                                    &middot; Maximum notional per CUSIP (separate for EUR and USD)
                                </p>
                                <p>
                                    <strong><em>If limit is blank, Trader users can transact without restrictions. If zero is applied, selling/buying in this currency is prohibited.</em></strong>
                                </p>
                                <p>
                                    Once the limits are set/updated, all Trader users in the company receive an email notification.
                                </p>
                                <p>
                                    Trader users can view trading limits in: Profile -&gt; Account Settings -&gt; Company.
                                </p>
                            </span>
                        </div>
                    </ExpandableInfoPanel>
                </ShowFor>
                <div className="section">
                    <div className="section-title"><h2>Daily Sell Limit</h2></div>
                    <CompanyLimitInput
                        label="Max bond size per CUSIP, USD"
                        placeholder="Max bond size per CUSIP"
                        value={tradingLimits[TradingLimitType.SellMaxBondSize].USD}
                        editMode={editMode}
                        onChange={(value) => handleSetTradingLimits(TradingLimitType.SellMaxBondSize, Currency.USD, value)}
                    />
                    <CompanyLimitInput
                        label="Max bond size per CUSIP, EUR"
                        placeholder="Max bond size per CUSIP"
                        value={tradingLimits[TradingLimitType.SellMaxBondSize].EUR}
                        editMode={editMode}
                        onChange={(value) => handleSetTradingLimits(TradingLimitType.SellMaxBondSize, Currency.EUR, value)}
                    />
                    <CompanyLimitInput
                        label="Sum of daily trades, USD"
                        placeholder="Sum of daily trades"
                        value={tradingLimits[TradingLimitType.SellMaxDailyTradesSize].USD}
                        editMode={editMode}
                        onChange={(value) => handleSetTradingLimits(TradingLimitType.SellMaxDailyTradesSize, Currency.USD, value)}
                    />
                    <CompanyLimitInput
                        label="Sum of daily trades, EUR"
                        placeholder="Sum of daily trades"
                        value={tradingLimits[TradingLimitType.SellMaxDailyTradesSize].EUR}
                        editMode={editMode}
                        onChange={(value) => handleSetTradingLimits(TradingLimitType.SellMaxDailyTradesSize, Currency.EUR, value)}
                    />
                </div>
                <div className="section">
                    <div className="section-title"><h2>Daily Buy Limit</h2></div>
                    <CompanyLimitInput
                        label="Max bond size per CUSIP, USD"
                        placeholder="Max bond size per CUSIP"
                        value={tradingLimits[TradingLimitType.BuyMaxBondSize].USD}
                        editMode={editMode}
                        onChange={(value) => handleSetTradingLimits(TradingLimitType.BuyMaxBondSize, Currency.USD, value)}
                    />
                    <CompanyLimitInput
                        label="Max bond size per CUSIP, EUR"
                        placeholder="Max bond size per CUSIP"
                        value={tradingLimits[TradingLimitType.BuyMaxBondSize].EUR}
                        editMode={editMode}
                        onChange={(value) => handleSetTradingLimits(TradingLimitType.BuyMaxBondSize, Currency.EUR, value)}
                    />
                    <CompanyLimitInput
                        label="Sum of daily purchase, USD"
                        placeholder="Sum of daily purchase"
                        value={tradingLimits[TradingLimitType.BuyMaxDailyTradesSize].USD}
                        editMode={editMode}
                        onChange={(value) => handleSetTradingLimits(TradingLimitType.BuyMaxDailyTradesSize, Currency.USD, value)}
                    />
                    <CompanyLimitInput
                        label="Sum of daily purchase, EUR"
                        placeholder="Sum of daily purchase"
                        value={tradingLimits[TradingLimitType.BuyMaxDailyTradesSize].EUR}
                        editMode={editMode}
                        onChange={(value) => handleSetTradingLimits(TradingLimitType.BuyMaxDailyTradesSize, Currency.EUR, value)}
                    />
                </div>
            </Preloader>

            <RouteLeavingGuard
                navigate={(pathname: string) => history.push(pathname)}
                shouldBlockNavigation={() => isChanged}
            />
            {
                confirm && (
                    <Confirm
                        text="If zero is applied, selling / buying in this currency is prohibited. Do you want to save limit(s)?"
                        onConfirm={() => {
                            dispatch(sellerAdminLimitActions.updateTradeLimitsRequest(tradingLimits));
                            setConfirm(false);
                        }}
                        onCancel={() => setConfirm(false)}
                    />
                )
            }
        </div >
    );
}