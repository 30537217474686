interface Props {
    onClick: () => void,
    disabled?: boolean
}

export const UnsubscribeAllButton = ({ onClick, disabled }: Props) => {
    return (
        <button className="btn btn-sm btn-ghost" onClick={onClick} disabled={disabled}>
            Unsubscribe All
        </button>
    )
}
