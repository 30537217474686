import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, matchPath } from "react-router";
import classNames from 'classnames'
import { profileTabs as tabs, roles, routes } from '../../constants';
import { profileActions as actions } from '../../actions';
import { Company, PasswordSettings, PersonalInfo, Traders } from './tabs';
import { Preloader } from '../common/Preloader';
import { TwoFactorAuthSettings } from './tabs/tfa/TwoFactorAuthSettings';
import { SubscriptionsView } from './tabs/subscriptions/SubscriptionsView';
import { SubscriptionsTab } from './tabs/subscriptions/SubscriptionsTab';
import { DealersView } from './tabs/manage/dealers/DealersView';
import { UsersView } from './tabs/manage/users/UsersView';
import { ShowFor } from '../access';
import IconSVG from "../../styles/svg-icons";
import { user } from '../../user/user';
import { EmailPreferences } from './tabs/email-preferences/EmailPreferences';
import { EmailPreferencesTab } from "./tabs/email-preferences/EmailPreferencesContent";
import { WithAmrRegistration } from '../routing/WithAmrRegistration';
import { isRequestSuccess } from '../../utils/request-state.utils';
import { TradingLimitsView, tradingLimitsTabs } from './tabs/trading-limits/TradingLimitsView';

const Profile = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const initStatus = useSelector(state => state.profile.initStatus);
    const profile = useSelector(state => state.profile.profile);

    useEffect(() => {
        dispatch(actions.init());
        return () => dispatch(actions.reset())
    }, [dispatch]);

    const currentActiveTab = () => {
        const isProfileManageEditUser = matchPath(history.location.pathname, {
            path: routes.profileManageEditUser,
            exact: true,
            strict: false
        });
        const isProfileManageEditUserRequest = matchPath(history.location.pathname, {
            path: routes.profileManageEditUserRequest,
            exact: true,
            strict: false
        });
        if (isProfileManageEditUser || isProfileManageEditUserRequest) {
            return { tab: tabs.managePlatformUsers };
        }
        switch (history.location.pathname) {
            case routes.profilePersonalInfo:
                return { tab: tabs.personalInfo, parent: tabs.parent.account };
            case routes.profilePasswordSettings:
                return { tab: tabs.passwordSettings, parent: tabs.parent.account };
            case routes.tfaSettings:
                return { tab: tabs.twoFactorAuthenticationSettings, parent: tabs.parent.account };
            case routes.profileNotifications:
                return { tab: tabs.notifications, parent: tabs.parent.account };
            case routes.subscriptionsTab():
            case routes.subscriptionsTab(SubscriptionsTab.overview):
            case routes.subscriptionsTab(SubscriptionsTab.plans):
            case routes.subscriptionsTab(SubscriptionsTab.paymentMethod):
            case routes.subscriptionsTab(SubscriptionsTab.billingInfo):
            case routes.subscriptionsTab(SubscriptionsTab.billingHistory):
                return { tab: tabs.subscriptions, parent: tabs.parent.account };
            case routes.profileCompanyInformation:
                return { tab: tabs.company, parent: tabs.parent.company };
            case routes.profileTradingLimitsTab():
            case routes.profileTradingLimitsTab(tradingLimitsTabs.companyLimits):
            case routes.profileTradingLimitsTab(tradingLimitsTabs.settlementAgentLimits):
            case routes.profileTradingLimitsTab(tradingLimitsTabs.settlementContacts):
                return { tab: tabs.tradingLimits, parent: tabs.parent.company };
            case routes.profileTraders:
                return { tab: tabs.traders, parent: tabs.parent.company };
            case routes.profileManageDealers:
                return { tab: tabs.manageDealers };
            case routes.profileManagePlatformUsers:
            case routes.profileManageCreateNewUser:
                return { tab: tabs.managePlatformUsers };
            case routes.profileNotificationsTab(EmailPreferencesTab.Dashboard):
                return { tab: routes.profileNotificationsTab(EmailPreferencesTab.Dashboard) };
            case routes.profileNotificationsTab(EmailPreferencesTab.BwicMonitor):
                return { tab: routes.profileNotificationsTab(EmailPreferencesTab.BwicMonitor) };
            case routes.profileNotificationsTab(EmailPreferencesTab.MyBwics):
                return { tab: routes.profileNotificationsTab(EmailPreferencesTab.MyBwics) };
            case routes.profileNotificationsTab(EmailPreferencesTab.IssuanceMonitor):
                return { tab: routes.profileNotificationsTab(EmailPreferencesTab.IssuanceMonitor) };
            case routes.profileNotificationsTab(EmailPreferencesTab.DealerInventory):
                return { tab: routes.profileNotificationsTab(EmailPreferencesTab.DealerInventory) };
            case routes.profileNotificationsTab(EmailPreferencesTab.Portfolio):
                return { tab: routes.profileNotificationsTab(EmailPreferencesTab.Portfolio) };
            case routes.profileNotificationsTab(EmailPreferencesTab.CloManagers):
                return { tab: routes.profileNotificationsTab(EmailPreferencesTab.CloManagers) };
            case routes.profileNotificationsTab(EmailPreferencesTab.Banks):
                return { tab: routes.profileNotificationsTab(EmailPreferencesTab.Banks) };
            case routes.profileNotificationsTab(EmailPreferencesTab.ArrangerPipeline):
                return { tab: routes.profileNotificationsTab(EmailPreferencesTab.ArrangerPipeline) };
            default:
                return { tab: '', parent: '' };
        }
    };

    const isActiveTab = tab => currentActiveTab().tab === tab;

    const renderTradingLimitsTab = () => {
        if (user.current()?.isClearingBank) return null;

        return (
            <li
                className={classNames('flex-none', { active: isActiveTab(tabs.tradingLimits) })}
                onClick={() => history.push(routes.profileTradingLimitsTab())}
            >
                Trading Limits
            </li>
        );
    }


    const renderManageTabs = () =>
        <ul className="sidebar-nav-type01">
            <li
                className={classNames('flex-none', { active: isActiveTab(tabs.managePlatformUsers) })}
                onClick={() => history.push(routes.profileManagePlatformUsers)}
            >
                Platform Users
            </li>
            {renderTradingLimitsTab()}
            <ShowFor role={roles.SellerAdministrator}>
                <li
                    className={classNames('flex-none', { active: isActiveTab(tabs.manageDealers) })}
                    onClick={() => history.push(routes.profileManageDealers)}
                >
                    Counterparties
                </li>
            </ShowFor>
        </ul>;

    return (
        <div className="container page-content-profile">
            <Preloader inProgress={!isRequestSuccess(initStatus)}>
                <div className="container-flex container-sidebar">
                    <div className="sidebar">
                        <div className="sidebar-title-type01">
                            <h2>
                                <IconSVG name="settings" width={16} height={16} />
                                Account Settings
                            </h2>
                        </div>
                        <ul className="sidebar-nav-type01">
                            <li
                                className={classNames({
                                    'flex-none': true,
                                    active: isActiveTab(tabs.personalInfo)
                                })}
                                onClick={() => history.push(routes.profilePersonalInfo)}
                            >
                                Personal
                            </li>
                            <li
                                className={classNames({
                                    'flex-none': true,
                                    active: isActiveTab(tabs.passwordSettings)
                                })}
                                onClick={() => history.push(routes.profilePasswordSettings)}
                            >
                                Password
                            </li>
                            {
                                profile && profile.tfaEnabled &&
                                <li
                                    className={classNames({
                                        'flex-none': true,
                                        active: isActiveTab(tabs.twoFactorAuthenticationSettings)
                                    })}
                                    onClick={() => history.push(routes.tfaSettings)}
                                >
                                    Two-factor Authentication
                                    {
                                        profile &&
                                        !profile.identityPhoneConfirmed &&
                                        !!profile.identityPhone &&
                                        <i className="icon icon-warning yellow" />
                                    }
                                </li>
                            }
                            <ShowFor roles={roles.getAllRolesExclude(roles.ArrangersClient)}>
                                <li
                                    className={classNames({
                                        'flex-none': true,
                                        active: (
                                            isActiveTab(routes.profileNotificationsTab(EmailPreferencesTab.Dashboard)) ||
                                            isActiveTab(routes.profileNotificationsTab(EmailPreferencesTab.BwicMonitor)) ||
                                            isActiveTab(routes.profileNotificationsTab(EmailPreferencesTab.MyBwics)) ||
                                            isActiveTab(routes.profileNotificationsTab(EmailPreferencesTab.IssuanceMonitor)) ||
                                            isActiveTab(routes.profileNotificationsTab(EmailPreferencesTab.Portfolio)) ||
                                            isActiveTab(routes.profileNotificationsTab(EmailPreferencesTab.CloManagers)) ||
                                            isActiveTab(routes.profileNotificationsTab(EmailPreferencesTab.DealerInventory)) ||
                                            isActiveTab(routes.profileNotificationsTab(EmailPreferencesTab.Banks)) ||
                                            isActiveTab(routes.profileNotificationsTab(EmailPreferencesTab.ArrangerPipeline))
                                        )
                                    })}
                                    onClick={() => history.push(routes.profileNotificationsTab(EmailPreferencesTab.Dashboard))}
                                >
                                    Alerts Center
                                </li>
                            </ShowFor>
                        </ul>
                        <div className="sidebar-title-type01">
                            <h2>
                                <IconSVG name="company" width={16} height={16} />
                                Company
                            </h2>
                        </div>
                        <ul className="sidebar-nav-type01">
                            <li
                                className={classNames({ 'flex-none': true, active: isActiveTab(tabs.company) })}
                                onClick={() => history.push(routes.profileCompanyInformation)}
                            >
                                Information
                            </li>
                            <ShowFor roles={[...roles.bd(), ...roles.admin(), roles.DataEntry, roles.Viewer]}>
                                <li
                                    className={classNames({ 'flex-none': true, active: isActiveTab(tabs.traders) })}
                                    onClick={() => history.push(routes.profileTraders)}
                                >
                                    Platform Users
                                </li>
                            </ShowFor>
                            <ShowFor roles={roles.seller()}>
                                {!user.hasRoles(roles.SellerAdministrator) && renderManageTabs()}
                            </ShowFor>
                            <ShowFor roles={roles.bd()}>
                                {renderTradingLimitsTab()}
                            </ShowFor>
                            <ShowFor roles={[...roles.subscriptionChange(), roles.Administrator]}>
                                <li
                                    className={classNames({
                                        'flex-none': true,
                                        active: isActiveTab(tabs.subscriptions)
                                    })}
                                    onClick={() => history.push(routes.subscriptionsTab())}
                                >
                                    Subscription
                                </li>
                            </ShowFor>
                        </ul>
                        <ShowFor role={roles.SellerAdministrator}>
                            <div className="sidebar-title-type01">
                                <h2>
                                    <IconSVG name="manage" width={16} height={16} />
                                    Manage
                                </h2>
                            </div>
                            {renderManageTabs()}
                        </ShowFor>
                    </div>
                    <div className="content-part-sidebar profile-content">
                        <Switch>
                            <Route
                                exact={true}
                                path={routes.profile}
                                render={() => <Redirect to={routes.profilePersonalInfo} />}
                            />
                            <Route exact={true} path={routes.profilePersonalInfo} component={PersonalInfo} />
                            <Route title="Profile - KTX ATS Platform" exact={false} path={routes.profileNotifications} allowAnonymous={false} allowRoles={roles.getAllRolesExclude(roles.ArrangersClient)}>
                                <WithAmrRegistration component={EmailPreferences} />
                            </Route>
                            <Route exact={true} path={routes.profileCompanyInformation} component={Company} />
                            <Route exact={true} path={routes.profilePasswordSettings} component={PasswordSettings} />
                            {
                                user.hasRoles(...[...roles.bd(), ...roles.admin(), roles.DataEntry, roles.Viewer]) &&
                                <Route exact={true} path={routes.profileTraders} component={Traders} />
                            }
                            <Route exact={true} path={routes.tfaSettings} component={TwoFactorAuthSettings} />
                            <Route
                                exact={true}
                                path={routes.subscriptions}
                                render={props => <SubscriptionsView activeTab={props.match.params.tab} />}
                            />
                            {
                                user.hasRoles(roles.SellerAdministrator) &&
                                <Route exact={true} path={routes.profileManageDealers} component={DealersView} />
                            }
                            {
                                user.hasRoles(roles.SellerAdministrator, roles.SellerTrader, roles.SellerViewer) &&
                                <Route path={routes.profileManagePlatformUsers} component={UsersView} />
                            }
                            {
                                user.hasRoles(roles.SellerAdministrator, roles.SellerTrader, roles.SellerViewer, roles.BrokerDealerTrader, roles.BrokerDealerViewer) &&
                                <Route exact={true} path={routes.profileTradingLimits} component={TradingLimitsView} />
                            }
                            <Redirect to={routes.notFound} />
                        </Switch>
                    </div>
                </div>
            </Preloader>
        </div>
    );
};

export { Profile };
