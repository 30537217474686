import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { manageEmailPreferencesActions } from '../../../../../actions/manage-email-preferences.actions';
import { useAppSelector } from '../../../../../effects/useAppSelector';
import { AlertOption } from '../../../../../types/email-preferences/EmailPreferences';
import { isRequesting } from '../../../../../utils';
import { RouteLeavingGuard } from '../../../../routing';
import { EmailPreferencesRadioGroup } from './EmailPreferencesRadioGroup';
import { SaveButton } from './SaveButton';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';

export function DashboardEmailTab() {
    const dispatch = useDispatch();
    const history = useHistory();
    const dailyDashboardEmail = useAppSelector(s => s.manageEmailPreferences.editPreferences.dailyDashboardEmail);
    const initialPreferences = useAppSelector(s => s.manageEmailPreferences.initialPreferences.dailyDashboardEmail);
    const isRequestingSavePreferences = useAppSelector(s => isRequesting(s.manageEmailPreferences.requestStateSaveEditPreferences));

    const hasChanges = !isEqual(dailyDashboardEmail, initialPreferences);

    const save = () => dispatch(manageEmailPreferencesActions.saveEmailPreferencesRequest());


    return (
        <>
            <div className="flex-row justify-content-end save-changes">
                <SaveButton disabled={!hasChanges || isRequestingSavePreferences} onClick={save}/>
            </div>
            <EmailPreferencesRadioGroup
                value={dailyDashboardEmail}
                disabled={isRequestingSavePreferences}
                alertOptions={[AlertOption.Never, AlertOption.Daily]}
                alertOptionInfo={{[AlertOption.Daily]: 'Alerts are sent at 5 PM EST every business day excluding holidays'}}
                groupNameIndentifier="daily-dashboard"
                title="Upcoming BWICs & Trade Analytics"
                description="Receive a daily email with a dashboard of Upcoming BWICs by Rating, BWIC Daily Stats and Scheduled BWIC Heatmap."
                requiredFeature={SubscriptionFeature.CanUseDashboardAlerts}
                onChange={alertOption => dispatch(manageEmailPreferencesActions.setCheckedDailyDashboardEmail(alertOption))}
            />
            <RouteLeavingGuard
                navigate={(pathname: string) => {
                    history.push(pathname);
                    dispatch(manageEmailPreferencesActions.rollbackDashboardEmails())
                }}
                shouldBlockNavigation={() => hasChanges}
            />
        </>
    )
}
