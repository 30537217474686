import moment from 'moment';
import { Link } from 'react-router-dom';
import { routes } from '../../../../../constants';
import { user } from '../../../../../user';
import { SubscriptionsTab } from '../SubscriptionsTab';
import { ContentSection } from '../ContentSection';
import { calculateSubscriptionTimeline, getSubscriptionTitle } from '../../../../../utils/subscription.utils';

const dateFormat = 'MMMM D, YYYY';

export function Overview() {
    const currentUser = user.current()!;
    const { isTrial } = calculateSubscriptionTimeline();

    const renderSubscriptionInfo = () => {
        if (isTrial) {
            return (<h1>{`Your FREE trial is enabled until ${moment(currentUser.trialPeriodEnd).format('MM/DD/YY')}`}</h1>)
        }
        return (
            <>
                <h1>{`Your Plan is ${getSubscriptionTitle(currentUser.subscription)}`}</h1>
                {
                    !!currentUser.subscriptionExpiration &&
                    <p>Subscription End: {moment(currentUser.subscriptionExpiration).format(dateFormat)}</p>
                }
            </>
        )
    }

    return (
        <ContentSection>
            <div className="subscription-overview">
                <div className="subscription-overview-row">
                    <div className="subscription-overview-section display-inline">
                        {renderSubscriptionInfo()}
                        <Link className="btn btn-main" to={routes.subscriptionsTab(SubscriptionsTab.plans)}>Switch plan</Link>
                    </div>
                </div>
            </div>
        </ContentSection>
    );
}
