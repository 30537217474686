import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { manageEmailPreferencesActions } from "../../../../../../actions/manage-email-preferences.actions";
import { routes } from '../../../../../../constants/routes';
import { isRequesting, isRequestNone, isRequestSuccess } from "../../../../../../utils/request-state.utils";
import { Preloader } from "../../../../../common/Preloader";
import { Popup, PopupBody, PopupFooter } from "../../../../../controls"
import { BwicMonitorAlertsTable } from "./BwicMonitorAlertsTable";
import { SavedFiltersAlerts } from "../../SavedFiltersAlerts";
import { useAppSelector } from "../../../../../../effects/useAppSelector";
import { usePageConfig } from "../../../../../common/pageConfig/usePageConfig";
import { PageConfigType } from "../../../../../../types/page-config/PageConfigType";
import { BwicFilterType } from "../../../../../../types/filters/FilterState";
import { BwicMonitorAlertsSaveButton } from "./BwicMonitorAlertsSaveButton";
import { EmailPreferencesTab } from "../../EmailPreferencesContent";
import { UserConfigFilter } from "../../../../../../types/user-config/UserConfigFilter";
import { SubscriptionFeature } from "../../../../../../types/billing/SubscriptionFeature";
import { textLabels } from "../../../../../../constants/text-labels";

interface BwicMonitorAlertsPopupProps {
    onClose: () => void
}

export const BwicMonitorAlertsPopup = ({ onClose }: BwicMonitorAlertsPopupProps) => {
    const dispatch = useDispatch();

    const requestStateGetPreferences = useAppSelector(s => s.manageEmailPreferences.requestStateGetPreferences);
    const requestStateSaveBwicByRating = useAppSelector(s => s.manageEmailPreferences.requestStateSaveBwicByRating);
    const filter = useAppSelector(s => s.filters.bwicMonitor.filter);
    const visibleFilters = useAppSelector(s => s.filters.bwicMonitor.visibleFilters);

    const { config, requestState, updateRequestState, saveAlerts } = usePageConfig(
        PageConfigType.BwicMonitorAll,
        false,
        BwicFilterType.BwicMonitor
    );

    const isLoading = isRequesting(
        requestStateGetPreferences,
        requestStateSaveBwicByRating,
        requestState,
        updateRequestState
    );

    useEffect(() => {
        if (isRequestSuccess(requestStateSaveBwicByRating)) {
            onClose();
        } // eslint-disable-next-line
    }, [requestStateSaveBwicByRating])

    useEffect(() => () => {
        dispatch(manageEmailPreferencesActions.reset())
    }, [dispatch])

    useEffect(() => {
        if (isRequestNone(requestStateGetPreferences)) {
            dispatch(manageEmailPreferencesActions.getInitialDataRequest());
        }
    }, [dispatch, requestStateGetPreferences]);

    const handleSave = (updatedFilters?: UserConfigFilter[]) => {
        dispatch(manageEmailPreferencesActions.saveBwicByRatingPreferencesRequest());
        if (updatedFilters?.length) {
            saveAlerts(updatedFilters);
        }
    }

    if(isRequestSuccess(requestStateSaveBwicByRating)) return null;

    return (
        <Popup
            renderInBody={true}
            title="BWIC Monitor Alerts"
            modalClass="modal-email-preferences email-preferences-fixed-height"
            onClose={onClose}
        >
            <PopupBody className="notifications-center">
                <Preloader inProgress={isLoading}>
                    <BwicMonitorAlertsTable collapsible={true} />
                    <div className="flex-row flex-direction-column flex-row-full-size">
                        <SavedFiltersAlerts
                            filter={filter}
                            filterAlertsStatuses={config?.filters}
                            visibleFilters={visibleFilters}
                            filterType={BwicFilterType.BwicMonitor}
                            pageConfigType={PageConfigType.BwicMonitorAll}
                            requiredFeature={SubscriptionFeature.BwicMonitorAlerts}
                            description={textLabels.savedFiltersBwicMonitor}
                            collapsible={true}
                            isStatusVisibleInTitle={true}
                        />
                    </div>
                </Preloader>
            </PopupBody>
            <PopupFooter>
                <Link to={routes.profileNotificationsTab(EmailPreferencesTab.BwicMonitor)} className="btn btn-link">
                    Manage Alerts
                </Link>
                <div className="flex-item-right flex-row">
                    <button className="btn btn-ghost" onClick={onClose}>
                        Cancel
                    </button>
                    <BwicMonitorAlertsSaveButton
                        currentFilters={config?.filters}
                        disabled={isLoading}
                        onSave={handleSave}
                    />
                </div>
            </PopupFooter>
        </Popup>
    )
}
