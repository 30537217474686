import React from 'react';

interface ContentSectionProps {
    children: React.ReactNode
}

export function ContentSection({ children }: ContentSectionProps) {

    return(
        <div className="content-section container-flex position-relative">
            {children}
        </div>
    )
}
