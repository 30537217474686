import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { manageEmailPreferencesActions } from '../../../../actions/manage-email-preferences.actions';
import { roles, routes } from '../../../../constants';
import { useAppSelector } from '../../../../effects/useAppSelector';
import { user } from '../../../../user';
import { isRequesting, isRequestNone } from '../../../../utils';
import { TabList } from '../../../bidding/common/tab-list';
import { Preloader } from '../../../common';
import { MyBwicsTab } from './tabs/MyBwicsTab';
import { DashboardEmailTab } from './tabs/DashboardEmailTab';
import { BwicMonitorAlertsTab } from './tabs/bwic-monitor/BwicMonitorAlertsTab';
import { IssuanceMonitorTab } from './tabs/IssuanceMonitorTab';
import { PortfolioTab } from './tabs/portfolio/PortfolioTab';
import { EmailPreferencesTabItem } from './tabs/EmailPreferencesTabItem';
import { CloManagerTab } from './tabs/clo-managers/CloManagerTab';
import { usePageConfig } from '../../../common/pageConfig/usePageConfig';
import { DealerInventoryAlertsTab } from './tabs/dealer-inventory/DealerInventoryAlertsTab';
import { BanksTab } from './tabs/banks/BanksTab';
import { AdaptiveHorizontalSlider } from '../../../common/AdaptiveHorizontalSlider';
import { ArrangerPipelineTab } from './tabs/ArrangerPipelineTab';

export enum EmailPreferencesTab {
    Dashboard = 'dashboard',
    BwicMonitor = 'bwicMonitor',
    MyBwics = 'myBwics',
    IssuanceMonitor = 'issuanceMonitor',
    DealerInventory = 'dealerInventory',
    CloManagers = 'cloManagers',
    Portfolio = 'portfolios',
    Banks = 'banks',
    ArrangerPipeline = 'arrangerPipeline',
}

const Tabs = [
    {
        title: 'Dashboard',
        type: EmailPreferencesTab.Dashboard,
        component: DashboardEmailTab,
    },
    {
        title: 'BWIC Monitor',
        type: EmailPreferencesTab.BwicMonitor,
        component: BwicMonitorAlertsTab,
    },
    {
        title: 'My BWICs',
        type: EmailPreferencesTab.MyBwics,
        component: MyBwicsTab,
    },
    {
        title: 'Issuance Monitor',
        type: EmailPreferencesTab.IssuanceMonitor,
        component: IssuanceMonitorTab,
    },
    {
        title: 'Arranger Pipeline',
        type: EmailPreferencesTab.ArrangerPipeline,
        component: ArrangerPipelineTab,
    },
    {
        title: 'Inventory',
        type: EmailPreferencesTab.DealerInventory,
        component: DealerInventoryAlertsTab,
    },
    {
        title: 'Portfolio',
        type: EmailPreferencesTab.Portfolio,
        component: PortfolioTab,
    },
    {
        title: 'CLO Managers',
        type: EmailPreferencesTab.CloManagers,
        component: CloManagerTab,
    },
    {
        title: 'Banks',
        type: EmailPreferencesTab.Banks,
        component: BanksTab,
    },
];

export const EmailPreferencesContent = () => {
    const dispatch = useDispatch();
    const { tab } = useParams<{ tab: string }>();
    const history = useHistory();

    const requestStateGetPreferences = useAppSelector(s => s.manageEmailPreferences.requestStateGetPreferences);
    const hasPortfolio = useAppSelector(s => s.manageEmailPreferences.editPreferences.hasPortfolio);
    const availableTabs = Tabs.filter(({ type }) => {
        if (
            type === EmailPreferencesTab.IssuanceMonitor ||
            type === EmailPreferencesTab.ArrangerPipeline
        ) {
            return user.hasRoles(...roles.issuanceMonitorAccess());
        }

        if (type === EmailPreferencesTab.Portfolio) {
            return hasPortfolio && !user.hasSingleRole(roles.SellerAdministrator);
        }

        if (type === EmailPreferencesTab.CloManagers) {
            return user.hasRoles(...roles.cloManagersAccess());
        }

        if (type === EmailPreferencesTab.DealerInventory) {
            return user.hasRoles(...roles.seller(), roles.Administrator, roles.Viewer);
        }

        if (type === EmailPreferencesTab.Banks) {
            return user.hasRoles(...roles.banksAccess());
        }

        return true;
    });
    const selectedTabIndex = availableTabs.findIndex(t => t.type === tab);

    usePageConfig();

    const withIssuanceMonitorAccess = user.hasRoles(...roles.issuanceMonitorAccess());

    useEffect(() => () => {
        dispatch(manageEmailPreferencesActions.reset())
    }, [dispatch]);

    useEffect(() => {
        if (isRequestNone(requestStateGetPreferences)) {
            dispatch(manageEmailPreferencesActions.getInitialDataRequest(withIssuanceMonitorAccess));
        }
    }, [dispatch, requestStateGetPreferences, withIssuanceMonitorAccess]);

    useEffect(() => {
        if (!tab) {
            history.replace(routes.profileNotificationsTab(EmailPreferencesTab.Dashboard))
        }
    }, [history, tab]);

    return (
        <Preloader inProgress={isRequestNone(requestStateGetPreferences) || isRequesting(requestStateGetPreferences)}>
            <TabList>
                <AdaptiveHorizontalSlider activeItemIndex={selectedTabIndex}>
                    {availableTabs.map(tab => (
                        <EmailPreferencesTabItem key={tab.type} tabPath={tab.type} title={tab.title} />
                    ))}
                </AdaptiveHorizontalSlider>
            </TabList>
            <div className="notifications-center-tab">
                <Switch>
                    {availableTabs.map(tab => (
                        <Route
                            key={tab.type}
                            exact
                            path={routes.profileNotificationsTab(tab.type)}
                            component={tab.component}
                        />
                    ))}
                </Switch>
            </div>
        </Preloader>
    );
}
