import { UserStatus } from '../../../../../types/account/UserStatus';
import { UserRequestStatus } from '../../../../../types/management/UserRequestStatus';
import { UserCreationRequest } from '../../../../../types/management/UserCreationRequest';
import { CompanyUser } from '../../../../../types/management/CompanyUser';
import { userRequestStatuses, userStatuses } from '../../../../../constants';

export interface UserListTableItem {
    id: number;

    userStatus?: UserStatus;
    userRequestStatus?: UserRequestStatus;
    statusText: string;

    fullName: string;
    email: string;
    jobTitle: string;
    phoneNumber: string | null;
    roles: string[];
    primaryContact: boolean;
    blockReason: string | null;
    registrationBy: string;
    registrationDate: Date;

    user?: CompanyUser;
    request?: UserCreationRequest;
}

export function convert(user: CompanyUser | UserCreationRequest): UserListTableItem {
    const isRequest = 'concurrencyStamp' in user;
    return {
        id: user.id,
        userStatus: isRequest ? undefined : user.status as UserStatus,
        userRequestStatus: isRequest ? user.status as UserRequestStatus : undefined,
        statusText: (isRequest
            ? userRequestStatuses.getByStatusCode(user.status as UserRequestStatus)?.title
            : userStatuses.getByStatusCode(user.status)?.title) ?? '',
        fullName: `${user.firstName} ${user.lastName}`,
        email: user.email,
        jobTitle: user.jobTitle,
        phoneNumber: user.phoneNumber,
        roles: user.roles,
        primaryContact: user.primaryContact,
        blockReason: user.blockReason,
        registrationBy: user.registrationBy,
        registrationDate: user.registrationDate,

        user: isRequest ? undefined : user as CompanyUser,
        request: isRequest ? user as UserCreationRequest : undefined
    };
}
