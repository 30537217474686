import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from "formik";
import { Popup } from '../../controls';
import { InputField } from '../../forms';
import { addNewTraderValidation } from '../validation/addNewTrader';
import { accountService } from "../../../services";
import { errorActions, profileActions } from "../../../actions";
import {errorMessages} from "../../../constants";

function ModalAddEditTrader({ visible, onClose, contact = {} }) {

    const dispatch = useDispatch();

    const initialValues = {
        id: contact.id || '',
        firstName: contact.firstName || '',
        lastName: contact.lastName || '',
        title: contact.title || '',
        email: contact.email || '',
        phone: contact.phone || '',
    };
    const [isPostingForm, setPostingForm] = useState(false);

    const isEdit = !!contact.id;

    const handleSubmit = async (values) => {
        try {
            setPostingForm(true);
            if (isEdit) {
                const traderId = await accountService.editCompanyTrader({ ...values }, values.id);
                dispatch(profileActions.editNewCompanyTraderInStore({ ...values }, traderId.id));
                handleOnClose()
            } else {
                const newTraderId = await accountService.addCompanyTrader({ ...values });
                const trader = { ...values };
                trader.id = newTraderId.id;
                trader.enabled = true;
                dispatch(profileActions.addNewCompanyTraderToStore(trader));
                handleOnClose()
            }
        } catch (e) {
            dispatch(errorActions.error(e, errorMessages.unexpectedError));
            setPostingForm(false);
        }
    };

    const handleOnClose = () => {
        setPostingForm(false);
        if (onClose && typeof onClose === 'function') {
            onClose()
        }
    };

    if (visible) {
        return (
            <Popup
                modalClass="edit-user-popup edit-trader-popup"
                title={isEdit ? 'Edit Trader' : 'Add Trader'}
                onClose={handleOnClose}
            >
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={addNewTraderValidation}
                    onSubmit={handleSubmit}
                >
                    <Form noValidate>
                        <div className="modal-body">
                            <div className="form-row">
                                <InputField
                                    label="First Name"
                                    markRequired
                                    name="firstName"
                                    placeholder="Enter first name"
                                />
                            </div>
                            <div className="form-row">
                                <InputField
                                    label="Last Name"
                                    markRequired
                                    name="lastName"
                                    placeholder="Enter last name"
                                />
                            </div>
                            <div className="form-row form-row-inline">
                                <div className="form-item">
                                    <InputField
                                        label="Title"
                                        name="title"
                                        placeholder="Enter title"
                                    />
                                </div>
                                <div className="form-item">
                                    <InputField
                                        label="Phone"
                                        markRequired
                                        type="tel"
                                        name="phone"
                                        placeholder="Enter phone"
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <InputField
                                    label="Email"
                                    markRequired={!isEdit}
                                    type="email"
                                    name="email"
                                    placeholder="Enter email"
                                    disabled={isEdit}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-ghost" type="button" onClick={handleOnClose}>CANCEL</button>
                            <button
                                disabled={isPostingForm}
                                className="btn btn-main"
                                type="submit"
                            >
                                {isEdit ? 'DONE' : '+ ADD TRADER'}
                            </button>
                        </div>
                    </Form>
                </Formik>
            </Popup>
        );
    }
    return null;
}

export { ModalAddEditTrader }

