import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ChangePhoneNumber } from '../../account';
import { profileActions as actions } from '../../../actions';
import { nameUtils } from '../../../utils';
import { UserProfilePhotoViewer } from '../../user-profile-photo/UserProfilePhotoViewer';
import { user } from '../../../user';
import IconSVG from '../../../styles/svg-icons';
import { Preloader, OnHoverTooltip } from '../../common';
import { ChangeBloombergEmail } from './ChangeBloombergEmail';

class PersonalInfo extends Component {
    componentWillUnmount = () => this.props.dispatch(actions.personalInfoReset());

    renderBloombergEmail = () => {
        const { dispatch, changeBloombergEmailVisible } = this.props;
        const currentUser = user.current() || {};
        if (changeBloombergEmailVisible) {
            return <ChangeBloombergEmail />;
        }
        return (
            <dl className="profile-list">
                <dt>Bloomberg Email</dt>
                <dd>
                    {
                        currentUser.bloombergEmail
                            ? (
                                <>
                                    <OnHoverTooltip overlay={currentUser.bloombergEmail}>
                                        <a href={`mailto:${currentUser.bloombergEmail}`} className="display-inline text-ellipsis">{currentUser.bloombergEmail}</a>
                                    </OnHoverTooltip>
                                    <button
                                        className="btn-link btn-edit"
                                        onClick={() => dispatch(actions.toggleEditBloombergEmail())}
                                    >
                                        <IconSVG name="edit-pencil" width={16} height={16} />
                                    </button>
                                </>
                            ) : (
                                <button className="btn-link"
                                        onClick={() => dispatch(actions.toggleEditBloombergEmail())}
                                >
                                    <IconSVG name="plus-round" width={16} height={16} />
                                    Add Bloomberg Email
                                </button>
                            )
                    }
                </dd>
            </dl>
        );
    };

    renderChangePhone = () => {
        const { dispatch, changePhoneVisible } = this.props;
        const profile = this.props.profile || {};

        if (changePhoneVisible) {
            return (
                <ChangePhoneNumber
                    phone={profile.phone}
                    onCancel={() => dispatch(actions.toggleEditPhoneMode(false))}
                />
            );
        }
        return (
            <dl className="profile-list">
                <dt>Phone Number</dt>
                <dd>
                    {
                        profile.phone
                            ? (
                                <>
                                    {profile.phone}
                                    <button
                                        className="btn-link btn-edit"
                                        onClick={() => dispatch(actions.toggleEditPhoneMode(true))}
                                    >
                                        <IconSVG name="edit-pencil" width={16} height={16} />
                                    </button>
                                </>
                            ) : (
                                <button className="btn-link"
                                        onClick={() => dispatch(actions.toggleEditPhoneMode(true))}>
                                    <IconSVG name="plus-round" width={16} height={16} />
                                    Add Phone Number
                                </button>
                            )
                    }
                </dd>
            </dl>
        );
    };

    render = () => {
        const { dispatch } = this.props;
        const profile = this.props.profile || {};
        const currentUser = user.current();

        return (
            <div className="tab-pane personal-info container-flex">
                <div className="sub-title">
                    <h1>Personal</h1>
                </div>
                <div className="content-section container-flex">
                    <div className="personal-info-content align-items-flex-start flex-row">
                        <div className="personal-info-avatar">
                            <Preloader inProgress={this.props.isUserProfilePhotoUploading}>
                                <div className="user-avatar-section">
                                    <UserProfilePhotoViewer
                                        userId={currentUser.id}
                                        alt={nameUtils.getInitials(profile.firstName, profile.lastName)}
                                    />
                                    <button
                                        className="btn btn-main"
                                        onClick={() => dispatch(actions.setVisibleUploadImagePopup(true))}
                                    >
                                        <IconSVG name="photo" width={16} height={16} />
                                    </button>
                                </div>
                            </Preloader>
                        </div>
                        <div className="personal-info-list">
                            <dl className="profile-list">
                                <dt>First Name</dt>
                                <dd>{profile.firstName}</dd>
                                <dt>Last Name</dt>
                                <dd>{profile.lastName}</dd>
                                <dt>Email</dt>
                                <dd><a href={`mailto:${profile.email}`}>{profile.email}</a></dd>
                            </dl>
                            {this.renderBloombergEmail()}
                            {this.renderChangePhone()}
                            {profile.linkedIn &&
                                <dl className="profile-list">
                                    <dt>LinkedIn</dt>
                                    <dd className="linkedin-link text-ellipsis">
                                        <OnHoverTooltip overlay={profile.linkedIn} overlayClassName="linkedin-link-tooltip">
                                            <a href={profile.linkedIn}>{profile.linkedIn}</a>
                                        </OnHoverTooltip>
                                    </dd>
                                </dl>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

const mapStateToProps = ({ profile, userProfilePhoto }) => ({
    profile: profile.profile,
    userPhotos: userProfilePhoto.userPhotos,
    changePhoneVisible: profile.changePhoneVisible,
    changeBloombergEmailVisible: profile.changeBloombergEmailVisible,
    isUserProfilePhotoUploading: profile.isUserProfilePhotoUploading,
});

const connectedPersonalInfo = connect(mapStateToProps)(PersonalInfo);
export { connectedPersonalInfo as PersonalInfo };
