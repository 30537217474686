import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { manageEmailPreferencesActions } from '../../../../../actions/manage-email-preferences.actions';
import { useAppSelector } from '../../../../../effects/useAppSelector';
import { AlertOption } from '../../../../../types/email-preferences/EmailPreferences';
import { isRequesting } from '../../../../../utils';
import { RouteLeavingGuard } from '../../../../routing';
import { EmailPreferencesRadioGroup } from './EmailPreferencesRadioGroup';
import { SaveButton } from './SaveButton';
import { UnsubscribeAllButton } from './UnsubscribeAllButton';
import { emailPreferencesUtils } from '../../../../../utils/email-preferences.utils';

export function MyBwicsTab() {
    const dispatch = useDispatch();
    const history = useHistory();

    const isRequestingSavePreferences = useAppSelector(s => isRequesting(s.manageEmailPreferences.requestStateSaveEditPreferences));
    const newBwicAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.newBwicAlertState);
    const bwicReminderAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.bwicReminderAlertState);
    const initialPreferences = useAppSelector(s => s.manageEmailPreferences.initialPreferences);

    const handleNewBwicNotificationsChange = (alertOption: AlertOption) => {
        const status = emailPreferencesUtils.convertAlertOptionToAlertEnabledState(alertOption);
        dispatch(manageEmailPreferencesActions.setNewBiwicAlerts(status));
    };

    const handleBwicReminderNotificationsChange = (alertOption: AlertOption) => {
        const status = emailPreferencesUtils.convertAlertOptionToAlertEnabledState(alertOption);
        dispatch(manageEmailPreferencesActions.setBiwicReminderAlerts(status));
    };

    const hasChanges = !(isEqual(initialPreferences.newBwicAlertState, newBwicAlertState) && isEqual(initialPreferences.bwicReminderAlertState, bwicReminderAlertState));
    const isUnsubscribeAllVisible =
        emailPreferencesUtils.convertAlertEnabledStateToAlertOption(newBwicAlertState) === AlertOption.Instant &&
        emailPreferencesUtils.convertAlertEnabledStateToAlertOption(bwicReminderAlertState) === AlertOption.Instant;

    return (
        <>
            <div className="flex-row justify-content-end save-changes">
                <UnsubscribeAllButton
                    onClick={() => dispatch(manageEmailPreferencesActions.unsubscribeAllBWICsNotifications())}
                    disabled={isRequestingSavePreferences || !isUnsubscribeAllVisible}
                />
                <SaveButton
                    disabled={!hasChanges || isRequestingSavePreferences}
                    onClick={() => dispatch(manageEmailPreferencesActions.saveEmailPreferencesRequest())}
                />
            </div>
            <EmailPreferencesRadioGroup
                value={emailPreferencesUtils.convertAlertEnabledStateToAlertOption(newBwicAlertState)}
                disabled={isRequestingSavePreferences}
                alertOptions={[AlertOption.Never, AlertOption.Instant]}
                alertOptionInfo={{
                    [AlertOption.Instant]: 'Alerts are sent once the BWIC is posted/canceled/finished on the KTX ATS Platform'
                }}
                groupNameIndentifier="new-bwic-notifications-state"
                title="New BWIC"
                description="Receive New BWIC/BWIC Cancelation/BWIC Finished email notifications if the 'Instant' option is selected."
                onChange={handleNewBwicNotificationsChange}
            />
            <EmailPreferencesRadioGroup
                value={emailPreferencesUtils.convertAlertEnabledStateToAlertOption(bwicReminderAlertState)}
                disabled={isRequestingSavePreferences}
                alertOptions={[AlertOption.Never, AlertOption.Instant]}
                alertOptionInfo={{
                    [AlertOption.Instant]: 'Alerts are sent 1 day before/at the beginning of the day/1 hour before the start of the BWIC'
                }}
                groupNameIndentifier="bwic-reminder-notification-state"
                title="BWIC Reminder"
                description="Receive reminder notifications before the start of BWIC."
                onChange={handleBwicReminderNotificationsChange}
            />
            <RouteLeavingGuard
                navigate={(pathname: string) => {
                    dispatch(manageEmailPreferencesActions.rollbackBWICNotifications())
                    history.push(pathname);
                }}
                shouldBlockNavigation={() => hasChanges}
            />
        </>
    )
}
