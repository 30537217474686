import { useAppSelector } from "../../../../../../effects/useAppSelector";
import { BwicFilterType } from "../../../../../../types/filters/FilterState";
import { PageConfigType } from "../../../../../../types/page-config/PageConfigType";
import { usePageConfig } from "../../../../../common/pageConfig/usePageConfig";

export function useInventoryAlertsChangedState() {
    const { config  } = usePageConfig(PageConfigType.DealerInventory, true, BwicFilterType.Inventory);
    const savedFiltersEditState = useAppSelector(s => s.manageEmailPreferences.editSavedFiltersPreferences);
    const initialPreferences = useAppSelector(s => s.manageEmailPreferences.initialPreferences);
    const editPreferences = useAppSelector(s => s.manageEmailPreferences.editPreferences);

    const isFilterAlertsChanged = config?.filters?.some(f =>
        savedFiltersEditState[f.referenceName] != null &&
        savedFiltersEditState[f.referenceName] !== f.alertOption
    );

    const dealerInventoryAlertStateChanged = initialPreferences.dealerInventoryAlertState !== editPreferences.dealerInventoryAlertState;

    return { isFilterAlertsChanged, dealerInventoryAlertStateChanged };
}