import React from 'react';
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { Popup } from '../../controls';
import { InputField } from '../../forms';
import { addNewTraderValidation } from '../validation/addNewTrader';
import { profileActions } from "../../../actions";

function ModalAddEditDepartment({ visible, onClose, contact = {}, isEditModal }) {

    const dispatch = useDispatch();

    const initialValues = {
        jobTitle: contact.title || '',
        departmentName: contact.departmentName || '',
        departmentValue: contact.departmentValue || '',
        isAdded: contact.isAdded || '',
        id: contact.id || '',
        firstName: contact.firstName || '',
        lastName: contact.lastName || '',
        email: contact.email || '',
        phone: contact.phone || '',
    };

    const handleSubmit = async (values) => {
        const submitModel = {
            firstName: values.firstName,
            lastName: values.lastName,
            jobTitle: values.jobTitle,
            phone: values.phone,
            email: values.email,
        };
        dispatch(profileActions.editCompanyContact(submitModel, values.departmentValue));
        handleOnClose();
    };

    const handleOnClose = () => {
        if (onClose && typeof onClose === 'function') {
            onClose()
        }
    };

    if (visible) {
        return (
            <Popup
                modalClass="edit-user-popup"
                title={isEditModal ? 'Edit Contact' : 'Add Contact'}
                onClose={handleOnClose}
            >
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validateOnBlur
                    validationSchema={addNewTraderValidation}
                    onSubmit={handleSubmit}
                >
                    <Form noValidate>
                        <div className="modal-body">
                            <div className="form-row">
                                <InputField
                                    label="First Name"
                                    markRequired
                                    name="firstName"
                                    placeholder="Enter first name"
                                />
                            </div>
                            <div className="form-row">
                                <InputField
                                    label="Last Name"
                                    markRequired
                                    name="lastName"
                                    placeholder="Enter last name"
                                />
                            </div>
                            <div className="form-row form-row-inline">
                                <div className="form-item">
                                    <InputField
                                        label="Title"
                                        maxLength="256"
                                        name="jobTitle"
                                        placeholder="Enter title"
                                    />
                                </div>
                                <div className="form-item">
                                    <InputField
                                        label="Phone"
                                        markRequired
                                        type="tel"
                                        name="phone"
                                        placeholder="Enter phone"
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <InputField
                                    label="Email"
                                    markRequired
                                    type="email"
                                    name="email"
                                    placeholder="Enter email"
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-ghost" type="button" onClick={handleOnClose}>CANCEL</button>
                            <button className="btn btn-main" type="submit">
                                {isEditModal ? 'DONE' : 'ADD'}
                            </button>
                        </div>
                    </Form>
                </Formik>
            </Popup>
        );
    }
    return null;
}

export { ModalAddEditDepartment }
