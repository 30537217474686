import { Link } from "react-router-dom"
import { routes } from "../../../constants/routes"
import { roles } from "../../../constants/roles"
import { ShowFor } from "../../access/ShowFor"
import { ProfileMenuSection } from "./ProfileMenuSection"
import { EmailPreferencesTab } from "../tabs/email-preferences/EmailPreferencesContent"
import { user } from "../../../user/user"
import { useAgreement } from "../../seller-buyside/useAgreement"
import { SettlementAgreementStatus } from "../../../types/bid-as-dealer/SettlementAgreementStatus"

interface ProfileMenuItemsProps {
    onItemClick: () => void
}

export const ProfileMenuItems = ({ onItemClick }: ProfileMenuItemsProps) => {
    const agreementState = useAgreement();

    const isBd = user.hasRoles(...roles.bd());
    const isSellerAdmin = user.hasRoles(roles.SellerAdministrator);

    return (
        <>
            <ProfileMenuSection title="Account Settings" icon="settings">
                <Link
                    className="navbar-dropdown-menu-item"
                    to={routes.profile}
                    onClick={onItemClick}
                >
                    Personal
                </Link>
                <Link
                    className="navbar-dropdown-menu-item"
                    to={routes.profilePasswordSettings}
                    onClick={onItemClick}
                >
                    Password
                </Link>
                <ShowFor roles={roles.getAllRolesExclude(roles.ArrangersClient)}>
                    <Link
                        className="navbar-dropdown-menu-item"
                        to={routes.profileNotificationsTab(EmailPreferencesTab.Dashboard)}
                        onClick={onItemClick}
                    >
                        Alerts Center
                    </Link>
                </ShowFor>
            </ProfileMenuSection>

            <ProfileMenuSection title="Company" icon="company">
                <Link
                    className="navbar-dropdown-menu-item"
                    to={routes.profileCompanyInformation}
                    onClick={onItemClick}
                >
                    Information
                </Link>
                {!isSellerAdmin &&
                    <>
                        <ShowFor roles={[...roles.bd(), ...roles.admin(), roles.DataEntry, roles.Viewer]}>
                            <Link
                                className="navbar-dropdown-menu-item"
                                to={routes.profileTraders}
                                onClick={onItemClick}
                            >
                                Platform Users
                            </Link>
                        </ShowFor>
                        <ShowFor roles={[...roles.seller()]}>
                            <Link
                                className="navbar-dropdown-menu-item"
                                to={routes.profileManagePlatformUsers}
                                onClick={onItemClick}
                            >
                                Platform Users
                            </Link>
                        </ShowFor>
                        <ShowFor roles={[...roles.seller(), ...roles.bd()]}>
                            {
                                !user.current()?.isClearingBank &&
                                (!isBd || agreementState.agreement?.agreementStatus === SettlementAgreementStatus.confirmed) &&
                                <Link
                                    className="navbar-dropdown-menu-item"
                                    to={routes.profileTradingLimitsTab()}
                                    onClick={onItemClick}
                                >
                                    Trading Limits
                                </Link>
                            }
                        </ShowFor>
                    </>
                }
                <ShowFor roles={[...roles.subscriptionChange(), roles.Administrator]}>
                    <Link
                        className="navbar-dropdown-menu-item"
                        to={routes.subscriptionsTab()}
                        onClick={onItemClick}
                    >
                        Subscription
                    </Link>
                </ShowFor>
            </ProfileMenuSection>

            <ShowFor role={roles.SellerAdministrator}>
                <ProfileMenuSection title="Manage" icon="manage">
                    <Link
                        className="navbar-dropdown-menu-item"
                        to={routes.profileManagePlatformUsers}
                        onClick={onItemClick}
                    >
                        Platform Users
                    </Link>
                    <Link
                        className="navbar-dropdown-menu-item"
                        to={routes.profileTradingLimitsTab()}
                        onClick={onItemClick}
                    >
                        Trading Limits
                    </Link>
                    <Link
                        className="navbar-dropdown-menu-item"
                        to={routes.profileManageDealers}
                        onClick={onItemClick}
                    >
                        Counterparties
                    </Link>
                </ProfileMenuSection>
            </ShowFor>
        </>
    )
}