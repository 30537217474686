import { Switch, Route, Redirect } from 'react-router';
import { routes } from '../../../../constants';
import { SubscriptionsTab } from './SubscriptionsTab';
import { Overview } from './overview/Overview';
import { Plans } from './plans/Plans';
import { TabList, TabItem } from '../../../bidding/common/tab-list';
import { useBackspaceRedirectBlocker } from '../../../../effects';
import { useAppSelector } from '../../../../effects/useAppSelector';
import { isRequestSuccess } from '../../../../utils';

interface Props {
    activeTab?: SubscriptionsTab
}

export function SubscriptionsView({ activeTab = SubscriptionsTab.overview }: Props) {
    useBackspaceRedirectBlocker();

    const initStatus = useAppSelector(s => s.profile.initStatus);
    const profile = useAppSelector(s => s.profile.profile);
    //const upgradeSubscriptionWizard = useAppSelector(s => s.subscriptions.upgradeSubscriptionWizard);

    if (!isRequestSuccess(initStatus) || !profile) {
        return null;
    }

    return (
        <div className="tab-pane subscriptions container-flex">
            <div className="sub-title">
                <h1>Subscription</h1>
            </div>
            <TabList>
                <TabItem active={activeTab === SubscriptionsTab.overview} title="Overview" path={routes.subscriptionsTab()} />
                <TabItem active={activeTab === SubscriptionsTab.plans} title="Plans" path={routes.subscriptionsTab(SubscriptionsTab.plans)} />
            </TabList>
            <Switch>
                <Route exact={true} path={routes.subscriptionsTab()} component={Overview} />
                <Route exact={true} path={routes.subscriptionsTab(SubscriptionsTab.overview)} component={Overview} />
                <Route path={routes.subscriptionsTab(SubscriptionsTab.plans)} component={Plans} />
                {/* <Route path={routes.subscriptionsTab(SubscriptionsTab.paymentMethod)} component={PaymentMethod} />
                <Route path={routes.subscriptionsTab(SubscriptionsTab.billingInfo)} component={BillingInfo} />
                <Route path={routes.subscriptionsTab(SubscriptionsTab.billingHistory)} component={BillingHistoryTab} /> */}
                <Redirect to={routes.notFound} />
            </Switch>
            {/* upgradeSubscriptionWizard.isUpgradeSubscriptionPopupVisible &&
            <UpgradeSubscriptionPopup /> */}
        </div>
    );
}
