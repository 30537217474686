import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { manageEmailPreferencesActions } from "../../../../../actions/manage-email-preferences.actions";
import { useAppSelector } from "../../../../../effects/useAppSelector";
import { AlertOption } from "../../../../../types/email-preferences/EmailPreferences";
import { isRequesting } from "../../../../../utils";
import { RouteLeavingGuard } from "../../../../routing";
import { SaveButton } from "./SaveButton";
import { ApEmailAlerts } from "../../../../amrPipeline/aggregated/email-alerts/arranger-pipeline/ApEmailAlerts";

export function ArrangerPipelineTab() {
    const dispatch = useDispatch();
    const history = useHistory();

    const arrangerPipelineState = useAppSelector(
        (s) => s.manageEmailPreferences.editArrangerPipelinePreferences
    );
    const initialArrangerPipelinePreferences = useAppSelector(
        (s) => s.manageEmailPreferences.initialArrangerPipelinePreferences
    );
    const isRequestingSavePreferences = useAppSelector((s) =>
        isRequesting(
            s.manageEmailPreferences.requestStateSaveIssuanceMonitorPreferences
        )
    );

    const initialApAlertOption =
        initialArrangerPipelinePreferences?.apAlertConfig.value.alertOption ||
        AlertOption.Never;
    const apAlertOption =
        arrangerPipelineState?.apAlertConfig.value.alertOption ||
        AlertOption.Never;

    const hasChanges = initialApAlertOption !== apAlertOption;

    const save = () =>
        dispatch(manageEmailPreferencesActions.saveArrangerPipelineRequest());

    const handleChangeApAlertOption = (option: AlertOption) =>
        dispatch(
            manageEmailPreferencesActions.setArrangerPipelineAlert(option)
        );

    return (
        <>
            <div className="flex-row justify-content-end save-changes">
                <SaveButton
                    disabled={!hasChanges || isRequestingSavePreferences}
                    onClick={save}
                />
            </div>
            <ApEmailAlerts
                disabled={isRequestingSavePreferences}
                upcomingAlert={apAlertOption}
                onChangeUpcomingAlert={handleChangeApAlertOption}
            />
            <RouteLeavingGuard
                navigate={(pathname: string) => {
                    dispatch(
                        manageEmailPreferencesActions.rollbackArrangerPipeline()
                    );
                    history.push(pathname);
                }}
                shouldBlockNavigation={() => hasChanges}
            />
        </>
    );
}
