import React from 'react';
import { useAppSelector } from '../../../../../effects/useAppSelector';
import { Currency } from '../../../../../types/enums/Currency';
import { Rating } from '../../../../../types/enums/Rating';
import { Checkbox } from '../../../../controls';
import { useDispatch } from 'react-redux';
import { manageEmailPreferencesActions } from '../../../../../actions/manage-email-preferences.actions';
import { emailPreferencesUtils } from '../../../../../utils/email-preferences.utils';
import { ActionBlocker } from '../../../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';

interface Props {
    currency: Currency;
    rating: Rating;
    disabled: boolean;
}

export const EmailPreferencesTableRating: React.FC<Props> = ({ rating, currency, disabled }) => {
    const dispatch = useDispatch();
    const checked = useAppSelector(s =>
        !!s.manageEmailPreferences.editPreferences.ratingPreference.find(
            r => r.rating === rating && emailPreferencesUtils.isCurrencyActive(r, currency)
        )
    );

    return (
        <ActionBlocker feature={SubscriptionFeature.BwicMonitorAlerts}>
            {blocked =>
                <Checkbox
                    disabled={disabled || blocked}
                    onChange={e =>
                        dispatch(manageEmailPreferencesActions.setRatingCheckedState(rating, currency, e.target.checked))
                    }
                    label={rating}
                    checked={checked && !blocked}
                />}
        </ActionBlocker>
    )
};
