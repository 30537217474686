import { SettlementAgentDailyTraidingLimit } from "../../../common/settlment-agent-popup/SettlementAgentDailyTraidingLimit";
import { SettlementAgentTabContent } from "./SettlementAgentTabContent";

export function SettlementAgentLimits() {
    return (
        <SettlementAgentTabContent>
            {agreement =>
                <>
                    <div className="description">
                        <div className="text-warm-grey">Sell/Buy limits and commissions set by the Settlement Agent</div>
                    </div>
                    <SettlementAgentDailyTraidingLimit
                        dailyTradingLimit={agreement.dailyTradingLimit}
                        commissions={agreement.commissions}
                    />
                </>
            }
        </SettlementAgentTabContent>
    )
}