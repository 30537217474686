import { textLabels } from "../../../../../../constants/text-labels";
import { SubscriptionFeature } from "../../../../../../types/billing/SubscriptionFeature";
import { AlertOption } from "../../../../../../types/email-preferences/EmailPreferences";
import { AlertEnabledState } from "../../../../../../types/enums/AlertEnabledState";
import { EmailPreferencesRadioGroup } from "../EmailPreferencesRadioGroup";

const instantAlertOptions = [AlertOption.Never, AlertOption.Instant];
const periodicAlertOption = [AlertOption.Never, AlertOption.Periodic];

type OnChangeHandler = (alertOption: AlertOption) => void;

interface Props {
    bwicAlert: AlertOption;
    bwicState?: AlertEnabledState;
    onChangeBwicAlert: OnChangeHandler;
    inventoryAlert: AlertOption;
    inventoryState?: AlertEnabledState;
    onChangeInventoryAlert: OnChangeHandler;
    imAlert: AlertOption;
    imState?: AlertEnabledState;
    onChangeImAlert: OnChangeHandler;
    outOfRiAlert: AlertOption;
    outOfRiState?: AlertEnabledState;
    onChangeOutOfRiAlert: OnChangeHandler;
    outOfNcAlert: AlertOption;
    outOfNcState?: AlertEnabledState;
    onChangeOutOfNcAlert: OnChangeHandler;
    rollerDeadlineAlert: AlertOption;
    rollerDeadlineState?: AlertEnabledState;
    onChangeRollerDeadlineAlert: OnChangeHandler;
}

export function PortfolioAlertsBlock({
    bwicAlert,
    bwicState,
    onChangeBwicAlert,
    inventoryAlert,
    inventoryState,
    onChangeInventoryAlert,
    imAlert,
    imState,
    onChangeImAlert,
    outOfRiAlert,
    outOfRiState,
    onChangeOutOfRiAlert,
    outOfNcAlert,
    outOfNcState,
    onChangeOutOfNcAlert,
    rollerDeadlineAlert,
    rollerDeadlineState,
    onChangeRollerDeadlineAlert,
}: Props) {
    return (
        <>
            <EmailPreferencesRadioGroup
                value={bwicAlert}
                alertOptions={instantAlertOptions}
                alertOptionInfo={{
                    [AlertOption.Instant]:
                        textLabels.savedFiltersPortfolioBwicInstantAlert,
                }}
                groupNameIndentifier="single-portfolio-bwicAlert-alert-state"
                title="BWIC Alerts"
                description={
                    textLabels.savedFiltersPortfolioBwicAlertDescription
                }
                onChange={onChangeBwicAlert}
                partially={bwicState === AlertEnabledState.PartiallyEnabled}
            />
            <EmailPreferencesRadioGroup
                value={inventoryAlert}
                alertOptions={instantAlertOptions}
                alertOptionInfo={{
                    [AlertOption.Instant]:
                        textLabels.saveFiltersPortfolioInventoryInstantsAlert,
                }}
                groupNameIndentifier="single-portfolio-inventoryAlert-alert-state"
                title="Inventory Alerts"
                description={
                    textLabels.savedFiltersPortfolioInventoryAlertDescription
                }
                onChange={onChangeInventoryAlert}
                partially={inventoryState === AlertEnabledState.PartiallyEnabled}
            />
            <EmailPreferencesRadioGroup
                value={imAlert}
                alertOptions={instantAlertOptions}
                alertOptionInfo={{
                    [AlertOption.Instant]:
                        textLabels.savedFiltersPortfolioIssuanceMonitorInstantAlert,
                }}
                groupNameIndentifier="single-portfolio-issuance-monitor-alert-state"
                title="Issuance Monitor"
                description={
                    textLabels.savedFiltersPortfolioIssuanceMonitorAlertDescription
                }
                onChange={onChangeImAlert}
                partially={imState === AlertEnabledState.PartiallyEnabled}
                requiredFeatures={[
                    SubscriptionFeature.IssuanceMonitorFullAccess,
                    SubscriptionFeature.PortfolioAlerts,
                ]}
            />
            <EmailPreferencesRadioGroup
                value={outOfRiAlert}
                alertOptions={periodicAlertOption}
                alertOptionInfo={{
                    [AlertOption.Periodic]:
                        textLabels.savedFiltersPortfolioOutOfRiPeriodicAlert,
                }}
                groupNameIndentifier="single-portfolio-out-of-ri-alert-state"
                title="Out of RI Period"
                description={
                    textLabels.savedFiltersPortfolioOutOfRiAlertDescription
                }
                onChange={onChangeOutOfRiAlert}
                partially={outOfRiState === AlertEnabledState.PartiallyEnabled}
                requiredFeatures={[
                    SubscriptionFeature.IssuanceMonitorFullAccess,
                    SubscriptionFeature.PortfolioAlerts,
                ]}
            />
            <EmailPreferencesRadioGroup
                value={outOfNcAlert}
                alertOptions={periodicAlertOption}
                alertOptionInfo={{
                    [AlertOption.Periodic]:
                        textLabels.savedFiltersPortfolioOutOfNcPeriodicAlert,
                }}
                groupNameIndentifier="single-portfolio-out-of-nc-alert-state"
                title="Out of NC Period"
                description={
                    textLabels.savedFiltersPortfolioOutOfNcAlertDescription
                }
                onChange={onChangeOutOfNcAlert}
                partially={outOfNcState === AlertEnabledState.PartiallyEnabled}
                requiredFeatures={[
                    SubscriptionFeature.IssuanceMonitorFullAccess,
                    SubscriptionFeature.PortfolioAlerts,
                ]}
            />
            <EmailPreferencesRadioGroup
                value={rollerDeadlineAlert}
                alertOptions={instantAlertOptions}
                alertOptionInfo={{
                    [AlertOption.Instant]:
                        textLabels.savedFiltersPortfolioRollerDeadlineDailyAlert,
                }}
                groupNameIndentifier="single-portfolio-roller-deadline-alert-state"
                title="Roller Deadline"
                description={
                    textLabels.savedFiltersPortfolioRollerDeadlineAlertDescription
                }
                onChange={onChangeRollerDeadlineAlert}
                partially={rollerDeadlineState === AlertEnabledState.PartiallyEnabled}
                requiredFeatures={[
                    SubscriptionFeature.IssuanceMonitorFullAccess,
                    SubscriptionFeature.PortfolioAlerts,
                ]}
            />
        </>
    );
}

