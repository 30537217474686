import React, { useCallback } from 'react';
import { useAppSelector } from '../../../../../effects/useAppSelector';
import { Currency } from '../../../../../types/enums/Currency';
import { Checkbox } from '../../../../controls';
import { useDispatch } from 'react-redux';
import { manageEmailPreferencesActions } from '../../../../../actions/manage-email-preferences.actions';
import { isRequesting } from '../../../../../utils';
import { AlertOption } from '../../../../../types/email-preferences/EmailPreferences';
import { ActionBlocker } from '../../../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';

interface Props {
    currency: Currency,
}

export const EmailPreferencesTableRatingTitle: React.FC<Props> = ({ currency }) => {
    const dispatch = useDispatch();

    const ratingPreference = useAppSelector(s => s.manageEmailPreferences.editPreferences.ratingPreference);
    const bwicByRatingAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.bwicByRatingAlertState);
    const isRequestStateSaving = useAppSelector(s => isRequesting(s.manageEmailPreferences.requestStateSaveEditPreferences));

    const isSelected = useCallback(
        () => !!ratingPreference.length && ratingPreference.every(r => r[`is${currency}`]),
        [ratingPreference, currency]
    );

    const isPartially = useCallback(
        () =>
            !!ratingPreference.length &&
            ratingPreference.some(r => r[`is${currency}`]) &&
            ratingPreference.some(r => !r[`is${currency}`]),
        [ratingPreference, currency]
    );

    return (
        <ActionBlocker feature={SubscriptionFeature.BwicMonitorAlerts}>
            {blocked =>
                <Checkbox
                    disabled={isRequestStateSaving || bwicByRatingAlertState === AlertOption.Never || blocked}
                    onChange={e => dispatch(manageEmailPreferencesActions.setRatingInCurrencyCheckedState(currency, e.target.checked))}
                    checked={!blocked && isSelected()}
                    partially={!blocked && isPartially()}
                    label={currency}
                />}
        </ActionBlocker>
    )
};
