import React from 'react';
import { UserRequestStatus } from '../../../../../types/management/UserRequestStatus';
import { UserCreationRequest } from '../../../../../types/management/UserCreationRequest';
import { CompanyUser } from '../../../../../types/management/CompanyUser';
import ContextMenu from '../../../../controls/ContextMenu';
import { UserStatus } from '../../../../../types/account/UserStatus';
import { isRequesting } from '../../../../../utils';
import { useHistory } from 'react-router';
import { routes } from '../../../../../constants';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../types/state/AppState';
import { UserListTableItem } from './UserListTableItem';
import { user as currentUser } from '../../../../../user/user';

interface Props {
    user: UserListTableItem;
    onBlockingUser: (user: UserListTableItem) => void;
    onUnblockingUser: (user: UserListTableItem) => void;
}

export const UserContextMenu: React.FC<Props> = ({ user, onBlockingUser, onUnblockingUser }) => {
    const history = useHistory();
    const userRequestOperation = useSelector((state: AppState) => state.sellerAdminUsers.userRequestOperationState[user.id]);
    const userOperation = useSelector((state: AppState) => state.sellerAdminUsers.userOperationState[user.id]);

    const getUserRequestMenuItems = (r: UserCreationRequest) => [{
        text: 'Edit',
        action: () => history.push(routes.profileManageEditUserRequestUrl(user.id))
    }, {
        text: r.status === UserRequestStatus.Blocked ? 'Activate' : 'Block',
        disabled: isRequesting(userRequestOperation),
        action: () => r.status === UserRequestStatus.Blocked
            ? onUnblockingUser(user)
            : onBlockingUser(user)
    }];

    const getUserMenuItems = (u: CompanyUser) => {
        if (u.id === currentUser.current()?.id) {
            return [];
        }
        return [{
            text: 'Edit',
            action: () => history.push(routes.profileManageEditUserUrl(user.id)),
        }, {
            text: u.status === UserStatus.Blocked ? 'Activate' : 'Block',
            disabled: isRequesting(userOperation),
            action: () => u.status === UserStatus.Blocked
                ? onUnblockingUser(user)
                : onBlockingUser(user)
        }]
    };

    if(user.id === currentUser.current()?.id) {
        return null;
    }

    return (
        <ContextMenu
            menuItems={user.request ? getUserRequestMenuItems(user.request!) : getUserMenuItems(user.user!)}
            bottomMargin={50}
        />
    )
};
