import React, { ChangeEvent } from 'react';
import cn from "classnames";
import { v4 as uuidv4 } from 'uuid';
import MaskedInput from "react-text-mask";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { constants } from '../../../../constants';

interface Props {
    label: string;
    placeholder: string;
    value: number | null;
    editMode: boolean;
    onChange: (value: number | null) => void;
}

export const CompanyLimitInput: React.FC<Props> = ({ value, editMode, label, placeholder, onChange }) => {
    const id = uuidv4();
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.replace(/,/g, '');
        if (newValue === ''
            || (
                newValue.match(constants.regOnlyNumbers) &&
                Number(newValue) >= constants.sellerAdminTradingLimits.min &&
                Number(newValue) <= constants.sellerAdminTradingLimits.max
            )
        ) {
            onChange(newValue === '' ? null : Number(newValue))
        } else {
            onChange(value)
        }
    };

    return (
        <div className={cn("form-row form-row-inline", {"editing": editMode})}>
            <div className="form-item">
                <label className="form-label" htmlFor={id}>{label}</label>
                {
                    editMode
                        ? (
                            <MaskedInput
                                className="form-control"
                                mask={createNumberMask({
                                    prefix: "",
                                    suffix: "",
                                    allowDecimal: false,
                                    includeThousandsSeparator: true,
                                })}
                                id={id}
                                onChange={handleChange}
                                value={value === null ? '' : value}
                                placeholder={placeholder}
                            />
                        )
                        : <span className="value">{value == null ? constants.emptyPlaceholder : Number(value).toLocaleString('en')}</span>
                }
            </div>
        </div>
    )
};
