import moment from "moment";
import { user } from "../../../../../user";
import { calculateSubscriptionTimeline } from "../../../../../utils/subscription.utils";

const dateFormat = 'M/D/YY';

interface Props {
    isCurrent: boolean;
}

export function SubscriptionEndDate({ isCurrent }: Props) {
    const currentUser = user.current();
    
    if (!currentUser) return null;

    const { isTrial } = calculateSubscriptionTimeline();

    const renderTrial = () => <>trial till {moment.utc(currentUser.trialPeriodEnd).format(dateFormat)}</>
    const renderPaid = () => <>subscribed till {moment.utc(currentUser.subscriptionExpiration).format(dateFormat)}</>

    return (
        <div className="till-date">
            {
                isCurrent &&
                <>
                    {isTrial && !!currentUser.trialPeriodEnd && renderTrial()}
                    {!isTrial && !!currentUser.subscriptionExpiration && renderPaid()}
                </>
            }
        </div>
    );
}