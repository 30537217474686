import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { MenageViewHeader } from './manage/header/MenageViewHeader';
import { ManageViewContent } from './manage/content/ManageViewContent';
import { roles, SORT, SORTING_TYPE, constants } from '../../../constants';
import { IColumnDefinition } from '../../bidding/common/table/types/ColumnDefinition';
import { Table } from '../../bidding/common/table';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { AppState } from '../../../types/state/AppState';
import { Preloader, OnHoverTooltip } from '../../common';
import { RequestUserStatusDetails } from './manage/users/RequestUserStatusDetails';
import Confirm from '../../alerts/Confirm';
import { user } from '../../../user';
import { UserListTableItem } from './manage/users/UserListTableItem';
import { ModalAddEditTrader } from './ModalAddEditTrader';
import { profileActions } from '../../../actions';
import { UserStatus } from '../../../types/account/UserStatus';
import { CompanyTrader } from '../../../types/company/CompanyTrader';
import ContextMenu from '../../controls/ContextMenu';
import { ContextMenuItem } from '../../controls/ContextMenuItem';
import { userStatuses } from '../../../constants';
import { useAppDispatch } from '../../../effects/useAppDispatch';

export const Traders: React.FC = () => {
    const [tradeModalVisible, setTradeModalVisible] = useState(false);
    const [contactForEdit, setContactForEdit] = useState<UserListTableItem | null>(null);
    const [isConfirmationVisible, setConfirmationVisible] = useState(false);

    const dispatch = useAppDispatch();

    const currentUser = user.current()!;
    const company = useSelector((state: AppState) => state.profile.company);
    const users: CompanyTrader[] = useSelector((state: AppState) => state.profile.company?.traders) ?? [];
    const primaryContact = users.find(c => c.id === company?.primaryContactId);
    const isLoading = useSelector((state: AppState) => state.profile.isLoading);
    const isAuthorisedUserPrimaryContact = company?.primaryContactId === currentUser.id;

    const convertToUserListItems = useCallback((users: CompanyTrader[]): UserListTableItem[] => {
        return users
            .map((user) => ({
                ...user,
                fullName: `${user.firstName} ${user.lastName}`,
                userStatus: user.status as UserStatus,
                statusText: userStatuses.getByStatusCode(user.status)?.title ?? '',
            }))
    }, []);

    const handleCloseModal = () => {
        setContactForEdit(null);
        setTradeModalVisible(false);
    };

    const hideConfirmationPopup = () => {
        setConfirmationVisible(false);
    };

    const confirmDisableTrader = () => {
        hideConfirmationPopup();
        dispatch(profileActions.disableCompanyTrader(contactForEdit));
    };

    const disableTrader = (contact: UserListTableItem) => {
        setContactForEdit(contact);
        setConfirmationVisible(true);
    };

    const enableTrader = (contact: UserListTableItem) => {
        setContactForEdit(contact);
        dispatch(profileActions.enableCompanyTrader(contact));
    };

    const renderUserManagementOptions = (contact: UserListTableItem) => {
        const isTraderActive = contact.userStatus !== UserStatus.Blocked;
        const isRequesting = contactForEdit !== null && contactForEdit.id === contact.id && isLoading;
        const isPrimary = contact.id === primaryContact?.id;

        if (isPrimary || !isAuthorisedUserPrimaryContact) return null;

        const handleClick = () => isTraderActive ? disableTrader(contact) : enableTrader(contact);

        return (
            isRequesting
                ? <Preloader inProgress={isRequesting} fullScreen={false} small={true} />
                : <ContextMenu>
                    <ContextMenuItem key='block/unblock' onClick={handleClick}>
                        {isTraderActive ? 'Block' : 'Unblock'}
                    </ContextMenuItem>
                </ContextMenu>
        )
    };

    const getRolesByString = (roleList: string[]) => roleList && roleList.length
        ? [...roleList].sort((a, b) => a.localeCompare(b)).map(r => roles.getTitle(r)).join(', ')
        : '–'

    const getColumns = () => {
        const name: IColumnDefinition<UserListTableItem> = {
            columnKey: 'name',
            renderColumnHeaderContent: () => 'User',
            renderColumnContent: entity => {
                return (
                    <OnHoverTooltip wrapperClass="text-ellipsis" overlay={entity.fullName}>
                        {entity.fullName}
                    </OnHoverTooltip>
                )
            },
            headerClassName: 'data-list-user',
            bodyClassName: 'data-list-user',
            sortingField: 'fullName',
            sortingType: SORTING_TYPE.string
        };
        const userRoles: IColumnDefinition<UserListTableItem> = {
            columnKey: 'roles',
            renderColumnHeaderContent: () => 'User Role',
            renderColumnContent: entity => {
                const text = getRolesByString(entity.roles);
                return (
                    <OnHoverTooltip wrapperClass="text-ellipsis" overlay={text}>
                        {text}
                    </OnHoverTooltip>
                )
            },
            headerClassName: 'data-list-user-role',
            bodyClassName: 'data-list-user-role',
            sortingField: 'roles',
            sortingType: SORTING_TYPE.rolesShortTitles
        };
        const email: IColumnDefinition<UserListTableItem> = {
            columnKey: 'email',
            renderColumnHeaderContent: () => 'Email',
            renderColumnContent: entity => {
                return (
                    <OnHoverTooltip wrapperClass="text-ellipsis" overlay={entity.email}>
                        <a href={`mailto:${entity.email}`}>{entity.email}</a>
                    </OnHoverTooltip>
                )
            },
            headerClassName: 'data-list-email',
            bodyClassName: 'data-list-email'
        };
        const status: IColumnDefinition<UserListTableItem> = {
            columnKey: 'status',
            renderColumnHeaderContent: () => 'Status',
            renderColumnContent: entity => (
                <RequestUserStatusDetails
                    userStatus={entity.userStatus}
                    userRequestStatus={entity.userRequestStatus}
                    blockReason={entity.blockReason || ''}
                />
            ),
            headerClassName: 'data-list-status',
            bodyClassName: 'data-list-status',
            sortingField: 'statusText',
            sortingType: SORTING_TYPE.string
        };
        const phone: IColumnDefinition<UserListTableItem> = {
            columnKey: 'phone',
            renderColumnHeaderContent: () => 'Phone',
            renderColumnContent: entity => entity.phoneNumber || constants.emptyPlaceholder,
            headerClassName: 'data-list-phone',
            bodyClassName: 'data-list-phone',
        };
        const options: IColumnDefinition<UserListTableItem> = {
            columnKey: 'actions',
            renderColumnHeaderContent: () => 'Actions',
            renderColumnContent: entity => renderUserManagementOptions(entity),
            headerClassName: 'data-list-options text-right',
            bodyClassName: 'data-list-options text-right'
        };
        const jobTitle: IColumnDefinition<UserListTableItem> = {
            columnKey: 'jobTitle',
            renderColumnHeaderContent: () => 'Job Title',
            renderColumnContent: entity => (
                !!entity.jobTitle
                    ? <OnHoverTooltip overlay={entity.jobTitle}>{entity.jobTitle}</OnHoverTooltip>
                    : constants.emptyPlaceholder
            ),
            headerClassName: 'data-list-job-title',
            bodyClassName: 'data-list-job-title',
            sortingField: 'jobTitle',
            sortingType: SORTING_TYPE.string
        };

        const columns = [
            userRoles,
            name,
            jobTitle,
            status,
            email,
            phone,
            ...(isAuthorisedUserPrimaryContact ? [options] : [])
        ];

        return columns.map(c => new ColumnBuilder(c));
    };

    return (
        <div className='tab-pane profile-users container-flex'>
            <Preloader inProgress={isLoading}>
                <MenageViewHeader title="Platform Users" />
                <ManageViewContent>
                    <Table
                        dataItems={convertToUserListItems(users)}
                        columns={getColumns()}
                        defaultSortBy={'fullName'}
                        defaultSortByDirection={SORT.ASC}
                    />
                </ManageViewContent>
            </Preloader>
            {contactForEdit === null ? '' : <ModalAddEditTrader visible={tradeModalVisible} onClose={handleCloseModal} contact={contactForEdit} />}
            {isConfirmationVisible && (
                <Confirm
                    onCancel={hideConfirmationPopup}
                    onConfirm={confirmDisableTrader}
                    title="Confirmation"
                    text={`Please confirm you would like to revoke access to the platform for ${contactForEdit === null ? '' : contactForEdit.email}`}
                />
            )}
        </div>
    );
};
