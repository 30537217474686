import React, { useState } from 'react';
import IconSVG from '../../../../styles/svg-icons';

interface Props {
    children?: React.ReactNode
}

export const ExpandableInfoPanel: React.FC<Props> = ({ children }: Props) => {
    const [visible, setVisible] = useState(false);

    const handleHidePanel = () => {
        setVisible(false);
    };

    if (visible) {
        return (
            <div className="intro-info position-relative">
                <button className="btn-close" onClick={handleHidePanel}>
                    <IconSVG name="close" width={16} height={16} />
                </button>
                {children}
            </div>
        )
    }

    return (
        <div className="show-info">
            <button className="btn btn-link" onClick={() => setVisible(true)}>
                Show information
            </button>
        </div>
    )
};
