import { AppProduct, getAppProductTitle } from '../../../../../types/billing/AppProduct';
import { subscriptionFeatureTitle } from '../../../../../types/billing/SubscriptionFeature';
import { featuresByProduct } from '../../../../../user/features/feature-provider';
import { Popup, PopupBody } from '../../../../controls';

interface Props {
    subscription: string;
    product: AppProduct;
    onClose: () => void;
}

const getProductBodyText = (product: AppProduct, subscription: string) => {
    switch (product) {
        case AppProduct.DealDocuments:
            return `The following documents are disabled for the ${getAppProductTitle(product)} product on the ${subscription} Plan:`
        default:
            return `The following features are disabled for the ${getAppProductTitle(product)} page on the ${subscription} Plan:`
    }
}

export function LimitedFeaturesPopup({ subscription, product, onClose }: Props) {
    const features = featuresByProduct[product];
    const featureTitles = features
        ?.map(f => subscriptionFeatureTitle[f])
        .filter(title => !!title);

    if (!featureTitles?.length) return null;

    return (
        <Popup
            renderInBody
            modalClass="modal-plan-details"
            onClose={onClose}
            title={`${subscription} Plan`}
            renderTitle={<span className="name-tag">{getAppProductTitle(product)}</span>}
        >
            <PopupBody>
                <p className="margin-b-0">{getProductBodyText(product, subscription)}</p>
                <ul className="list-bullet grey">
                    {featureTitles.map(f => <li key={f}>{f}</li>)}
                </ul>
            </PopupBody>
        </Popup>
    )
}
