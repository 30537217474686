import { BwicFilterType, CommonFilter } from "../../../../types/filters/FilterState";
import { PageConfigType } from "../../../../types/page-config/PageConfigType";
import { FilterUpdatesTable } from "../../../amrPipeline/aggregated/email-alerts/issuance-monitor/FilterUpdatesTable";
import { UserConfigFilter } from "../../../../types/user-config/UserConfigFilter";
import { manageEmailPreferencesActions } from "../../../../actions/manage-email-preferences.actions";
import { useDispatch } from "react-redux";
import { apiUtils } from "../../../../utils";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../effects/useAppSelector";
import { compareStrings } from "../../../../utils/compare.utils";
import { AlertOption } from "../../../../types/email-preferences/EmailPreferences";
import { RequiredFeature } from "../../../access/RequiredFeature";
import { SubscriptionFeature } from "../../../../types/billing/SubscriptionFeature";
import { SubscribeLink } from "../../../access/BlockedFeatureText";
import { FilterColumnsKeys } from "../../../amrPipeline/aggregated/email-alerts/issuance-monitor/filterColumns";
import { EmptyPlaceholder } from "../../../common";
import IconSVG from "../../../../styles/svg-icons";

interface Props {
    filter: CommonFilter;
    visibleFilters: string[];
    pageConfigType: PageConfigType;
    filterType: BwicFilterType;
    filterAlertsStatuses?: UserConfigFilter[];
    disabled?: boolean;
    requiredFeature?: SubscriptionFeature;
    description: string;
    excludeColumns?: FilterColumnsKeys[];
    alertOptionToolitps?: { [key in AlertOption]?: string };
    collapsible?: boolean;
    isStatusVisibleInTitle?: boolean;
}

export const SavedFiltersAlerts = ({
    filter,
    visibleFilters,
    filterAlertsStatuses,
    filterType,
    disabled,
    requiredFeature,
    description,
    excludeColumns = [],
    alertOptionToolitps,
    collapsible = false,
    isStatusVisibleInTitle = false,
}: Props) => {
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const changedAlertOptions = useAppSelector(s => s.manageEmailPreferences.editSavedFiltersPreferences);

    useEffect(() => {
        return () => {
            dispatch(manageEmailPreferencesActions.savedFilterAlertOptionsReset());
        }
    }, [dispatch])

    const mapChangedAlertState = () => (filterAlertsStatuses ?? []).map(f => ({
        ...f,
        alertOption: changedAlertOptions[f.referenceName] ?? f.alertOption
    }))
        .sort((a, b) => compareStrings(a.name, b.name))

    const handleFilterAlertStateChange = (updated: UserConfigFilter[]) => {
        const updatedState = apiUtils.normalize(updated, x => x.referenceName, x => x.alertOption)
        dispatch(manageEmailPreferencesActions.savedFilterAlertOptionChange(updatedState));
    }

    const renderEmptyPlaceholder = () => {
        return (
            <RequiredFeature
                className="empty-placeholder"
                inline
                feature={requiredFeature}
                text={<><SubscribeLink /> to unlock the saved filters.</>}
            >
                <EmptyPlaceholder text="There are no saved filters yet to receive email notifications." />
            </RequiredFeature>
        )
    }

    const renderTitle = () => (
        <h4 onClick={collapsible ? () => setCollapsed(!collapsed) : undefined} className="controls-wrap-title flex-row">
            {
                collapsible &&
                <span className="btn-link">
                    <IconSVG name="icon-expand" className={`${!collapsed ? 'collapse' : ''}`} width={16} height={16} />
                </span>
            }
            Saved Filters&nbsp;<span className="text-regular text-warm-grey">{isStatusVisibleInTitle ? `(${filterAlertsStatuses?.some(f => f.alertOption !== AlertOption.Never) ? 'on' : 'off'})`: null}</span>
        </h4>
    )

    return (
        <div className="controls-wrap">
            <div className="controls-wrap-head">
                {renderTitle()}
            </div>
            <p className="controls-wrap-info">{description}</p>
            {
                collapsed
                    ? null
                    : (filterAlertsStatuses?.length ?
                        <FilterUpdatesTable
                            excludeColumns={excludeColumns}
                            disabled={disabled}
                            filter={filter}
                            visibleFilters={visibleFilters}
                            filterType={filterType}
                            filtersStatuses={mapChangedAlertState()}
                            setFiltersStatuses={handleFilterAlertStateChange}
                            alertOptionTooltips={alertOptionToolitps}
                            requiredFeature={requiredFeature}
                        />
                        : renderEmptyPlaceholder())
            }
        </div>
    )
}