import React from 'react';
import { ManageViewContent } from '../manage/content/ManageViewContent';
import { EmailPreferencesContent } from './EmailPreferencesContent';

export const EmailPreferences: React.FC = () => {
    return (
        <div className="tab-pane notifications-center container-flex">
            <div className="sub-title">
                <h1>Alerts Center</h1>
            </div>
            <ManageViewContent>
                <EmailPreferencesContent />
            </ManageViewContent>
        </div >
    )
};
