import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';
import { AlertOption } from '../../../../../types/email-preferences/EmailPreferences';
import { CompanyAlertsValue } from '../../../../../types/user-config/UserConfig';
import { UserConfigType } from '../../../../../types/user-config/UserConfigType';
import { ActionBlocker } from '../../../../access/ActionBlocker';
import { RadioButton } from '../../../../controls';

interface Props {
    type: UserConfigType;
    option: AlertOption;
    allOptions: CompanyAlertsValue[];
    onChange: (type: UserConfigType, option: AlertOption) => void;
    disabled?: boolean;
    feature?: SubscriptionFeature;
    features?: SubscriptionFeature[];
}

export function MultiTableHeaderRadioButton({
    type,
    option,
    allOptions,
    onChange,
    disabled,
    feature,
    features,
}: Props) {
    const selected = allOptions?.some(x => x.alertOption === option);
    const partially = allOptions?.some(x => x.alertOption !== option);

    const handleClick = () => {
        onChange(type, option);
    };

    return (
        <ActionBlocker feature={feature} features={features} requireAllFeatures={!!features?.length}>
            {blocked => (
                <RadioButton
                    disabled={disabled || blocked}
                    name={`global-${option}-${type}`}
                    label={option}
                    checked={blocked ? option === AlertOption.Never: selected}
                    partially={!blocked && partially}
                    onClick={handleClick}
                    className={'qwe'}
                    onChange={() => {}}
                />
            )}
        </ActionBlocker>
    );
}
