import { useDispatch } from "react-redux";
import { manageEmailPreferencesActions } from "../../../../../../actions/manage-email-preferences.actions";
import { useAppSelector } from '../../../../../../effects/useAppSelector';
import { isRequestNone, isRequesting } from "../../../../../../utils/request-state.utils";
import { BwicMonitorAlertsTable } from "./BwicMonitorAlertsTable"
import { PageConfigType } from '../../../../../../types/page-config/PageConfigType';
import { BwicFilterType } from '../../../../../../types/filters/FilterState';
import { usePageConfig } from '../../../../../common/pageConfig/usePageConfig';
import { SavedFiltersAlerts } from '../../SavedFiltersAlerts';
import { BwicMonitorAlertsSaveButton } from './BwicMonitorAlertsSaveButton';
import { UserConfigFilter } from '../../../../../../types/user-config/UserConfigFilter';
import { Preloader } from '../../../../../common/Preloader';
import { SubscriptionFeature } from "../../../../../../types/billing/SubscriptionFeature";
import { textLabels } from "../../../../../../constants/text-labels";

export const BwicMonitorAlertsTab = () => {
    const dispatch = useDispatch();

    const filter = useAppSelector(s => s.filters.bwicMonitor.filter);
    const visibleFilters = useAppSelector(s => s.filters.bwicMonitor.visibleFilters);
    const savePreferencesRequestState = useAppSelector(s => s.manageEmailPreferences.requestStateSaveEditPreferences);

    const { config, requestState, saveAlerts, updateRequestState } = usePageConfig(
        PageConfigType.BwicMonitorAll,
        true,
        BwicFilterType.BwicMonitor
    );

    const handleSave = (updatedFilters?: UserConfigFilter[]) => {
        dispatch(manageEmailPreferencesActions.saveEmailPreferencesRequest());
        if (updatedFilters?.length) {
            saveAlerts(updatedFilters);
        }
    }

    return (
        <>
            <div className="flex-row justify-content-end save-changes">
                <BwicMonitorAlertsSaveButton
                    className="btn-sm"
                    currentFilters={config?.filters}
                    disabled={
                        isRequestNone(requestState) ||
                        isRequesting(requestState, updateRequestState, savePreferencesRequestState)
                    }
                    onSave={handleSave}
                    withLeavingGuard
                />
            </div>
            <Preloader inProgress={isRequestNone(requestState) || isRequesting(requestState)}>
                <BwicMonitorAlertsTable />
                <SavedFiltersAlerts
                    filter={filter}
                    filterAlertsStatuses={config?.filters}
                    disabled={isRequesting(savePreferencesRequestState, updateRequestState)}
                    visibleFilters={visibleFilters}
                    filterType={BwicFilterType.BwicMonitor}
                    pageConfigType={PageConfigType.BwicMonitorAll}
                    requiredFeature={SubscriptionFeature.BwicMonitorAlerts}
                    description={textLabels.savedFiltersBwicMonitor}
                />
            </Preloader>
        </>
    )
}
